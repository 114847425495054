import {
    Icon,
    InfoCard,
    Row,
    Subtitle,
    TextContainer,
} from '@moller/design-system';
import ContactItem from 'src/shared/contact-item/ContactItem';
import { styled } from 'styled-components';

const StyledInfoCard = styled(InfoCard)`
    margin-bottom: var(--moller-spacing-s);
    padding-bottom: var(--moller-spacing-xxs);
`;

const StyledRow = styled(Row)`
    margin: var(--moller-spacing-s) 0;
    max-width: 100%;
    flex-wrap: wrap;
`;

const StyledTimeText = styled.span`
    margin-left: 6px;
`;

interface ContactInfoListProps {
    title: string;
    description: string;
    phoneLabel?: string;
    phoneNumber?: string;
    email?: string;
    openHoursText?: string;
}

export const ContactInfoCard = ({
    title,
    description,
    phoneLabel,
    phoneNumber,
    email,
    openHoursText,
}: ContactInfoListProps) => {
    return (
        <StyledInfoCard>
            <Subtitle>{title}</Subtitle>
            <TextContainer>
                <p>{description}</p>
            </TextContainer>
            <StyledRow gap="s">
                {email && <ContactItem email={email} />}
                {phoneLabel && phoneNumber && (
                    <ContactItem
                        phoneLabel={phoneLabel}
                        phoneNumber={phoneNumber}
                    />
                )}
            </StyledRow>
            {openHoursText && (
                <StyledRow verticalAlign="center">
                    <Icon icon={'schedule'} style={{ fontWeight: '400' }} />
                    <StyledTimeText>{openHoursText}</StyledTimeText>
                </StyledRow>
            )}
        </StyledInfoCard>
    );
};

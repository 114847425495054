import { LocalFormState } from '@moller/design-system/utilities/forms/useLocalFormState.js';
import { GUMPENS_ECONOMY_SERVICE } from '../../lib/constants/dealerConstants';
import { vehicleOlderThanXMonths } from '../../lib/util/vehicleOlderThanXMonths';
import { DealerPicker } from './dealer-picker';
import { useFastTrack } from '../../lib/useFastTrack';
import { DealerForm } from './ChooseDealerSection';
import {
    DEALER_TYPE_DAMAGE,
    DEALER_TYPE_SERVICE,
    DealerType,
    DealerViewModel,
} from 'external-apis/src/types/port';
import { CarViewModel } from 'external-apis/src/types/carcare';

function getFilteredDealers(
    dealers: DealerViewModel[],
    selectedVehicle: CarViewModel,
    activeDealerTypes: DealerType[],
    filterByBrand: boolean
): DealerViewModel[] {
    const isOldVehicle = vehicleOlderThanXMonths(
        selectedVehicle.date.registration.first,
        60
    );
    const filteredDealers = filterByBrand
        ? dealers.filter((dealer) =>
              dealer.serviceCertifications.some(
                  (cert) =>
                      cert.toLowerCase() ===
                      selectedVehicle.brandCode.toLowerCase()
              )
          )
        : dealers;
    return (
        filteredDealers
            // TODO: should this logic be a part of DealerPicker instead?
            .filter((dealer) => !(isEconomyDealer(dealer.id) && !isOldVehicle))
            .filter((dealer) =>
                activeDealerTypes.some((type) =>
                    dealer.dealerTypes.includes(type)
                )
            )
    );
}

function isEconomyDealer(dealerId: string) {
    return dealerId === GUMPENS_ECONOMY_SERVICE;
}

type ChooseDealerEditProps = {
    dealers: DealerViewModel[];
    dealerForm: LocalFormState<DealerForm>;
    vehicle: CarViewModel;
    preferredDealerId: string | undefined;
};

export function ChooseDealerEdit({
    dealers,
    vehicle,
    dealerForm,
    ...props
}: ChooseDealerEditProps) {
    const fastTrackType = useFastTrack();
    const getDealerTypes: DealerType[] = [
        DEALER_TYPE_SERVICE,
        ...(fastTrackType.fastTrack === 'glass-damage'
            ? [DEALER_TYPE_DAMAGE]
            : []),
    ];

    const filteredDealers = getFilteredDealers(
        dealers,
        vehicle,
        getDealerTypes,
        !fastTrackType.fastTrack
    );
    const inputProps = dealerForm.inputProps('selectedDealer');
    return (
        <DealerPicker
            dealers={filteredDealers}
            onChange={(dealer) => inputProps.setValue(dealer.id)}
            selectedDealerId={inputProps.value}
            validity={inputProps.validity}
            {...props}
        />
    );
}

import { AxiosRequestHeaders, AxiosResponse } from 'axios';
import { RequestConfig } from 'src/utils/http/doRequest';
import get from 'src/utils/http/get';
import { mockData } from './data';
import getCarInformation from './mocks/getCarInformation';
import getCarServices from './mocks/getCarServices';
import getFutureAppointment from './mocks/getFutureAppointment';
import getHistoricAppointment from './mocks/getHistoricAppointment';
import getMileage from './mocks/getMileage';
import getMyCarsProfile from './mocks/getMyCarsProfile';
import getPreferredDealers from './mocks/getPreferredDealers';
import getTireHotelDeal from './mocks/getTireHotelDeal';

const tryGetMockData = (path: string) => {
    if (!mockData) {
        return null;
    }

    if (path.includes('api/v3/myCarsProfile')) {
        return getMyCarsProfile(mockData);
    }

    if (path.includes('/car-information/') && path.includes('FAKEVIN')) {
        return getCarInformation(path.split('/car-information/')[1], mockData);
    }

    if (path.includes('api/v3/carServices/')) {
        return getCarServices(path.split('api/v3/carServices/')[1], mockData);
    }

    if (path.includes('api/v1/tire-hotel-deal/vin/')) {
        return getTireHotelDeal(
            path.split('api/v1/tire-hotel-deal/vin/')[1],
            mockData
        );
    }

    if (path.includes('api/v3/historicAppointment/')) {
        return getHistoricAppointment(
            path.split('api/v3/historicAppointment/')[1],
            mockData
        );
    }

    if (path.includes('api/v3/futureAppointment')) {
        return getFutureAppointment();
    }

    //TODO Update to use /v1/cars/{vin}/mileage
    if (path.includes('api/v3/mileageEdit/')) {
        return getMileage(path.split('api/v3/mileageEdit/')[1], mockData);
    }

    if (path.includes('/v1/preferred-dealers/affiliation')) {
        return getPreferredDealers(mockData);
    }

    return null;
};

const proxyGet = async <T>(
    path: string,
    config?: RequestConfig
): Promise<AxiosResponse<T>> => {
    const mockData = tryGetMockData(path) as T;

    if (mockData) {
        console.log('Mock data: ' + path, mockData);
        return {
            data: mockData,
            status: 200,
            statusText: 'OK',
            headers: {},
            config: { headers: {} as AxiosRequestHeaders },
        };
    }

    return await get<T>(path, config);
};

export default proxyGet;

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useFetchersContext } from 'external-apis';
import { AddCarRelationFeasibilityResult } from 'external-apis/src/types/bilhold';
import httpStatus from 'http-status';
import AddCarMethod from 'src/features/add-car/utils/AddCarMethod';
import isRegNoValid from 'src/features/add-car/utils/isRegNoValid';
import Brand from 'src/types/Brand';
import CarRelation from 'src/utils/enums/CarRelation';
import { ADD_CAR_V2 } from 'src/utils/http/endpoints';
import get from 'src/utils/http/get';
import del from 'src/utils/http/httpDelete';
import post from 'src/utils/http/post';
import put from 'src/utils/http/put';

export function approveCodeErrorMessage(statusCode?: number) {
    switch (statusCode) {
        case 400:
            return 'general_error_contact_support';
        case 403:
            return 'approveCodeForbidden';
        case 404:
            return 'approveCodeBadRequest';
        case 429:
            return 'approveCodeTooManyRequests';
        default:
            return 'general_error_contact_support';
    }
}

export const useConfirmCode = (
    code: string,
    licencePlate: string,
    successCallback: () => void
) => {
    return useMutation<unknown, AxiosError<ErrorResponseData>>({
        mutationKey: ['codeConfimation', licencePlate],
        mutationFn: () =>
            put(`api/v1/add-car/${licencePlate}/approve`, {
                code,
            }),
        onSuccess: () => successCallback(),
    });
};

export const useDeleteAddCar = (
    licencePlate: string,
    successCallback: () => void
) => {
    return useMutation({
        mutationKey: ['deleteAddCar', licencePlate],
        mutationFn: () => del(`api/v1/add-car/${licencePlate}`),
        onSuccess: () => successCallback(),
    });
};

export interface ErrorResponseData {
    errors?: Messages[];
    httpCode: number;
}

interface Messages {
    message:
        | 'AWAIT_RETRY'
        | 'EXCEEDED_DAILY_REQUESTS'
        | 'EXCEEDED_TOTAL_REQUESTS';
}

export function checkRenewError(
    errorResponse?: AxiosResponse<ErrorResponseData>
) {
    const responseData = errorResponse?.data;

    if (
        !(errorResponse?.status === httpStatus.TOO_MANY_REQUESTS) &&
        !responseData?.errors?.length
    ) {
        return 'general_error_contact_support';
    }

    const firstError =
        responseData?.errors && responseData.errors.length > 0
            ? responseData?.errors[0].message
            : '';

    switch (firstError) {
        case 'AWAIT_RETRY':
            return 'renewCodeAwaitRetry';
        case 'EXCEEDED_DAILY_REQUESTS':
            return 'renewCodeExceededDailyTries';
        case 'EXCEEDED_TOTAL_REQUESTS':
            return 'renewCodeExceededTotalTries';
        default:
            return 'general_error_contact_support';
    }
}

export const useCodeRenewal = (licencePlate: string) => {
    return useMutation<unknown, AxiosError<ErrorResponseData>>({
        mutationKey: ['codeRenewal', licencePlate],
        mutationFn: () => put(`api/v1/add-car/${licencePlate}/renew`),
    });
};

interface AddCarPostInterface {
    registrationNumber: string;
    vin: string;
    relationType: CarRelation;
    leaser?:
        | string
        | {
              organisationNumber?: string;
          };
    companyCar?:
        | string
        | null
        | {
              company: {
                  name: string | undefined;
                  electronicAddress: string | undefined;
              };
          };
}

export const useAddCar = (
    onSuccess: () => void,
    setError: (value: string) => void
) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (addCarObject: AddCarPostInterface) =>
            post(ADD_CAR_V2, addCarObject),
        onSuccess: () => {
            void queryClient.invalidateQueries({ queryKey: ['carProfile'] });
            onSuccess();
        },
        onError: () => {
            setError('general_error_contact_support');
        },
    });
};

export type AddCarRelationReason =
    | 'RELATION_ALREADY_EXISTING_PRIVATE'
    | 'RELATION_ALREADY_EXISTING_ORGANIZATION'
    | 'RELATION_ALREADY_EXISTING_ORGANIZATION_EXT'
    | 'RELATION_ALREADY_EXISTING'
    | 'OWNER_NOT_EXISTING_IN_MVR'
    | 'PROPRIETOR_NOT_EXISTING'
    | 'PROPRIETOR_NOT_VALID'
    | 'PROPRIETOR_MOBILE_NOT_VALID'
    | 'PROPRIETOR_EXCLUDED'
    | 'PROPRIETOR_NOT_VALID_OWNER'
    | 'PROPRIETOR_NOT_VALID_LEASER';

export interface AddCarRelation {
    type: CarRelation;
    method: AddCarMethod;
    reasons: AddCarRelationReason[];
    proprietorType?: CarRelation;
}
export interface AddCarSearchResponse {
    brand: Brand;
    carModel: string;
    vin: string;
    registrationNumber: string;
    relationTypes: AddCarRelation[];
}

const RequestCarErrorMessage = {
    PENDING: 'addCarPendingRequest',
    ALREADY_ADDED: 'addCarAlreadyAdded',
    UNKNOWN_BRAND: 'addCarUnknownBrand',
    NOT_FOUND: 'addCarNotFound',
    GENERAL_ERROR: 'general_error_contact_support',
};

export interface SearchCarErrorResponseData {
    errors?: SearchCarMessage[];
    httpCode?: number;
    httpDescription?: string;
}

interface SearchCarMessage {
    message:
        | 'PENDING'
        | 'ALREADY_ADDED'
        | 'UNKNOWN_BRAND'
        | 'NOT_FOUND'
        | 'GENERAL_ERROR';
}

export const getSearchCar = (
    licensePlate: string,
    setSearchCarData: (data: AddCarSearchResponse) => void,
    setSearchCarError: (errorKey: string) => void
) => {
    const request = get<AddCarSearchResponse>(
        `api/v1/add-car/${licensePlate}/request`
    );
    return request
        .then((res) => setSearchCarData(res.data))
        .catch((error: AxiosError<SearchCarErrorResponseData>) => {
            const errorMessage = error.response?.data?.errors?.[0]?.message;
            if (Number(error.response?.data?.httpCode) === httpStatus.NOT_FOUND)
                setSearchCarError(RequestCarErrorMessage.NOT_FOUND);
            else if (error?.response?.data?.httpDescription && errorMessage)
                setSearchCarError(RequestCarErrorMessage[errorMessage]);
            else setSearchCarError(RequestCarErrorMessage.GENERAL_ERROR);
        });
};

const useAddCarRequestFetcher = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.bilhold.fetcher
        .path('/v1/cars/add-car/{RegistrationNumber}/request')
        .method('get')
        .create();
};

async function getAddCarRequest(
    RegistrationNumber: string,
    addCarRequest: ReturnType<typeof useAddCarRequestFetcher>
) {
    const result = await addCarRequest({ RegistrationNumber });
    return result.data;
}

export const useAddCarRequestFeasibility = (registrationNumber: string) => {
    const addCarRequest = useAddCarRequestFetcher();

    return useQuery<AddCarRelationFeasibilityResult>({
        queryKey: ['addCarFeasibility', registrationNumber],
        queryFn: () => getAddCarRequest(registrationNumber, addCarRequest),
        enabled: isRegNoValid(registrationNumber),
    });
};

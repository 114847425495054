import { nextMonth } from '@moller/design-system';
import {
    QueryClient,
    useMutation,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';
import { format, formatISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { useFetchersContext } from 'external-apis';
import {
    AvailableTimeSlotsQuery,
    CountryCode,
    ReservedTimeSlotViewModel,
    TimeSlotViewModel,
    TimeslotReserveModelQuery,
} from 'external-apis/src/types/port';
import {
    FriendlyQueryError,
    FriendlyQueryWarning,
} from '../../lib/errors/PortError';
import {
    LanguageContextType,
    useLanguageContext,
} from '../../lib/languages/languageContext';
import { STALE_TIME } from '../../lib/query-client/config';
import { useGetApiLocaleConfig } from '../apiLocale';

const useFindTimeSlots = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.port.fetcher
        .path('/time-slots/search')
        .method('post')
        .create();
};

export const queryPrefix = 'getTimeSlots';

const toGetTimeslotKey = (
    query: AvailableTimeSlotsQuery,
    timeZone: string
) => ({
    ...query,
    startTime: formatInTimeZone(query.startTime, timeZone, 'yyyy-MM'),
});

const getPrefetchBody = (query: AvailableTimeSlotsQuery, offset: number) => ({
    ...query,
    startTime: formatISO(nextMonth(new Date(), offset)),
    endTime: formatISO(nextMonth(new Date(), offset + 1)),
});

export const usePrefetchTimeslots = (query: AvailableTimeSlotsQuery) => {
    const findTimeSlots = useFindTimeSlots();
    const qc = useQueryClient();
    const [lc] = useLanguageContext();
    const { timeZone } = useGetApiLocaleConfig();

    const prefetchSlots = (prefetchBodyoffset: AvailableTimeSlotsQuery) => {
        void qc.prefetchQuery({
            queryKey: [
                queryPrefix,
                toGetTimeslotKey(prefetchBodyoffset, timeZone),
            ],
            queryFn: () => getTimeSlots(prefetchBodyoffset, lc, findTimeSlots),
            staleTime: STALE_TIME.minute * 2,
        });
    };

    prefetchSlots(getPrefetchBody(query, 1));
    prefetchSlots(getPrefetchBody(query, 2));
};

export function useGetTimeSlots(
    body: AvailableTimeSlotsQuery & { startTime: string }
) {
    const [lc] = useLanguageContext();

    const { timeZone } = useGetApiLocaleConfig();
    const findTimeSlots = useFindTimeSlots();

    // If the key includes startTime with seconds we get 100% cache miss.
    return useQuery<TimeSlotViewModel[], Error>({
        queryKey: [queryPrefix, toGetTimeslotKey(body, timeZone)],
        queryFn: () => getTimeSlots(body, lc, findTimeSlots),
        enabled: !!body,
        staleTime: STALE_TIME.minute * 2,
        refetchOnWindowFocus: false,
    });
}

export async function getTimeSlots(
    body: AvailableTimeSlotsQuery,
    lc: LanguageContextType,
    findTimeSlots: ReturnType<typeof useFindTimeSlots>
) {
    try {
        const result = await findTimeSlots(body);
        return result.data;
    } catch (e) {
        if (e instanceof findTimeSlots.Error) {
            throw new FriendlyQueryError(
                lc.errors.port_timeslots_find_default,
                e,
                e.status
            );
        }
        throw e;
    }
}

const usePostTimeStot = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.port.fetcher
        .path('/time-slots/{timeSlotId}/reserve')
        .method('post')
        .create();
};

export function useReserveTimeSlot({
    vin,
    serviceIds,
}: {
    vin: string;
    serviceIds: string[];
}) {
    const [lc] = useLanguageContext();
    const qc = useQueryClient();

    const { countryCode } = useGetApiLocaleConfig();
    const postTimeslot = usePostTimeStot();

    return useMutation<ReservedTimeSlotViewModel, Error, TimeSlotViewModel>(
        (timeSlot: TimeSlotViewModel) =>
            reserveTimeSlot(
                timeSlot,
                vin,
                serviceIds,
                countryCode,
                lc,
                qc,
                postTimeslot
            )
    );
}

async function reserveTimeSlot(
    timeSlot: TimeSlotViewModel,
    vin: string,
    serviceIds: string[],
    countryCode: CountryCode,
    lc: LanguageContextType,
    qc: QueryClient,
    postTimeSlot: ReturnType<typeof usePostTimeStot>
) {
    const body: TimeslotReserveModelQuery = {
        vin,
        dealerNumber: timeSlot.dealerNumber,
        startTime: timeSlot.startTime,
        estimatedFinished: timeSlot.estimatedFinished,
        resourceId: timeSlot.timeSlotId,
        adapterId: timeSlot.adapterId,
        businessOperation: timeSlot.businessOperation,
        serviceIds,
        countryCode,
    };

    try {
        const result = await postTimeSlot({
            ...body,
            timeSlotId: body.resourceId,
        });
        return result.data;
    } catch (e) {
        if (e instanceof postTimeSlot.Error) {
            const response = e.getActualType();
            if (response) {
                switch (response.status) {
                    case 409:
                        if (
                            response.data.errorType ===
                            'TimeSlotReservationExpired'
                        ) {
                            const warningMsg =
                                lc.errors.port_timeslots_reserve_conflict.replace(
                                    '{time}',
                                    format(new Date(body.startTime), 'HH:mm')
                                );

                            qc.setQueriesData<TimeSlotViewModel[]>(
                                [queryPrefix],
                                (data) => {
                                    return (data ?? []).filter(
                                        (x) =>
                                            !(
                                                x.adapterId == body.adapterId &&
                                                x.dealerNumber ===
                                                    body.dealerNumber &&
                                                x.timeSlotId ===
                                                    body.resourceId &&
                                                x.startTime ===
                                                    body.startTime &&
                                                x.estimatedFinished ===
                                                    body.estimatedFinished
                                            )
                                    );
                                }
                            );

                            void qc.invalidateQueries([queryPrefix]);
                            throw new FriendlyQueryWarning(
                                warningMsg,
                                e,
                                e.status
                            );
                        }
                        throw new FriendlyQueryError(
                            lc.errors.default,
                            e,
                            response.status
                        );
                    default:
                        throw new FriendlyQueryError(
                            lc.errors.port_timeslots_reserve_default,
                            e,
                            e.status
                        );
                }
            }
        }
        throw e;
    }
}

import { RadioButton, useForm } from '@moller/design-system';
import { useEffect } from 'react';
import { BilholdErrorText } from '../../components/bilhold/BilholdErrorText';

export type Option<T, TValue> = T extends undefined
    ? { label: string; value: TValue }
    : { label: string; value: TValue; item: T };

type RadioGroupInputProps<T, TValue> = {
    id: string;
    options: Option<T, TValue>[];
    render?: (radioButton: JSX.Element, item: T) => JSX.Element;
    hideErrorText?: boolean;
    field: string;
    initialValue?: TValue;
};

export function RadioGroup<
    TItem = undefined,
    TValue extends string | string[] = string
>({
    id,
    options,
    render,
    hideErrorText,
    field,
    initialValue,
}: RadioGroupInputProps<TItem, TValue>) {
    const formContext = useForm<{ [key: string]: TValue }>();

    if (!formContext) {
        throw new Error('Used form input outside of form!');
    }

    const selectedValue = formContext.form.state.raw[field];

    const setValue = formContext.form.setValue(field);

    const validity = formContext.form.inputProps(field).validity;

    useEffect(() => {
        if (selectedValue?.length === 0 && initialValue) {
            setValue(initialValue);
        }
    });

    const radioButton = (option: Option<TItem, TValue>) => {
        const value =
            typeof option.value === 'string'
                ? option.value
                : option.value?.[0] ?? null;
        const checked =
            typeof option.value === 'string'
                ? value === selectedValue
                : selectedValue.includes(value);

        return (
            <RadioButton
                id={value}
                key={value}
                value={checked}
                label={option.label}
                onChange={(event) => {
                    if (event.target.checked) {
                        setValue(option.value);
                    }
                }}
                validity={validity && { type: validity.type }}
            />
        );
    };

    return (
        <>
            {options.map((option) => {
                if ('item' in option && render) {
                    return render(radioButton(option), option.item);
                } else if (render) {
                    throw new Error('Render function provided without item!');
                }
                return radioButton(option);
            })}

            {!hideErrorText && <BilholdErrorText {...{ id, validity }} />}
        </>
    );
}

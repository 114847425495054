import { ImageType } from 'react-images-uploading';
import { loadImageWithAuth } from 'src/utils/auth/authUtils';
import getCurrentEnvironment from 'src/utils/environment/getCurrentEnvironment';
import { DamageMedia } from './useMedia';

function createMediaPath(mediaId: string, imageType?: boolean): string {
    if (!mediaId) {
        throw new Error('mediaId cannot be empty string');
    }
    const { serverUrl } = getCurrentEnvironment();
    const path = `${serverUrl}/api/v3/media/${mediaId}${
        imageType ? '/type/IMAGE' : ''
    }`;

    return path;
}

export const loadImageUrl = async (img: DamageMedia): Promise<ImageType> => {
    const mediaPath = createMediaPath(img.id.toString());
    const dataUrl = await loadImageWithAuth(mediaPath);
    return { ...img, dataURL: dataUrl };
};

export const loadImageUrls = async (images: DamageMedia[]) => {
    return Promise.all(images.map(loadImageUrl));
};

import { CarServiceDeal } from 'src/features/my-car/types/CarServiceDeal';
import { MockData } from '../data';

const getCarServices = (
    vin: string,
    mockData: MockData
): CarServiceDeal[] | null => {
    const car = mockData.cars.find((car) => car.vin === vin);

    if (!car) {
        return null;
    }

    return [
        {
            serviceId: 'mobilityGuarantee',
            status: car.mobilityGuarantee ? 'ACTIVE' : 'INACTIVE',
            lastUpdatedMileage: car.lastUpdatedMileage,
        },
        {
            serviceId: 'tireHotel',
            status: 'NODEAL',
            lastUpdatedMileage: '',
        },
    ];
};

export default getCarServices;

import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import logout from 'src/utils/auth/logout';
import DeleteAccountErrorType from 'src/utils/enums/DeleteAccountErrorType';
import del from 'src/utils/http/httpDelete';

export const useDeleteUser = (phoneNumber?: string) => {
    return useMutation<unknown, AxiosError<ErrorResponseData>>({
        mutationFn: () => del(`api/users/${phoneNumber}`),
        onSuccess: () => logout(),
    });
};

interface ErrorResponseData {
    errors?: Messages[];
    httpCode: number;
}

interface Messages {
    message: DeleteAccountErrorType;
}

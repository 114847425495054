import CountryCode from 'src/types/CountryCode';
import trimUserName from 'src/utils/auth/trimUserName';
import getCurrentEnvironment from 'src/utils/environment/getCurrentEnvironment';
import { CREATE_NEW_USER } from 'src/utils/http/endpoints';
import post from 'src/utils/http/post';

export interface PostUserInfo {
    countryCode?: string;
    userInfo: {
        email: string;
        firstName: string;
        lastName: string;
        addressLine: string;
        zipCode: string;
        city: string;
    };
    password: string;
}

export const postCreateUser = (
    input: PostUserInfo,
    otp: string,
    phoneNumber: string,
    setPostError: (errorKey: string) => void
) => {
    const { password, userInfo } = input;
    const { loginServerUrl } = getCurrentEnvironment();
    const url = `${loginServerUrl}/${CREATE_NEW_USER}`;

    const trimmedUserInfo = {
        ...userInfo,
        firstName: userInfo.firstName.trim(),
        lastName: userInfo.lastName.trim(),
        addressLine: userInfo.addressLine.trim(),
        email: userInfo.email.trim(),
    };
    const payload = {
        countryCode: 'NO',
        otp,
        password,
        phoneCountryCode: 'NO',
        phoneNumber: trimUserName(phoneNumber),
        userInfo: { ...trimmedUserInfo, addressCountry: CountryCode.NO },
    };

    return post(url, payload, {
        axiosConfig: { withCredentials: true },
        isAnonymous: true,
    }).catch(() => {
        setPostError('an_error_has_occured');
    });
};

import { App, AppState } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { Column, Icon, LabelText } from '@moller/design-system';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    getNumberOfUnOpenedMessages,
    getUrlToUnreadMessages,
} from 'src/features/contact/utils/messagesUtils';
import {
    PATH_EXPLORE,
    PATH_HOME,
    PATH_MESSAGES,
    PATH_MY_PREFERRED_DEALER,
    PATH_MY_PROFILE,
    PATH_PROFILE_OVERVIEW,
} from 'src/routing/routes';
import MessageBadge from 'src/shared/message-badge/MessageBadge';
import { ddRumPrivacySetting } from 'src/utils/datadog';
import { useMessageThreads } from 'src/utils/hooks/useMessageThreads';
import lang from 'src/utils/lang';
import Page from 'src/utils/pageType';
import { styled } from 'styled-components';

const MenuContainer = styled.div`
    position: fixed;
    display: flex;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 90;
    justify-content: space-around;
    background-color: var(--moller-color-surface);
    padding-top: var(--moller-spacing-xs);
    padding-bottom: max(var(--safe-area-bottom), var(--moller-spacing-xs));
    box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.1);
`;

const CenteredColumn = styled(Column)`
    position: relative;
    align-items: center;
    width: 100%;
    text-decoration: none;
    color: var(--moller-color-on-surface);
`;

const MessageBadgeContainer = styled.div`
    position: relative;
    left: calc(0px - var(--moller-size-xs));
`;

const AppMenu = ({ currentPage }: { currentPage: Page }) => {
    const [showMenu, setShowMenu] = useState(true);

    const { data: messages } = useMessageThreads();

    const numberOfUnreadMessages = getNumberOfUnOpenedMessages(messages);
    const urlToUnreadMessages = getUrlToUnreadMessages(messages);

    useEffect(() => {
        void App.addListener('appStateChange', (state: AppState) => {
            if (state.isActive) {
                setShowMenu(true);
            }
        });

        return () => {
            void App.removeAllListeners();
        };
    }, []);

    useEffect(() => {
        if (Capacitor.isPluginAvailable('Keyboard')) {
            void Keyboard.addListener('keyboardWillShow', () => {
                setShowMenu(false);
            });

            void Keyboard.addListener('keyboardDidHide', () => {
                setShowMenu(true);
            });

            return () => {
                void Keyboard.removeAllListeners();
            };
        }
    }, []);

    if (!showMenu || !currentPage.appMenuActive) {
        return null;
    }
    return (
        <MenuContainer {...ddRumPrivacySetting('allow')}>
            <CenteredColumn forwardedAs={Link} to={PATH_HOME}>
                <Icon
                    icon="home"
                    variantOverride={
                        location.pathname.includes(PATH_HOME)
                            ? 'filled'
                            : undefined
                    }
                />
                <LabelText>{lang.frontpage}</LabelText>
            </CenteredColumn>
            <CenteredColumn forwardedAs={Link} to={urlToUnreadMessages}>
                <MessageBadgeContainer>
                    <MessageBadge unread={numberOfUnreadMessages} />
                </MessageBadgeContainer>

                <Icon
                    icon="chat"
                    variantOverride={
                        location.pathname.includes(PATH_MESSAGES)
                            ? 'filled'
                            : undefined
                    }
                />
                <LabelText>{lang.messages}</LabelText>
            </CenteredColumn>
            <CenteredColumn forwardedAs={Link} to={PATH_EXPLORE}>
                <Icon
                    icon="explore"
                    variantOverride={
                        location.pathname.includes(PATH_EXPLORE)
                            ? 'filled'
                            : undefined
                    }
                />
                <LabelText>{lang.explore}</LabelText>
            </CenteredColumn>
            <CenteredColumn forwardedAs={Link} to={PATH_PROFILE_OVERVIEW}>
                <Icon
                    icon="settings"
                    variantOverride={
                        location.pathname.includes(PATH_PROFILE_OVERVIEW) ||
                        location.pathname.includes(PATH_MY_PROFILE) ||
                        location.pathname.includes(PATH_MY_PREFERRED_DEALER)
                            ? 'filled'
                            : undefined
                    }
                />
                <LabelText>{lang.settings}</LabelText>
            </CenteredColumn>
        </MenuContainer>
    );
};

export default AppMenu;

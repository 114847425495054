export const SERVICE_GROUP_ID = {
    tireGroup: 'custom-tire-group',
    recommendedGroup: 'custom-recommended-group',
} as const;

export const SERVICE_GROUP_IDS: string[] = Object.values(SERVICE_GROUP_ID);

export const SERVICE_WITH_PPS_DATADOG = 'Service med PPS-pakker';

export const GLASS_DAMAGE_ID = {
    large: '5-large',
    small: '5-small',
    edge: '5-edge',
} as const;

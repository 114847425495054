import {
    Headline,
    InfoCard,
    PrimaryButton,
    Subtitle,
    breakpointTablet,
} from '@moller/design-system';
import { ReservedTimeSlotViewModel } from 'external-apis/src/types/port';
import { useFlag } from 'feature-toggle';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import { useGetServices } from '../../../_api/http/services';
import { useGetVehicle } from '../../../_api/http/vehicle';
import {
    DDRumPrivacySetting,
    ddRumPrivacySetting,
} from '../../../components/DDRumPrivacySetting';
import { FlexRow } from '../../../components/FlexRow';
import { SellVehicleCard } from '../../../components/SellVehicleCard';
import { BilholdInnerViewLayout } from '../../../components/bilhold/BilholdView';
import { IcsCalendar } from '../../../lib/IcsCalendar';
import { PATH_HOME } from '../../../lib/constants/routes';
import { useLanguageContext } from '../../../lib/languages/languageContext';
import { DealerFacilities } from '../../transport-services/DealerFacilities';
import { NpsInput } from '../net-promoter-score/NpsInput';

export interface ConfirmBookingDoneProps {
    email: string;
    phone: string;
    selectedDealerNumber: string;
    registrationNumber: string;
    isBookingRequest: boolean;
    mileage?: string;
    reservedTimeSlot?: ReservedTimeSlotViewModel;
    dealerNumber: string;
    dealerName?: string;
}

export function ConfirmBookingDoneInternal({
    email,
    phone,
    reservedTimeSlot,
    registrationNumber,
    mileage,
    dealerNumber,
    selectedDealerNumber,
    isBookingRequest,
}: ConfirmBookingDoneProps) {
    const [lc] = useLanguageContext();

    const vehicle = useGetVehicle(registrationNumber);

    const serviceResponse = useGetServices({
        vin: vehicle.data?.vehicleIdentificationNumber,
        dealerNumbers: [selectedDealerNumber],
    });

    const showWaitingServicesInSummary = useFlag(
        'show-waiting-services-in-summary'
    );

    window.scrollTo(0, 0);

    const receiptText = email
        ? lc.confirmBooking.done_receipt_email.replace('{0}', email)
        : lc.confirmBooking.done_receipt_sms.replace('{0}', phone);
    const receiptTextRequest = email
        ? lc.confirmBooking.done_receipt_email_request.replace('{0}', email)
        : lc.confirmBooking.done_receipt_sms_request.replace('{0}', phone);
    return (
        <DDRumPrivacySetting privacyLevel="allow">
            <Headline>
                {isBookingRequest
                    ? lc.confirmBooking.done_headline_request
                    : lc.confirmBooking.done_headline}
            </Headline>
            <p {...ddRumPrivacySetting('mask')}>
                {isBookingRequest ? receiptTextRequest : receiptText}
            </p>
            <BilholdInnerViewLayout>
                <Subtitle>{lc.confirmBooking.done_remember_headline}</Subtitle>
                <InfoCard density="compact">
                    <RememberList>
                        <li>
                            {lc.confirmBooking.done_remember_driver_license}
                        </li>
                        <li>{lc.confirmBooking.done_remember_booklet}</li>
                        <li>{lc.confirmBooking.done_remember_user_info}</li>
                        <li>
                            {
                                lc.chooseTimeslot
                                    .pre_appointment_delivery_info_done
                            }
                        </li>
                        <li>{lc.prices.disclaimer_price}</li>
                        <li>{lc.prices.disclaimer_price_material}</li>
                    </RememberList>
                </InfoCard>
            </BilholdInnerViewLayout>
            <SellVehicleCard
                id="card_after_booking"
                regNumber={registrationNumber}
                dealerNumber={selectedDealerNumber}
                phone={phone}
            />
            {serviceResponse.isSuccess &&
                serviceResponse.data.Waiting.length > 0 &&
                showWaitingServicesInSummary && (
                    <DealerFacilities
                        waitingServices={serviceResponse.data.Waiting}
                    />
                )}
            {reservedTimeSlot && (
                <IcsCalendar
                    regNumber={registrationNumber}
                    timeSlot={reservedTimeSlot}
                    mileage={mileage}
                />
            )}
            <MakeNewOrderFlexBox>
                {!isBookingRequest && (
                    <p>{lc.confirmBooking.done_edit_order}</p>
                )}
                <RedirectLoggedIn />
            </MakeNewOrderFlexBox>
            <NpsInput dealerNumber={dealerNumber} />
        </DDRumPrivacySetting>
    );
}

function RedirectLoggedIn() {
    const navigate = useNavigate();
    const [lc] = useLanguageContext();
    return (
        <PrimaryButton onClick={() => navigate(PATH_HOME)}>
            {lc.confirmBooking.done_redirect_btn}
        </PrimaryButton>
    );
}

const MakeNewOrderFlexBox = styled(FlexRow)`
    align-items: center;
    @media (max-width: ${breakpointTablet}) {
        flex-direction: column;
        align-items: end;
        gap: var(--moller-spacing-m);
    }
`;

const RememberList = styled.ul`
    @media (max-width: ${breakpointTablet}) {
        padding-left: var(--moller-spacing-l);
    }
`;

import {
    ActionCard,
    Description,
    breakpointTablet,
} from '@moller/design-system';
import { CarViewModel } from 'external-apis/src/types/carcare';
import { styled } from 'styled-components';
import { BoldText } from '../../../components/BoldText';
import { VehicleSectionState } from '../ChooseVehicleSection';

type ChooseRegisteredVehicleProps = {
    setSectionState: (x: VehicleSectionState) => void;
    registeredVehicles: CarViewModel[];
};

const RegisteredCarsLayout = styled.div`
    display: grid;
    @media (min-width: ${breakpointTablet}) {
        grid-template-columns: repeat(2, 1fr);
    }
    gap: var(--moller-spacing-xxs);
`;

export const ChooseRegisteredVehicle: React.FC<
    ChooseRegisteredVehicleProps
> = ({ setSectionState, registeredVehicles }) => {
    const registeredVehicleCards = registeredVehicles.map((car) => (
        <ActionCard
            // Ensure plateNumber is not included in datadog RUM action name
            data-dd-action-name={`${car.brandName} ${car.modelName}`}
            key={car.vehicleIdentificationNumber}
            density="compact"
            onClick={() =>
                setSectionState({
                    viewMode: 'done',
                    data: { registrationNumber: car.plateNumber },
                })
            }
        >
            <BoldText>
                {car.brandName} {car.modelName}
            </BoldText>
            <Description>{car.plateNumber}</Description>
        </ActionCard>
    ));

    return (
        <RegisteredCarsLayout>{registeredVehicleCards}</RegisteredCarsLayout>
    );
};

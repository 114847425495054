import { ServiceViewModel } from 'external-apis/src/types/port';
import { useAppConfig } from './config/configContext';
import { FastTrackType } from '../_api/urlSearchParameters';
import { useEffect, useState } from 'react';
import { useStandardServicesForm } from '../sections/standard-services/useSectionForm';
import { SERVICE_GROUP_ID } from './constants/serviceConstants';

type UseFastTrackServices = {
    form: ReturnType<typeof useStandardServicesForm>;
    services: ServiceViewModel[] | undefined;
    fastTrack: FastTrackType | undefined;
};

function useFastTrackServices({
    form,
    services,
    fastTrack,
}: UseFastTrackServices) {
    const [enabled, setEnabled] = useState(!!fastTrack);
    useEffect(() => {
        const setValue = form.setValue('selectedIds');
        if (!(services && enabled)) return;
        const fastTrackServices = getFastTrackServices(services, fastTrack);
        if (!fastTrackServices) return;
        setValue([...fastTrackServices.preselected]);
        setEnabled(false);
    }, [fastTrack, services, form, enabled]);
}

export function useFastTrack() {
    const { fastTrack } = useAppConfig().bookingParameters;
    const useSelectServices = useFastTrackServices;
    return {
        fastTrack,
        useSelectServices,
    };
}

function getServiceCategory(
    serviceModels: ServiceViewModel[],
    category: Exclude<ServiceViewModel['category'], undefined>
) {
    return serviceModels.filter((service) => service.category === category);
}

export function getFastTrackServices(
    serviceModels: ServiceViewModel[],
    fastTrack: FastTrackType | undefined
): { services: ServiceViewModel[]; preselected: string[] } | undefined {
    if (!fastTrack) {
        return undefined;
    }

    switch (fastTrack) {
        case 'glass-damage': {
            const services = getServiceCategory(serviceModels, 'GlassDamage');
            return { services, preselected: services.map((x) => x.id) };
        }
        case 'tire-change': {
            const services = getServiceCategory(serviceModels, 'Tire');
            return {
                services,
                preselected: [
                    SERVICE_GROUP_ID.tireGroup,
                    sortServicesByPrice(services).map((x) => x.id)[0],
                ],
            };
        }
    }
}

function sortServicesByPrice(services: ServiceViewModel[]) {
    return services.sort(
        (a, b) =>
            (b.dealerSpecificInformation[0]?.price?.fixedPrice || 0) -
            (a.dealerSpecificInformation[0]?.price?.fixedPrice || 0)
    );
}

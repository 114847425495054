import { MockData } from '../data';
import { HistoricBooking } from 'src/features/my-car/query/useWorkshopHistory';

const getHistoricAppointment = (
    vin: string,
    mockData: MockData
): HistoricBooking[] | null => {
    const car = mockData.cars.find((car) => car.vin === vin);

    if (!car) {
        return null;
    }

    const appointments: HistoricBooking[] = [
        {
            date: '2021-09-07',
            dealerName: 'Møller Bil Etterstad',
            invoices: [
                {
                    invoiceNumber: '68025120',
                    path: 'api/documents/invoices/dealer/083/year/2021/invoiceNumber/68025120',
                },
            ],
            type: 'EU-kontroll',
            taskNames: [
                'EU-kontroll',
                'Tillegg inntil kr 1000 uten å ringe deg',
                'Verkstedmateriell og kildesortering',
                'Oljeskiftservice (fleksibel)',
                'Oppd styreenhet dsg - 34h5',
                'Skifte bremseskiver og klosser foran',
                'Skifte bremseskiver og klosser bak',
            ],
            id: '571531',
            adapterId: 'mnet-adapter',
        },
        {
            date: '2020-05-27',
            dealerName: 'Møller Bil Etterstad',
            invoices: [
                {
                    invoiceNumber: '68025120',
                    path: 'api/documents/invoices/dealer/083/year/2021/invoiceNumber/68025120',
                },
            ],
            type: 'Service',
            taskNames: [
                'EU-kontroll',

                'Tillegg inntil kr 1000 uten å ringe deg',

                'Verkstedmateriell og kildesortering',

                'Oljeskiftservice (fleksibel)',
                'Oppd styreenhet dsg - 34h5',
                'Skifte bremseskiver og klosser foran',
                'Skifte bremseskiver og klosser bak',
            ],
            id: '571532',
            adapterId: 'mnet-adapter',
        },
        {
            date: '2021-10-03',
            dealerName: 'Møller Bil Etterstad',
            invoices: [
                {
                    invoiceNumber: '68025120',
                    path: 'api/documents/invoices/dealer/083/year/2021/invoiceNumber/68025120',
                },
            ],
            type: 'EU-kontroll',
            taskNames: [
                'EU-kontroll',
                'Tillegg inntil kr 1000 uten å ringe deg',
                'Verkstedmateriell og kildesortering',
                'Oljeskiftservice (fleksibel)',
                'Oppd styreenhet dsg - 34h5',
                'Skifte bremseskiver og klosser foran',
                'Skifte bremseskiver og klosser bak',
            ],
            id: '571533',
            adapterId: 'mnet-adapter',
        },
        {
            date: '2022-04-14',
            dealerName: 'Møller Bil Etterstad',
            invoices: [
                {
                    invoiceNumber: '68025120',
                    path: 'api/documents/invoices/dealer/083/year/2021/invoiceNumber/68025120',
                },
            ],
            type: 'Service',
            taskNames: [
                'EU-kontroll',
                'Tillegg inntil kr 1000 uten å ringe deg',
                'Verkstedmateriell og kildesortering',
                'Oljeskiftservice (fleksibel)',
                'Skifte bremseskiver og klosser foran',
                'Skifte bremseskiver og klosser bak',
            ],
            id: '571534',
            adapterId: 'mnet-adapter',
        },
    ];

    return appointments.slice(0, car.pastAppointments);
};

export default getHistoricAppointment;

import { datadogRum } from '@datadog/browser-rum';
import {
    AlertBanner,
    Checkbox,
    Column,
    RadioButton,
    Subtitle,
    TextContainer,
} from '@moller/design-system';
import { RefObject, useEffect, useRef, useState } from 'react';
import { ddRumPrivacySetting } from 'src/utils/datadog';
import lang from 'src/utils/lang';
import { styled } from 'styled-components';
import { NextStepButton, SectionTitle, StyledTextField } from '../Damage';
import DamageAppraisal from '../utils/DamageAppraisal';
import { hasValidImages, hasValidPaymentInfo } from '../utils/damageValidation';
import insuranceCompanies from '../utils/insuranceCompanies';
import { useUpdateDamageAppraisal } from '../utils/useDamageAppraisals';
import { useGetMedia } from '../utils/useMedia';

const StyledSelect = styled.select`
    width: 100%;
    max-width: 300px;
    height: var(--moller-size-xxl);
    background-color: var(--moller-color-surface);
    box-sizing: border-box;
    box-shadow: 0 0 0 var(--moller-stroke-small) var(--border-color);
    border-radius: var(--moller-radius-input);
`;

interface DamagePaymentProps {
    damage: DamageAppraisal;
    detailsStepDone: boolean;
    paymentStepDone: boolean;
    setPaymentStepDone: (value: boolean) => void;
    headingRef: RefObject<HTMLHeadingElement>;
}

const DamagePayment = ({
    damage,
    detailsStepDone,
    paymentStepDone,
    setPaymentStepDone,
    headingRef,
}: DamagePaymentProps) => {
    const { mutate: updateDamage, isError } = useUpdateDamageAppraisal(
        damage.id
    );
    const datadogSent = useRef(false);

    const [isCustomInsuranceCompany, setIsCustomInsuranceCompany] = useState(
        !insuranceCompanies.some(
            (x) => x.value === (damage.insuranceCompanyName ?? '')
        )
    );
    const [customInsurance, setCustomInsurance] = useState(
        isCustomInsuranceCompany ? damage.insuranceCompanyName ?? '' : ''
    );
    const [insuranceNumber, setInsuranceNumber] = useState(
        damage.insuranceNumber ?? ''
    );
    const { data: media } = useGetMedia(damage?.id);

    const showPayment =
        damage?.description &&
        media &&
        hasValidImages(media) &&
        detailsStepDone;

    const isStepInvalid = !hasValidPaymentInfo(damage);
    useEffect(() => {
        if (isStepInvalid) {
            setPaymentStepDone(false);
        }
    }, [isStepInvalid, setPaymentStepDone]);

    if (detailsStepDone && !datadogSent.current) {
        datadogRum.addAction('Damage payment');
        datadogSent.current = true;
    }

    return (
        <TextContainer {...ddRumPrivacySetting('allow')}>
            <Subtitle ref={headingRef}>{lang.damagePaymentTitle}</Subtitle>
            {showPayment ? (
                <Column gap={'xs'}>
                    <p>{lang.damagePaymentDescription}</p>
                    <SectionTitle>{lang.isInsuranceCase}</SectionTitle>
                    <RadioButton
                        label={lang.yes}
                        id={`${lang.isInsuranceCase}-${lang.yes}`}
                        value={!damage.customerPaying}
                        onChange={() => {
                            updateDamage({ customerPaying: false });
                        }}
                    />
                    <RadioButton
                        label={lang.no}
                        id={`${lang.isInsuranceCase}-${lang.no}`}
                        value={damage.customerPaying}
                        onChange={() => updateDamage({ customerPaying: true })}
                    />
                    {!damage.customerPaying && (
                        <>
                            <SectionTitle>
                                {lang.chooseInsuranceCompany}*
                            </SectionTitle>
                            <StyledSelect
                                value={damage.insuranceCompanyName ?? ''}
                                onChange={(e) => {
                                    setIsCustomInsuranceCompany(false);
                                    setCustomInsurance('');
                                    updateDamage({
                                        insuranceCompanyName: e.target.value,
                                    });
                                }}
                            >
                                {insuranceCompanies.map((c) => (
                                    <option key={c.value} value={c.value}>
                                        {c.label}
                                    </option>
                                ))}
                            </StyledSelect>
                            <Checkbox
                                label={lang.needCustomInsuranceCompany}
                                value={isCustomInsuranceCompany}
                                onChange={(e) => {
                                    setIsCustomInsuranceCompany(
                                        e.target.checked
                                    );
                                    setCustomInsurance('');
                                    updateDamage({
                                        insuranceCompanyName: undefined,
                                    });
                                }}
                            />
                            {isCustomInsuranceCompany && (
                                <StyledTextField
                                    label={lang.insuranceCompany}
                                    value={customInsurance}
                                    onChange={(e) =>
                                        setCustomInsurance(e.target.value)
                                    }
                                    onBlur={() => {
                                        if (
                                            damage.insuranceCompanyName !==
                                            customInsurance
                                        ) {
                                            updateDamage({
                                                insuranceCompanyName:
                                                    customInsurance,
                                            });
                                        }
                                    }}
                                    helperText={
                                        lang.customInsuranceCompanyHelpText
                                    }
                                />
                            )}
                            <SectionTitle>{lang.damageNumber}</SectionTitle>
                            <p>{lang.damageNumberDescription}</p>
                            <StyledTextField
                                label={lang.damageNumber}
                                value={insuranceNumber}
                                onChange={(e) =>
                                    setInsuranceNumber(e.target.value)
                                }
                                onBlur={() => {
                                    if (
                                        damage.insuranceNumber !==
                                        insuranceNumber
                                    ) {
                                        updateDamage({ insuranceNumber });
                                    }
                                }}
                            />
                        </>
                    )}
                    <SectionTitle>{lang.needsReplacementCar}</SectionTitle>
                    <RadioButton
                        label={lang.yes}
                        id={`${lang.needsReplacementCar}-${lang.yes}`}
                        value={damage.needsReplacementCar}
                        onChange={() =>
                            updateDamage({
                                needsReplacementCar: true,
                            })
                        }
                    />
                    <RadioButton
                        label={lang.no}
                        id={`${lang.needsReplacementCar}-${lang.no}`}
                        value={!damage.needsReplacementCar}
                        onChange={() =>
                            updateDamage({
                                needsReplacementCar: false,
                            })
                        }
                    />
                    {!paymentStepDone && (
                        <NextStepButton
                            disabled={isStepInvalid}
                            setStepDone={setPaymentStepDone}
                        />
                    )}
                </Column>
            ) : (
                <p>Du må fylle inn beskrivelse og bilder først</p>
            )}
            {isError && (
                <AlertBanner type="error" message={lang.damageGeneralError} />
            )}
        </TextContainer>
    );
};

export default DamagePayment;

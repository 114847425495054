import { Services } from '../../_api/http/services';
import {
    useValidDate,
    useValidLength,
    useValidMileage,
    useValidServiceAdditionalInfo,
    useValidStandardServices,
} from '../../lib/form/validators';

export function useSectionValidators(availableServices: Services | undefined) {
    const validMileage = useValidMileage();
    const glassDamageWhereMaxLength = 150;
    const validServiceIds = useValidStandardServices(
        availableServices,
        glassDamageWhereMaxLength
    );
    const validServiceAdditionalInfo =
        useValidServiceAdditionalInfo(availableServices);
    const validGlassDamageDate = useValidDate(availableServices);
    const validGlassDamageLocation = useValidLength(
        glassDamageWhereMaxLength,
        availableServices
    );
    return {
        validMileage,
        validServiceIds,
        validServiceAdditionalInfo,
        validGlassDamageDate,
        validGlassDamageLocation,
    };
}

import { Description, InfoCard } from '@moller/design-system';
import { ServiceViewModel } from 'external-apis/src/types/port';
import React from 'react';
import { styled } from 'styled-components';
import { useLanguageContext } from '../../lib/languages/languageContext';
import { ServicePrice } from '../../sections/standard-services/price/ServicePrice';
import { SelectedService } from '../../sections/standard-services/useSectionForm';
import { BoldText } from '../BoldText';
import { BilholdViewLayout } from '../bilhold/BilholdView';
import {
    ServicePriceSummary,
    TotalPriceContainer,
} from '../prices/ServicePriceSummary';

type ServicesDoneProps = {
    selectedServices: ServiceViewModel[];
    additionalInfo?: SelectedService[];
};
export const ServiceCommentDescription = styled.div`
    padding-bottom: var(--moller-spacing-xs);
    font-size: 0.8rem;
`;

const hasComment = (service: ServiceViewModel) =>
    service.additionalInfo?.some((x) => x.infoType === 'Comment');

export const ServicesDone: React.FC<ServicesDoneProps> = ({
    selectedServices,
    additionalInfo,
}) => {
    const [lc] = useLanguageContext();

    return (
        <BilholdViewLayout>
            {selectedServices.map((service) => (
                <InfoCard
                    density="compact"
                    key={`${service.adapterId}_${service.id}`}
                >
                    <BoldText>{service.name}</BoldText>
                    {hasComment(service) && (
                        <ServiceCommentDescription>
                            <>{lc.components.comment} </>
                            {
                                additionalInfo?.find(
                                    (info) => info.serviceId === service.id
                                )?.comment
                            }
                        </ServiceCommentDescription>
                    )}
                    <Description>
                        <ServicePrice
                            service={{ type: 'service', service: service }}
                        />
                    </Description>
                </InfoCard>
            ))}
            <TotalPriceContainer>
                <p>{lc.prices.sum}</p>
                <ServicePriceSummary services={selectedServices} />
            </TotalPriceContainer>
        </BilholdViewLayout>
    );
};

import { InfoCard, Subtitle } from '@moller/design-system';
import React from 'react';
import { styled } from 'styled-components';
import PreparationSVG from './svgs/fiks_ferdig.svg?react';
import WarrantySVG from './svgs/garantiansvar.svg?react';
import StressFreeSVG from './svgs/stressfritt.svg?react';
import NonBindingSVG from './svgs/uforpliktende.svg?react';

const StyledInfoCard = styled(InfoCard)`
    margin-top: var(--moller-spacing-s);
    background-color: var(--moller-color-background);
    display: flex;
    gap: var(--moller-spacing-m);
    align-items: center;
    border: 1px solid #000;
`;

type BenefitType = 'warranty' | 'preparation' | 'stressFree' | 'nonBinding';

interface BenefitContent {
    title: string;
    description: string;
    illustration: React.ReactNode;
}

const getBenefitContent = (type: BenefitType): BenefitContent | null => {
    switch (type) {
        case 'warranty':
            return {
                title: 'Slipp 2 års garantiansvar',
                description:
                    'Vi står ansvarlig dersom det skulle dukke opp feil med bilen etter at den er solgt.',
                illustration: <WarrantySVG />,
            };
        case 'preparation':
            return {
                title: 'Fiks ferdig til neste eier',
                description:
                    'Vi undersøker, reparerer, vasker, polerer og klargjør bilen slik at neste eier blir fornøyd.',
                illustration: <PreparationSVG />,
            };
        case 'stressFree':
            return {
                title: 'Stressfritt',
                description:
                    'Du slipper å lage annonse, ta tekniske sjekker, og alt papirarbeid. Vi tar oss også av eventuell gjeld på bilen. Du får raskt oppgjør og penger på konto.',
                illustration: <StressFreeSVG />,
            };
        case 'nonBinding':
            return {
                title: 'Uforpliktende tilbud',
                description:
                    'Alt er helt uforpliktende. Du velger selv om du godtar tilbudet.',
                illustration: <NonBindingSVG />,
            };
        default:
            return null;
    }
};

interface BenefitCardProps {
    type: BenefitType;
}

export const BenefitCard = ({ type }: BenefitCardProps) => {
    return (
        <StyledInfoCard>
            <div>
                <Subtitle as="h4">{getBenefitContent(type)?.title}</Subtitle>
                {getBenefitContent(type)?.description}
            </div>
            <div>{getBenefitContent(type)?.illustration}</div>
        </StyledInfoCard>
    );
};

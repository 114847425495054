import { useLottie } from 'lottie-light-react';
import { styled } from 'styled-components';
import { Transition } from '../../components/Transition';
import animationData from './Skoda_Hvit.json';

const AnimationContainer = styled.div`
    * svg {
        animation-duration: 1s;
        animation-name: slideup;
        animation-delay: 1.5s;
        animation-fill-mode: forwards;
    }

    @keyframes slideup {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
            display: none;
        }
    }
`;

const SkodaTransition = () => {
    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData,
        style: {
            height: 150,
            width: 300,
            margin: 'auto',
        },
    };

    const { View } = useLottie(defaultOptions);

    return (
        <Transition delay={1.5} duration={2}>
            <AnimationContainer>{View}</AnimationContainer>
        </Transition>
    );
};

export default SkodaTransition;

import { VideosQueryResult } from '@bilhold/sanity';
import { datadogRum } from '@datadog/browser-rum';
import {
    breakpointTablet,
    LoadingSpinner,
    TextContainer,
} from '@moller/design-system';
import groq from 'groq';
import { useEffect } from 'react';
import { useSanityData } from 'src/sanity/useSanityData';
import { OrderedCar } from 'src/types/OrderedCar';
import { styled } from 'styled-components';
import { OrderedCarVideos } from './OrderedCarVideos';
import { SanityCarVideos } from './SanityCarVideos';

interface OrderedCarVideoContainerProps {
    car: OrderedCar;
}

const Container = styled(TextContainer)`
    width: 90vw;
    padding-top: var(--moller-spacing-xs);
    padding-bottom: var(--moller-spacing-xxl);
    margin: auto;
    max-width: 1200px;
    @media screen and (min-width: ${breakpointTablet}) {
        width: 80vw;
    }
`;
export const OrderedCarVideoContainer = ({
    car,
}: OrderedCarVideoContainerProps) => {
    const videosQuery = groq`*[_type == "video" && references(*[_type == "carType" && $modelType in modelType[] && carBrand == $carBrand]._id)] | order(priority asc)`;

    const {
        data: videos,
        isLoading,
        error,
    } = useSanityData<VideosQueryResult>(videosQuery, {
        modelType: car.model.type,
        carBrand: car.brand,
        modelYear: car.model.year,
    });

    useEffect(() => {
        if (!videos || videos.length === 0) {
            return;
        }

        const videoTitles = videos.map((v) => v.name);

        datadogRum.addAction('bilhold-user-sees-sanity-videos', {
            modelType: car.model.type,
            carBrand: car.brand.toString(),
            modelYear: car.model.year,
            videoTitles,
        });
    }, [car.brand, car.model.type, car.model.year, videos]);

    if (isLoading) {
        return (
            <Container>
                <LoadingSpinner isFullScreen />
            </Container>
        );
    }
    if (error) {
        datadogRum.addError(
            `Error received when trying to fetch Sanity data from ${car.model.type} ${car.model.text} ${car.model.year}}`
        );
    }

    if (videos && videos.length > 0) {
        return <SanityCarVideos car={car} videos={videos} />;
    }

    return <OrderedCarVideos car={car} />;
};

import {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useState,
} from 'react';

type TransitionState = { myCar: boolean; frontPage: boolean };
const TransistionContext = createContext<
    | {
          shouldRunTransition: TransitionState;
          setShouldRunTransition: (state: Partial<TransitionState>) => void;
      }
    | undefined
>(undefined);

export const TransitionContextProvider = ({
    children,
}: {
    children: ReactNode;
}) => {
    const [shouldRunTransition, setInternalShouldRunTransition] = useState({
        myCar: true,
        frontPage: true,
    });
    const setShouldRunTransition = useCallback(
        (state: Partial<TransitionState>) => {
            setInternalShouldRunTransition((prev) => ({ ...prev, ...state }));
        },
        []
    );

    return (
        <TransistionContext.Provider
            value={{
                shouldRunTransition,
                setShouldRunTransition,
            }}
        >
            {children}
        </TransistionContext.Provider>
    );
};

export const useTransitionContext = () => {
    const context = useContext(TransistionContext);
    if (context === undefined) {
        throw new Error(
            'useTransitionContext must be used within a TransitionContextProvider'
        );
    }
    return context;
};

import { useMutation } from '@tanstack/react-query';
import put from 'src/utils/http/put';

// This is also typed in bilhold-admin
export const DamageValuationStatus = {
    NEW: 'NEW',
    PROCESSING: 'PROCESSING',
    REQUESTED_CHANGES: 'REQUESTED_CHANGES',
    RECEIVED_CHANGES: 'RECEIVED_CHANGES',
    VALUATION_SENT: 'VALUATION_SENT',
    COMPLETED: 'COMPLETED',
    UNKNOWN: 'UNKNOWN',
} as const;

// This is used for setting case in bilhold-admin to "PÅGÅENDE SAKER"
export const useDavStatusUpdate = (externalId?: string | number) => {
    const body = {
        davStatus: DamageValuationStatus.RECEIVED_CHANGES,
    };
    return useMutation({
        mutationFn: () => put(`api/dav-status/id/${externalId}`, body),
    });
};

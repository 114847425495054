import { Checkbox, InputHelperText, useForm } from '@moller/design-system';

type Option<T> = T extends undefined
    ? { label: string; value: string }
    : { label: string; value: string; item: T };
type MultiCheckboxInputProps<T> = {
    id: string;
    options: Option<T>[];
    field: string;
    render?: (checkbox: JSX.Element, item: T) => JSX.Element;
    hideErrorText?: boolean;
};

export function MultiCheckboxInput<TItem = undefined>({
    id,
    options,
    render,
    field,
    hideErrorText,
}: MultiCheckboxInputProps<TItem>) {
    const formContext = useForm<{ [key: string]: string[] }>();

    if (!formContext) {
        throw new Error('Used form input outside of form!');
    }

    const selectedValues = formContext.form.state.raw[field];

    const setValue = formContext.form.setValue(field);
    const isSelected = new Map(selectedValues.map((x) => [x, true]));

    const validity = formContext.form.inputProps(field).validity;

    const checkbox = (option: Option<TItem>) => (
        <Checkbox
            id={option.value}
            key={option.value}
            value={isSelected.get(option.value)}
            label={option.label}
            onChange={(event) => {
                if (event.target.checked) {
                    setValue([...selectedValues, option.value]);
                } else {
                    setValue(selectedValues.filter((x) => x !== option.value));
                }
            }}
            validity={validity && { type: validity.type }}
        />
    );

    return (
        <>
            {options.map((option) => {
                if ('item' in option && render) {
                    return render(checkbox(option), option.item);
                } else if (render) {
                    throw new Error('Render function provided without item!');
                }
                return checkbox(option);
            })}
            {!hideErrorText && (
                <InputHelperText
                    reserveSpaceForMessage={false}
                    {...{ id, validity }}
                />
            )}
        </>
    );
}

import { PreferredAffiliationDealerResponse } from 'src/features/home/queries/usePreferredDealer';
import useFeatureToggle from 'src/utils/dev-tools/useFeatureToggle';
import { formatDealerName } from 'src/utils/formatDealerName';
import { useTireHotelDealMDH } from '../../queries/useTireHotelDealMDH';
import {
    dealersWithDekkhotell,
    mittDekkhotellDealers,
} from './TireHotelDealers';

export const useTireCardContent = (
    vin: string,
    preferredDealer?: PreferredAffiliationDealerResponse
): ContentType | null => {
    const showTireChange = useFeatureToggle('ShowTireChange');
    const mittDekkhotellCampaign = useFeatureToggle('MittDekkhotellCampaign');

    const tireHotelDealMDH = useTireHotelDealMDH(vin);

    if (
        !showTireChange ||
        !preferredDealer ||
        tireHotelDealMDH?.data?.isTireHotelCar
    )
        return null;

    // Render card content based on dealer affiliation
    if (dealersWithDekkhotell.includes(preferredDealer?.preferredDealerId)) {
        return DealerWithDekkhotellContent;
    } else if (
        mittDekkhotellDealers.includes(preferredDealer?.preferredDealerId) &&
        mittDekkhotellCampaign
    ) {
        return MittDekkhotellCampaignContent(preferredDealer);
    }

    return StandardCarTireContent;
};

export type ContentType = {
    type: 'internal' | 'external' | 'standard';
    cardTitle: string;
    cardDescription: string;
    modalHeader: string;
    modalTitle: string;
    modalDescription: string[];
    modalDetail?: string;
    infoText?: string;
    modalButton: string;
    modalButtonLink?: string;
};

const StandardCarTireContent: ContentType = {
    type: 'standard',
    cardTitle: 'Vi fikser hjulskift for deg!',
    cardDescription:
        'Du slipper stresset med å bytte selv – og kan nyte en kaffe, eller gjør noe annet fornuftig, mens du venter. Bestill time i dag!',
    modalHeader: 'Hjulskift',
    modalTitle: 'Vi sørger for at bilen er klar for en ny sesong på veien.',
    modalDescription: [
        'Det er viktig at bilen din alltid er riktig skodd for føret du kjører på. Vi hjelper deg med hjulskift, så slipper du å bytte hjul selv.',
    ],
    modalButton: 'Bestill hjulskift',
};

const DealerWithDekkhotellContent: ContentType = {
    type: 'internal',
    cardTitle: 'Tar dekkene dine mye plass?',
    cardDescription: `Dekkene dine har det best på dekkhotell. Bestill dekkhotell hos oss! Hjulskift er inkludert!`,
    modalHeader: 'Dekkhotell',
    modalTitle: 'Vi sørger for at bilen er klar for en ny sesong på veien.',
    modalDescription: [
        'Dekkene dine har det best på et dekkhotell når de ikke er i bruk. Hos oss utfører vi hjulskift, vasker og kontrollerer dekkene grundig, før vi oppbevarer dem trygt innendørs',
        'Den største fordelen med å oppbevare dekkene på et dekkhotell, er at det forlenger levetiden. I tillegg er det praktisk og trygt.',
        'Hos Møller Bils dekkhotell inspiserer vi alle dekk og kommer med anbefalinger til hva slags dekk du bør velge til bilen din. Profesjonelle fagfolk sørger for at dekkene dine alltid er i gode hender.',
    ],
    modalButton: 'Bestill dekkhotell',
};

const MittDekkhotellCampaignContent = (
    preferredDealer: PreferredAffiliationDealerResponse
): ContentType => {
    return {
        type: 'external',
        cardTitle: 'Dekkhotell – enkelt og bekymringsfritt',
        cardDescription: `Få 50% rabatt* på første sesong med dekkhotell hos vår partner Mitt Dekkhotell.`,
        modalHeader: 'Dekkhotell',
        modalTitle:
            'Få 50% rabatt* på første sesong med dekkhotell hos vår partner Mitt Dekkhotell.',
        modalDescription: [
            `Dekkene dine har det best på hotell når de ikke er i bruk. Vi på ${formatDealerName(
                preferredDealer?.preferredDealerName
            )} har gleden av å tilby deg dekkhotell gjennom vår partner Mitt Dekkhotell. `,
            'Med dekkhotellavtale ordner Mitt Dekkhotell både hjulskift, lagring og forsikring av dekkene, samt kostnadsfri reperasjon av punktering så sant det er reparerbart.',
            'Bestill dekkhotell hos Mitt Dekkhotell nå og få 50% rabatt* på første sesong, som inkluderer skift til vinterhjul og lagring frem til vårens hjulskift',
        ],
        infoText:
            '*Tilbudet er for nye Mitt Dekkhotell kunder og gjelder skift til vinterhjul og lagring på dekkhotell frem til vårsesongen. ',
        modalButton: 'Bli dekkhotellkunde',
        modalButtonLink: `https://bestill.mittdekkhotell.no?cc=Ptlw`,
    };
};

import { useQuery } from '@tanstack/react-query';
import get from 'src/utils/http/get';
import { tireHotelDeal } from '../types/TireHotelDeal';

export const useTireHotelDeal = (vin: string | undefined) => {
    return useQuery({
        queryKey: ['TireHoteldeal', vin],
        queryFn: () =>
            get<tireHotelDeal[]>(`api/v3/tireHotelDeal/vin/${vin}`).then(
                (res) => res.data
            ),
        enabled: !!vin,
    });
};

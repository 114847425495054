import { styled } from 'styled-components';

const BadgeContainer = styled.div`
    background-color: var(--moller-color-error);
    color: var(--moller-color-on-error);
    height: var(--moller-size-xs);
    width: var(--moller-size-xs);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -5px;
    left: var(--moller-size-xs);
    pointer-events: none;
    padding: 2px;
    font-size: x-small;
`;

const MessageBadge = ({ unread }: { unread: number }) => {
    if (unread === 0) {
        return null;
    }
    return (
        <BadgeContainer>
            <p>{unread}</p>
        </BadgeContainer>
    );
};

export default MessageBadge;

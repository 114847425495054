import {
    AlertBanner,
    breakpointMobile,
    Checkbox,
    Column,
    formInputs,
} from '@moller/design-system';
import { Fragment, useEffect } from 'react';
import { styled } from 'styled-components';
import { GLASS_DAMAGE_ID } from '../../../lib/constants/serviceConstants';
import { RadioGroup } from '../../../lib/form/RadioGroup';
import {
    LanguageContextType,
    useLanguageContext,
} from '../../../lib/languages/languageContext';
import { StdServicesForm, useStandardServicesForm } from '../useSectionForm';

const { TextField: FormTextField } = formInputs<StdServicesForm>();

export function GlassService(props: {
    servicesForm: ReturnType<typeof useStandardServicesForm>;
}) {
    const [lc] = useLanguageContext();

    const selectedRadioOption =
        props.servicesForm.state.raw.glassDamageRadioOption;

    const selectedDamageKind = props.servicesForm.state.raw.glassDamageKind;
    const setSelectedDamageKind =
        props.servicesForm.setValue('glassDamageKind');
    const setDamageWhen = props.servicesForm.setValue('glassDamageWhen');
    const setDamageWhere = props.servicesForm.setValue('glassDamageWhere');

    useEffect(() => {
        const isLarge = selectedRadioOption === GLASS_DAMAGE_ID.large;
        const isSmall =
            selectedRadioOption === GLASS_DAMAGE_ID.small &&
            selectedDamageKind !== GLASS_DAMAGE_ID.edge;

        if (
            isLarge &&
            selectedDamageKind !== GLASS_DAMAGE_ID.large &&
            setSelectedDamageKind
        ) {
            setSelectedDamageKind(GLASS_DAMAGE_ID.large);
        }

        if (
            isSmall &&
            selectedDamageKind !== GLASS_DAMAGE_ID.small &&
            setSelectedDamageKind
        ) {
            setSelectedDamageKind(GLASS_DAMAGE_ID.small);
        }
    }, [
        lc.standardServices.glass_damage_large,
        lc.standardServices.glass_damage_small,
        selectedDamageKind,
        selectedRadioOption,
        setSelectedDamageKind,
    ]);

    const glassDamageOptions = [
        {
            id: GLASS_DAMAGE_ID.large,
            label: lc.standardServices.glass_damage_large,
        },
        {
            id: GLASS_DAMAGE_ID.small,
            label: lc.standardServices.glass_damage_small,
        },
    ];

    const radioGroupOptions = glassDamageOptions.map((x) => {
        return {
            value: x.id,
            label: x.label,
            item: { key: x.id },
        };
    });

    return (
        <Column gap="xxs">
            <RadioGroup
                id="glassdamage-radiogroup"
                options={radioGroupOptions}
                field={'glassDamageRadioOption'}
                initialValue={GLASS_DAMAGE_ID.large}
                render={(radioButton, item) => (
                    <Fragment key={item.key}>
                        {radioButton}
                        {item.key === GLASS_DAMAGE_ID.small &&
                            selectedRadioOption === item.key && (
                                <GlassDamageEdgeCheckbox
                                    setSelectedDamageKind={
                                        setSelectedDamageKind
                                    }
                                    selectedDamageKind={selectedDamageKind}
                                />
                            )}
                    </Fragment>
                )}
            />
            <GlassDamageInfoBox selectedDamageKind={selectedDamageKind} />
            <DamageFieldGrid>
                <Column>
                    <p>{lc.standardServices.glass_damage_when}?</p>
                    <FormTextField
                        label={'DD.MM.YYYY'}
                        className={'damage-input'}
                        field={'glassDamageWhen'}
                        validity={
                            props.servicesForm.inputProps('glassDamageWhen')
                                .validity
                        }
                        onChange={(e) => {
                            e.preventDefault();
                            setDamageWhen(e.currentTarget.value);
                        }}
                    />
                </Column>
                <Column>
                    <p>{lc.standardServices.glass_damage_where}?</p>
                    <FormTextField
                        label={lc.standardServices.glass_damage_location_label}
                        className={'damage-input'}
                        field={'glassDamageWhere'}
                        onChange={(e) => setDamageWhere(e.currentTarget.value)}
                    />
                </Column>
            </DamageFieldGrid>
        </Column>
    );
}

const DamageFieldGrid = styled.div`
    gap: var(--moller-spacing-s);
    display: grid;
    width: 100%;
    @media (min-width: ${breakpointMobile}) {
        grid-template-columns: 1fr 1fr;
    }
`;

const IndentedCheckbox = styled(Checkbox)`
    padding-left: 24px;
`;

function GlassDamageEdgeCheckbox({
    setSelectedDamageKind,
    selectedDamageKind,
}: {
    setSelectedDamageKind: (
        x: (typeof GLASS_DAMAGE_ID)[keyof typeof GLASS_DAMAGE_ID]
    ) => void;
    selectedDamageKind: (typeof GLASS_DAMAGE_ID)[keyof typeof GLASS_DAMAGE_ID];
}) {
    const [lc] = useLanguageContext();
    return (
        <IndentedCheckbox
            id={GLASS_DAMAGE_ID.edge}
            label={lc.standardServices.glass_damage_edge}
            value={selectedDamageKind === GLASS_DAMAGE_ID.edge}
            onChange={() => {
                if (selectedDamageKind === GLASS_DAMAGE_ID.edge) {
                    setSelectedDamageKind(GLASS_DAMAGE_ID.small);
                } else {
                    setSelectedDamageKind(GLASS_DAMAGE_ID.edge);
                }
            }}
        />
    );
}

function GlassDamageInfoBox({
    selectedDamageKind,
}: {
    selectedDamageKind: string;
}) {
    const [lc] = useLanguageContext();
    const smallDamage = selectedDamageKind.includes(GLASS_DAMAGE_ID.small);
    const heading = smallDamage
        ? lc.standardServices.glass_damage_info_title_small
        : lc.standardServices.glass_damage_info_title_large;
    const message = smallDamage
        ? lc.standardServices.glass_damage_info_description_small
        : lc.standardServices.glass_damage_info_description_large;

    return <AlertBanner type={'info'} heading={heading} message={message} />;
}

export function getGlassDamageComment(
    glassDamageKind: (typeof GLASS_DAMAGE_ID)[keyof typeof GLASS_DAMAGE_ID],
    glassDamageWhen: string,
    glassDamageWhere: string,
    lc: LanguageContextType
) {
    const glassDamageInfo = `\n${lc.standardServices.glass_damage_when}: ${glassDamageWhen}
        ${lc.standardServices.glass_damage_where}: ${glassDamageWhere}`;

    switch (glassDamageKind) {
        case GLASS_DAMAGE_ID.small: {
            return {
                comment:
                    lc.standardServices.glass_damage_title +
                    lc.standardServices.glass_damage_small +
                    glassDamageInfo,
                infoTitle: lc.standardServices.glass_damage_info_title_small,
                infoDescription:
                    lc.standardServices.glass_damage_info_description_small,
            };
        }
        case GLASS_DAMAGE_ID.edge: {
            return {
                comment:
                    lc.standardServices.glass_damage_title +
                    lc.standardServices.glass_damage_edge +
                    glassDamageInfo,
                infoTitle: lc.standardServices.glass_damage_info_title_large,
                infoDescription:
                    lc.standardServices.glass_damage_info_description_large,
            };
        }
        default: {
            return {
                comment:
                    lc.standardServices.glass_damage_title +
                    lc.standardServices.glass_damage_large +
                    glassDamageInfo,
                infoTitle: lc.standardServices.glass_damage_info_title_large,
                infoDescription:
                    lc.standardServices.glass_damage_info_description_large,
            };
        }
    }
}

import { CapacitorUpdater } from '@capgo/capacitor-updater';
import { datadogRum } from '@datadog/browser-rum';
import { useEffect, useState } from 'react';
import { getIsPendingRefreshToken } from 'src/utils/auth/refreshAccessToken';

let retrtyCount = 0;

const waitUntilRefreshTokenIsUpdated = async (bundleId: string) => {
    // Don't reload the app if we still don't have a token after 10 seconds. Update on next launch instead.
    if (retrtyCount > 10) {
        await CapacitorUpdater.next({ id: bundleId });
        return;
    }

    if (getIsPendingRefreshToken()) {
        setTimeout(() => {
            void waitUntilRefreshTokenIsUpdated(bundleId);
        }, 1000);
    } else {
        await CapacitorUpdater.set({ id: bundleId });
    }

    retrtyCount += 1;
};

const checkForUpdates = async (currentUrl: string) => {
    console.log('Checking for app bundle updates...');
    const isAutoUpdateEnabled = await CapacitorUpdater.isAutoUpdateEnabled();

    if (isAutoUpdateEnabled?.enabled !== true) {
        return;
    }

    const latestBundleInfo = await CapacitorUpdater.getLatest();
    const current = await CapacitorUpdater.current();

    if (
        latestBundleInfo?.url == null ||
        latestBundleInfo?.version == current?.bundle?.version
    ) {
        return;
    }

    const latestBundle = await CapacitorUpdater.download({
        url: latestBundleInfo.url,
        version: latestBundleInfo.version,
    });

    // Don't update the app bundle if the user is on the booking/damage/fastrack flow. Apply update on next reload instead.
    if (
        currentUrl.indexOf('booking') !== -1 ||
        currentUrl.indexOf('fasttrack') !== -1 ||
        currentUrl.indexOf('damage') !== -1
    ) {
        await CapacitorUpdater.next({ id: latestBundle.id });
    } else {
        await waitUntilRefreshTokenIsUpdated(latestBundle.id);
    }

    datadogRum.addAction('AppBundleUpdater', {
        version: latestBundle.version,
    });
};

// This component ensures that the app bundle is updated when the app is opened.
const AppBundleUpdater = () => {
    const [lastCheck, setLastCheck] = useState<number | null>(null);
    const updateCheckInterval = 1000 * 60 * 60;
    const currentUrl = window.location.pathname;

    useEffect(() => {
        if (lastCheck != null && Date.now() - lastCheck < updateCheckInterval) {
            return;
        }

        setLastCheck(Date.now());

        try {
            void checkForUpdates(currentUrl);
        } catch (error) {
            console.error(error);
            datadogRum.addError(error);
        }
    }, [lastCheck, updateCheckInterval, currentUrl]);

    return null;
};

export default AppBundleUpdater;

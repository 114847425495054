import {
    matchesRegex,
    maxLength,
    stripWhitespace,
    validation,
    ValidationStepResult,
} from '@moller/design-system/utilities/validation';
import {
    SERVICE_GROUP_ID,
    SERVICE_GROUP_IDS,
} from '../constants/serviceConstants';
import { useLanguageContext } from '../languages/languageContext';
import { IS_WAITING_AT_DEALER } from '../../sections/transport-services/TransportServicesEdit';
import { Services } from '../../_api/http/services';
import { ServiceViewModel } from 'external-apis/src/types/port';
import {
    SelectedService,
    StdServicesForm,
} from '../../sections/standard-services/useSectionForm';

function oneServiceChecked(
    list: string[],
    message: string
): ValidationStepResult {
    const services = list.filter((x) => !SERVICE_GROUP_IDS.includes(x));
    return {
        isValid: services.length > 0,
        errorMessage: message,
    };
}
export function useValidStandardServices(
    availableServices: Services | undefined,
    glassDamageLength: number
) {
    const [lc] = useLanguageContext();
    return validation<string[], StdServicesForm>({
        steps: [
            (list: string[]) =>
                oneServiceChecked(
                    list,
                    lc.errors.multichoice_services_validation
                ),
            (list: string[], allValues) => ({
                isValid: hasRequiredComment(
                    list,
                    allValues.serviceAdditionalInfo,
                    [
                        ...(availableServices?.Standard ?? []),
                        ...(availableServices?.Recommended ?? []),
                    ]
                ),
                errorMessage: lc.errors.other_service_comment_validation,
            }),
            (list: string[], allValues) => ({
                isValid: isGlassDamage(
                    allValues,
                    glassDamageLength,
                    availableServices?.Standard ?? []
                ),
                errorMessage: lc.errors.glass_service_validation,
            }),
        ],
    });
}

// TODO create additionalInfoType date and additionalInfoType short-comment?
function isGlassDamage(
    allValues: StdServicesForm,
    maxLength: number,
    allAvailableServices: ServiceViewModel[]
) {
    const glassDamageIsSelected = allValues.selectedIds.some(
        (selectedId) =>
            allAvailableServices.find((x) => x.id === selectedId)?.category ===
            'GlassDamage'
    );

    if (glassDamageIsSelected) {
        return (
            VALID_DATE.test(allValues.glassDamageWhen) &&
            allValues.glassDamageWhere.length > 0 &&
            allValues.glassDamageWhere.length < maxLength
        );
    }
    return true;
}

export function useValidServiceAdditionalInfo(
    availableServices: Services | undefined
) {
    const [lc] = useLanguageContext();
    return validation<SelectedService[], StdServicesForm>({
        steps: [
            (list: SelectedService[], allValues) => {
                return {
                    isValid: hasRequiredComment(allValues.selectedIds, list, [
                        ...(availableServices?.Standard ?? []),
                        ...(availableServices?.Recommended ?? []),
                        ...(availableServices?.Delivery ?? []),
                        ...(availableServices?.Transport ?? []),
                        ...(availableServices?.Additional ?? []),
                    ]),
                    errorMessage:
                        lc.errors.other_service_comment_validation_text_field,
                };
            },
        ],
    });
}

function hasRequiredComment(
    selectedServices: string[],
    serviceAdditionalInfos: SelectedService[],
    availableServices: ServiceViewModel[]
) {
    return selectedServices.every((selectedServiceId) => {
        const serviceModel = availableServices.find(
            (x) => x.id === selectedServiceId
        );
        const commentRequired = serviceModel?.additionalInfo?.some(
            (additionalInfo) =>
                additionalInfo.isRequired &&
                additionalInfo.infoType === 'Comment'
        );
        if (commentRequired) {
            const comment = serviceAdditionalInfos.find(
                (x) => x.serviceId === selectedServiceId
            )?.comment;
            return comment && comment.length > 0;
        }
        return true;
    });
}

// If 'I can wait at the workshop' option is checked, no other option can be checked at the same time
function waitAtWorkshopChecked(
    list: string[],
    message: string
): ValidationStepResult {
    const isValid = !(list.includes(IS_WAITING_AT_DEALER) && list.length > 1);
    return {
        isValid,
        errorMessage: message,
    };
}

export function getTransportServicesValidator(message: string) {
    return validation({
        steps: [(list: string[]) => waitAtWorkshopChecked(list, message)],
    });
}

const VALID_DIGITS = /^[0-9]+$/;
export function useValidMileage() {
    const [lc] = useLanguageContext();
    return validation<string, StdServicesForm>({
        steps: [
            matchesRegex(VALID_DIGITS, lc.errors.mileage_validation_number),
            maxLength(9),
        ],
        preValidation: (mileage) => stripWhitespace(mileage),
        necessity: (values) => ({
            isRequired: values.selectedIds.includes(
                SERVICE_GROUP_ID.recommendedGroup
            ),
            missingMessage: lc.errors.mileage_validation_required,
        }),
        showMessageImmediately: (mileage) => mileage.length > 0,
    });
}

const minLength =
    (length: number) =>
    (value: string): ValidationStepResult => ({
        isValid: value.length >= length,
        errorMessage: '',
    });

export function useValidLength(
    length: number,
    availableServices: Services | undefined
) {
    const [lc] = useLanguageContext();
    return validation<string, StdServicesForm>({
        steps: [maxLength(length), minLength(1)],
        necessity: (values) => ({
            isRequired: values.selectedIds.some(
                (selectedId) =>
                    availableServices?.Standard.find((x) => x.id === selectedId)
                        ?.category === 'GlassDamage'
            ),
            missingMessage: lc.errors.glass_service_location_validation,
        }),
    });
}

const VALID_DATE = /^(\d{2}\.){2}\d{4}/;

export function useValidDate(availableServices: Services | undefined) {
    const [lc] = useLanguageContext();

    return validation<string, StdServicesForm>({
        steps: [
            matchesRegex(VALID_DATE, lc.errors.glass_service_date_validation),
        ],
        necessity: (values) => {
            return {
                isRequired: values.selectedIds.some(
                    (selectedId) =>
                        availableServices?.Standard.find(
                            (x) => x.id === selectedId
                        )?.category === 'GlassDamage'
                ),
                missingMessage: lc.errors.glass_service_date_validation,
            };
        },
    });
}

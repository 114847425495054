import { LogoBrand } from '@moller/design-system/foundation/logos/Logo.js';
import { useEffect, useMemo } from 'react';
import {
    AnimatedAutoriaLogo,
    AnimatedBilholdLogo,
    AnimatedDahlesLogo,
    AnimatedGumpenLogo,
    AnimatedMollerLogo,
    AnimationContainer,
} from 'src/features/home/transition/transition';
import SkodaTransition from 'src/features/my-car/car-brands/skoda/SkodaTransition';
import { AnimatedLogo } from 'src/features/my-car/components/AnimatedLogo';
import { PATH_BEGIN, PATH_HOME } from 'src/routing/routes';
import { themeOverride } from 'src/utils/dev-tools/devToolsUtils';
import { ChildThemeProvider, useThemeContext } from 'theming';

const getAnimatedTransition = (dealerBrand: LogoBrand) => {
    switch (dealerBrand) {
        case 'mollerbil':
            return <AnimatedMollerLogo />;
        case 'gumpen':
            return <AnimatedGumpenLogo />;
        case 'dahles':
            return <AnimatedDahlesLogo />;
        case 'autoria':
            return <AnimatedAutoriaLogo />;
        case 'vw':
            return <AnimatedLogo brand="vw" />;
        case 'skoda':
            return <SkodaTransition />;
        case 'audi':
            return <AnimatedLogo brand="audi" />;
        case 'cupra':
            return <AnimatedLogo brand="cupra" />;

        default:
            return <AnimatedBilholdLogo />;
    }
};
const SplashScreen = () => {
    const { theme } = useThemeContext();

    const shouldRender = useMemo(() => {
        if (
            !(
                window.location.pathname === '/' ||
                window.location.pathname === PATH_BEGIN ||
                window.location.pathname === PATH_HOME
            )
        ) {
            return false;
        }
        const currentTimeStamp = new Date().getTime();
        const prevTimeStamp = localStorage.getItem('prevTimeStamp');

        if (prevTimeStamp) {
            const timeDiff =
                (currentTimeStamp - parseInt(prevTimeStamp, 10)) / 1000;
            if (timeDiff < 30) {
                return false;
            }
        }

        localStorage.setItem('prevTimeStamp', currentTimeStamp.toString());
        return true;
    }, []);

    useEffect(() => {
        const onBeforeUnload = () => {
            localStorage.setItem(
                'prevTimeStamp',
                new Date().getTime().toString()
            );
        };
        window.addEventListener('beforeunload', onBeforeUnload);
    }, []);

    if (!shouldRender) {
        return null;
    }

    const transition = getAnimatedTransition(theme);

    return (
        <ChildThemeProvider theme={theme} overrideTheme={themeOverride}>
            <AnimationContainer id="animation-container">
                {transition}
            </AnimationContainer>
        </ChildThemeProvider>
    );
};

export default SplashScreen;

import { CarsResponse } from 'src/features/home/queries/useCarProfile';
import { CarStatus, MockData } from '../data';

const getMyCarsProfile = (mockData: MockData): CarsResponse => {
    return {
        existingCars: mockData.cars
            .filter((car) => car.CarStatus === CarStatus.EXISTING)
            .map((car) => ({
                vin: car.vin,
                licensePlate: car.licensePlate,
                name: car.model,
                brand: car.brand,
                mileage: 123456,
                registrationDate: '2014-06-19',
            })),
        pendingCars: [],
        orderedCars: [],
    };
};

export default getMyCarsProfile;

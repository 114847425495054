import { isAnnataAdapter } from 'booking/_api/adapterUtils';
import { portBaseUrl } from 'external-apis';
import getCurrentEnvironment from '../../environment/getCurrentEnvironment';

/**
 * Resolve the given path relative to current environment's serverUrl. Explicitly, given 'https://<domain>/bilhold' as serverUrl:
 *   - relative paths are resolved from '<domain>/bilhold/'. E.g path 'public/api' --> '<domain>/bilhold/public/api
 *   - root-relative paths are resolved from '<domain>/'. E.g. path '/bilhold/public/api' --> '<domain>/bilhold/public/api
 *   - absolute paths are unchanged. E.g path 'https://www.example.com/public/api' is unchanged.
 * @param path A relative, root-relative or absolute path
 * @param adapterId In case we send the adapterId, path is to workshop-port
 * @param enabledAnnataInvoice Feature flag to show if invoice from annata will be used
 * @returns an absolute URL
 */
export function toServerUrl(
    path: string,
    adapterId?: string,
    enabledAnnataInvoice?: boolean
) {
    const env = getCurrentEnvironment();

    if (enabledAnnataInvoice && isAnnataAdapter(adapterId)) {
        const bookingEnv = env.name === 'staging' ? 'stage' : env.name;
        const portUrl = portBaseUrl(
            bookingEnv,
            import.meta.env.VITE_BOOKING_API_URL_BOOKING
        );
        return new URL(`${path}&adapterId=${adapterId}`, portUrl).href;
    }
    // Use URL constructor to join path onto base server url.
    const baseUrl = new URL(env.serverUrl).href.endsWith('/')
        ? env.serverUrl
        : `${env.serverUrl}/`;
    return new URL(path, baseUrl).href;
}

import {
    AlertBanner,
    AlertBannerProps,
    AllowedIcons,
    Column,
    IconButton,
    Logo,
    Row,
    breakpointTablet,
} from '@moller/design-system';
import { Menu, MenuItem } from '@mui/material';
import { useToggle } from 'feature-toggle';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    PATH_EXPLORE,
    PATH_HOME,
    PATH_PROFILE_OVERVIEW,
} from 'src/routing/routes';
import MessageBadge from 'src/shared/message-badge/MessageBadge';
import { ddRumPrivacySetting } from 'src/utils/datadog';
import { useMessageThreads } from 'src/utils/hooks/useMessageThreads';
import lang from 'src/utils/lang';
import { isMobileApp } from 'src/utils/mobile';
import { css, styled } from 'styled-components';
import { useThemeContext } from 'theming';
import {
    getNumberOfUnOpenedMessages,
    getUrlToUnreadMessages,
} from '../contact/utils/messagesUtils';
import { HeaderOptions } from './HeaderOptions';

const HeaderContent = styled(Row)`
    max-width: 800px;
    min-height: 64px; /* To avoid layout shifts from differing logo heights when changing themes */
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    height: fit-content;
    padding: var(--moller-spacing-xs) var(--moller-spacing-s)
        var(--moller-spacing-xs) var(--moller-spacing-s);
    gap: var(--moller-spacing-xs);
`;

const MenuContainer = styled(Row)`
    gap: var(--moller-spacing-s);
    @media screen and (max-width: ${breakpointTablet}) {
        display: none;
    }
`;

const MessageIconContainer = styled.div`
    width: fit-content;
    position: relative;
`;

const AlertBannerContainer = styled.div`
    width: 100%;
    max-width: 800px;
    padding: 0 12px;
    box-sizing: border-box;
    margin: 0 auto;
`;

const StyledLogo = styled(Logo)<{ brand: string }>`
    margin-top: ${({ brand }) =>
        brand === 'audi' ? 'var(--moller-spacing-xxs)' : '0'};

    ${({ brand }) => {
        if (brand === 'vw' && isMobileApp()) {
            return css`
                position: relative;
                z-index: 10;
                width: 100vw;

                svg {
                    background-color: var(--moller-color-background);
                    color: var(--moller-color-primary);
                }

                // Blue line before and after logo
                &::before,
                &::after {
                    content: '';
                    position: block;
                    top: 50%;
                    width: 100%;
                    height: 1px;
                    background-color: var(--moller-color-primary);
                    z-index: -1;
                }
            `;
        }
    }}
`;

const MobileDesktopRow = styled(Row)`
    display: none;
    @media screen and (max-width: ${breakpointTablet}) {
        display: flex;
    }
`;

const HeaderNew = () => {
    const { theme: brand } = useThemeContext();
    const navigate = useNavigate();
    const { data: messages } = useMessageThreads();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [toggleInformationMessage, setToggleInformationMessage] =
        useState(true);

    type InfoMessage = {
        type: AllowedIcons;
        message: string;
    };

    const showInfoMessage = useToggle<InfoMessage>('ShowInfoMessage');

    const numberOfUnreadMessages = getNumberOfUnOpenedMessages(messages);
    const urlToUnreadMessages = getUrlToUnreadMessages(messages);

    const HeaderMenuItem: {
        icon: AllowedIcons;
        label: string;
        path: string;
        show: boolean;
        unread?: number;
    }[] = [
        {
            icon: 'home',
            label: lang.frontpage,
            path: PATH_HOME,
            show: true,
        },
        {
            icon: 'chat',
            label: lang.messages,
            path: urlToUnreadMessages,
            show: true,
            unread: numberOfUnreadMessages,
        },
        {
            icon: 'explore',
            label: lang.explore,
            path: PATH_EXPLORE,
            show: true,
        },
        {
            icon: 'settings',
            label: lang.settings,
            path: PATH_PROFILE_OVERVIEW,
            show: true,
        },
    ];

    return (
        <Column {...ddRumPrivacySetting('allow')}>
            <HeaderContent
                verticalAlign="center"
                horizontalAlign={isMobileApp() ? 'center' : 'spaceBetween'}
            >
                {location.pathname.includes(PATH_HOME) ? (
                    <div>
                        <Row gap={'s'}>
                            <StyledLogo
                                brand={brand}
                                type="wordmark"
                                size={brand === 'vw' ? 'xxl' : 'm'}
                            />
                        </Row>
                    </div>
                ) : (
                    <div></div>
                )}
                {!isMobileApp() && (
                    <>
                        <MobileDesktopRow gap={'s'}>
                            <MessageIconContainer>
                                <IconButton
                                    id="long-button"
                                    icon="menu"
                                    label="menu"
                                    aria-controls={
                                        open ? 'long-menu' : undefined
                                    }
                                    aria-expanded={open ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={handleMenuClick}
                                />
                                <MessageBadge unread={numberOfUnreadMessages} />
                            </MessageIconContainer>
                            <Menu
                                id="long-menu"
                                open={open}
                                anchorEl={anchorEl}
                                MenuListProps={{
                                    'aria-labelledby': 'long-button',
                                }}
                                onClose={handleClose}
                            >
                                {HeaderMenuItem.map(
                                    (item) =>
                                        item.show && (
                                            <MenuItem key={item.label}>
                                                <MessageIconContainer>
                                                    <IconButton
                                                        icon={item.icon}
                                                        label={item.label}
                                                        showLabel="right"
                                                        onClick={() => {
                                                            navigate(item.path);
                                                            handleClose();
                                                        }}
                                                    />
                                                    <MessageBadge
                                                        unread={
                                                            item.unread ?? 0
                                                        }
                                                    />
                                                </MessageIconContainer>
                                            </MenuItem>
                                        )
                                )}
                            </Menu>
                        </MobileDesktopRow>
                        <MenuContainer>
                            <HeaderOptions />
                        </MenuContainer>
                    </>
                )}
            </HeaderContent>
            {toggleInformationMessage &&
                showInfoMessage?.variation.value.message &&
                showInfoMessage?.variation.value.type && (
                    <AlertBannerContainer>
                        <AlertBanner
                            type={
                                showInfoMessage?.variation.value
                                    .type as AlertBannerProps['type']
                            }
                            message={showInfoMessage?.variation.value.message}
                            dismiss={() => setToggleInformationMessage(false)}
                        />
                    </AlertBannerContainer>
                )}
        </Column>
    );
};

export default HeaderNew;

import { useParams } from 'react-router-dom';
import { CenteredPageContent } from 'src/shared/CenteredPageContent';
import useFeatureToggle from 'src/utils/dev-tools/useFeatureToggle';
import { AddCar as AddCar_Old } from './AddCar';
import { AddCar } from './AddCar_New';

const Index = () => {
    const params = useParams();
    const UseNewRequestEndpoint = useFeatureToggle('UseNewRequestEndpoint');

    return (
        <CenteredPageContent showBackButton>
            {!UseNewRequestEndpoint ? (
                <AddCar_Old licensePlate={params.licensePlate} />
            ) : (
                <AddCar />
            )}
        </CenteredPageContent>
    );
};

export default Index;

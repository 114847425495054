import { datadogRum } from '@datadog/browser-rum';
import { ActionChip, Headline, Row } from '@moller/design-system';
import { useEffect, useState } from 'react';
import { CenteredPageContent } from 'src/shared/CenteredPageContent';
import FeedbackPrompt from 'src/shared/feedback-card/FeedbackPrompt';
import { ddRumPrivacySetting } from 'src/utils/datadog';
import { themeOverride } from 'src/utils/dev-tools/devToolsUtils';
import { useMessageThreads } from 'src/utils/hooks/useMessageThreads';
import lang from 'src/utils/lang';
import { styled } from 'styled-components';
import { ChildThemeProvider } from 'theming';
import ContactInfoList from './ContactInfoList';
import { MessagesChat } from './MessagesChat';
import { MessagesDamageAppraisal } from './MessagesDamageAppraisal';
import { messageIsSeen } from './utils/messagesUtils';

const MessageHeadline = styled(Headline)`
    margin: var(--moller-spacing-m) 0;
`;

const StyledRow = styled(Row)`
    margin-bottom: var(--moller-spacing-s);
`;

const Index = () => {
    const { data: messageThreads, isLoading, isError } = useMessageThreads();
    const [selectedCategory, setSelectedCategory] = useState<
        'contactList' | 'messageList' | 'chatList'
    >('contactList');

    // This makes sure the correct category is active when a user comes back from a message thread.
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const category = urlParams.get('category');
        if (category === 'damage_appraiser') {
            setSelectedCategory('messageList');
        } else if (category === 'chat') {
            setSelectedCategory('chatList');
        }
    }, []);

    const getNumberOfUnseenMessages = (
        category: 'DAMAGE_APPRAISER' | 'CHAT'
    ) => {
        const filteredMessageThreads = messageThreads?.filter(
            (thread) => thread.category === category
        );

        return filteredMessageThreads?.reduce(
            (acc, thread) => (messageIsSeen(thread) ? acc : acc + 1),
            0
        );
    };

    useEffect(() => {
        datadogRum.addAction('selectedContactCategory', {
            contactCategory: selectedCategory,
        });
    }, [selectedCategory]);

    const chatMessages = messageThreads?.filter((x) => x.category === 'CHAT');
    const damageMessages = messageThreads?.filter(
        (x) => x.category === 'DAMAGE_APPRAISER'
    );

    return (
        <ChildThemeProvider theme="bilhold" overrideTheme={themeOverride}>
            <CenteredPageContent {...ddRumPrivacySetting('allow')}>
                <MessageHeadline>{lang.myMessages}</MessageHeadline>
                <StyledRow gap="xxs">
                    <ActionChip
                        selected={selectedCategory === 'contactList'}
                        onClick={() => setSelectedCategory('contactList')}
                        label="Kontaktliste"
                    />
                    <ActionChip
                        selected={selectedCategory === 'messageList'}
                        onClick={() => setSelectedCategory('messageList')}
                        icon={
                            getNumberOfUnseenMessages('DAMAGE_APPRAISER')
                                ? 'mail'
                                : undefined
                        }
                        label="Skadesaker"
                    />
                    {chatMessages && chatMessages?.length > 0 && (
                        <ActionChip
                            selected={selectedCategory === 'chatList'}
                            onClick={() => setSelectedCategory('chatList')}
                            icon={
                                getNumberOfUnseenMessages('CHAT')
                                    ? 'mail'
                                    : undefined
                            }
                            label="Meldinger"
                        />
                    )}
                </StyledRow>
                {(() => {
                    if (selectedCategory === 'contactList') {
                        return <ContactInfoList />;
                    } else if (selectedCategory === 'messageList') {
                        return (
                            <MessagesDamageAppraisal
                                messageThreads={damageMessages}
                                hasError={isError}
                                isLoading={isLoading}
                            />
                        );
                    } else if (selectedCategory === 'chatList') {
                        return (
                            <MessagesChat
                                messageThreads={chatMessages}
                                hasError={isError}
                            />
                        );
                    }
                })()}

                <FeedbackPrompt />
            </CenteredPageContent>
        </ChildThemeProvider>
    );
};

export default Index;

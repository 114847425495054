import { atoms } from '@moller/gnist-themes/atoms.css.js';
import { CurrencyType } from 'external-apis/src/types/port';
import React from 'react';
import { styled } from 'styled-components';
import {
    LanguageContextType,
    useLanguageContext,
} from '../../../lib/languages/languageContext';
import { IS_WAITING_AT_DEALER } from '../../transport-services/TransportServicesEdit';
import { isRecommendedGroup, ServiceOrGroup } from '../groupServices';
import { calculatePrice } from './util';

interface ServicePriceProps {
    service: ServiceOrGroup;
    selectedServiceIds?: string[];
}

type Price<T> = {
    type: T;
};

export type PriceUnknown = Price<'PRICE_UNKNOWN'>;

export type PriceFrom = Price<'PRICE_FROM'> & {
    price: number;
    currency: CurrencyType;
};

export type PriceFixed = Price<'PRICE_FIXED'> & {
    price: number;
    currency: CurrencyType;
};

export type ServicePriceType = PriceUnknown | PriceFrom | PriceFixed;

const SubServicePriceText = styled.div`
    text-align: right;
    white-space: nowrap;
`;

export const SubServicePrice: React.FC<ServicePriceProps> = ({
    service,
    selectedServiceIds,
}) => {
    const [lc] = useLanguageContext();
    const price = calculatePrice(service, selectedServiceIds);
    const priceText = getServicePriceText(price, service, lc);

    return (
        <SubServicePriceText
            className={atoms({ typography: 'description-small' })}
        >
            {priceText}
        </SubServicePriceText>
    );
};

export const ServicePrice: React.FC<ServicePriceProps> = ({
    service,
    selectedServiceIds,
}) => {
    const [lc] = useLanguageContext();
    const price = calculatePrice(service, selectedServiceIds);
    const priceText = getServicePriceText(price, service, lc);

    return <div>{priceText}</div>;
};

function getServicePriceText(
    servicePrice: ServicePriceType,
    service: ServiceOrGroup,
    lc: LanguageContextType
) {
    if (servicePrice.type === 'PRICE_UNKNOWN') {
        // Recommended group
        if (service.type === 'group' && isRecommendedGroup(service.group)) {
            return `${lc.prices.price_calculated}`;
        }
        if (
            service.type === 'service' &&
            service.service.id === IS_WAITING_AT_DEALER
        ) {
            return null;
        }
        return `${lc.prices.price_calculated_at_dealer}`;
    }
    if (servicePrice.price === 0) {
        return `${lc.prices.free}`;
    }
    const roundedPrice = Math.ceil(servicePrice.price);
    if (servicePrice.type === 'PRICE_FIXED') {
        if (service.type === 'group' && isRecommendedGroup(service.group)) {
            return `${lc.prices.sum}${roundedPrice} ${lc.prices.currency}`;
        }
        return `${roundedPrice} ${lc.prices.currency}`;
    }
    if (servicePrice.type === 'PRICE_FROM') {
        return `${lc.prices.from} ${roundedPrice} ${lc.prices.currency}`;
    }
}

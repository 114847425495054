import Dealer from 'src/types/Dealer';

interface DealerSorter {
    readonly name: string;
    readonly sortFunction: (a: Dealer, b: Dealer) => number;
}
export const dealerSorters: DealerSorter[] = [
    {
        name: 'position',
        sortFunction: (a, b) => {
            if (
                a.distanceFromClient !== undefined &&
                b.distanceFromClient !== undefined
            ) {
                return a.distanceFromClient - b.distanceFromClient;
            } else {
                return 0;
            }
        },
    },
    {
        name: 'aToAA',
        sortFunction: (a, b) => a.name.localeCompare(b.name),
    },
];

import { SimpleCoordinates } from 'src/types/SimpleCoordinates';

const toRadians = (degrees: number) => degrees * (Math.PI / 180);

const haversineDistance = (
    position1: SimpleCoordinates,
    position2: SimpleCoordinates
) => {
    const earthRadius = 6371; // Radius of the earth in kilometers
    const { latitude: lat1, longitude: lon1 } = position1;
    const { latitude: lat2, longitude: lon2 } = position2;

    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRadians(lat1)) *
            Math.cos(toRadians(lat2)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return Math.ceil(earthRadius * c); // Distance in kilometers
};

export const getKilometersBetween = (
    position1?: SimpleCoordinates,
    position2?: SimpleCoordinates
) => {
    if (position1 && position2) {
        return haversineDistance(position1, position2);
    }

    return undefined;
};

import { datadogRum } from '@datadog/browser-rum';
import {
    Column,
    RadioButton,
    Subtitle,
    useLocalFormState,
} from '@moller/design-system';
import {
    required,
    validRegistrationNumber,
} from '@moller/design-system/utilities/validation';
import { useRef } from 'react';
import Car from 'src/types/Car';
import { ddRumPrivacySetting } from 'src/utils/datadog';
import lang from 'src/utils/lang';
import DamageAppraisal from '../utils/DamageAppraisal';
import { useUpdateDamageAppraisal } from '../utils/useDamageAppraisals';
import SearchOtherCarView from './SearchOtherCarView';

interface DamageCarProps {
    setCar: (car: Car) => void;
    cars?: Car[];
    damage: DamageAppraisal;
}

const DamageCar = ({ setCar, cars, damage }: DamageCarProps) => {
    const { mutate: updateDamage } = useUpdateDamageAppraisal(damage.id);
    const datadogSent = useRef(false);
    const selectedVehicleIsOtherCar = !cars?.some((c) => c.vin === damage.vin);
    const form = useLocalFormState(
        { licensePlate: selectedVehicleIsOtherCar ? damage.licensePlate : '' },
        { licensePlate: required(validRegistrationNumber) }
    );
    const handleCarChange = (car: Car) => {
        updateDamage({
            vin: car?.vin,
            licensePlate: car?.licensePlate,
            title: 'Karosseri',
        });
        setCar(car);
        // Blank out "other car" field when selecting a car from the list
        form.setValue('licensePlate')('');
    };
    if (!datadogSent.current) {
        datadogRum.addAction('Search car - damage');
        datadogSent.current = true;
    }
    return (
        <Column gap={'m'} {...ddRumPrivacySetting('mask')}>
            <Subtitle>{lang.damageCarsTitle}</Subtitle>
            <Column gap={'s'}>
                {cars &&
                    cars.map((c) => (
                        <RadioButton
                            key={c.vin}
                            label={`${c.name}: ${c.licensePlate}`}
                            value={damage?.vin === c.vin}
                            onChange={() => {
                                handleCarChange(c);
                            }}
                        />
                    ))}
            </Column>
            <SearchOtherCarView
                setCar={setCar}
                damageId={damage.id}
                form={form}
            />
        </Column>
    );
};

export default DamageCar;

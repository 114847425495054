import { Column, RadioButton } from '@moller/design-system';
import { ServiceViewModel } from 'external-apis/src/types/port';
import { useEffect } from 'react';
import { FlexRow } from '../../../components/FlexRow';
import { SubServicePrice } from '../price/ServicePrice';
import { useStandardServicesForm } from '../useSectionForm';
import { IndentedDescription } from './components/IndentedDescription';

type TireServiceProps = {
    services: ServiceViewModel[];
    servicesForm: ReturnType<typeof useStandardServicesForm>;
};

export function TireService(props: TireServiceProps) {
    const selectedIds = props.servicesForm.state.raw.selectedIds;
    const servicesIds = props.services.map((x) => x.id);

    const setSelectedIds = props.servicesForm.setValue('selectedIds');
    const someIdSelected = selectedIds.some((id) => servicesIds.includes(id));

    /*
     * Select the first tire service by default
     * - condition: check if there exist a selected id.
     *   (selectedValues and setValue should always be true)
     * - if no selected id. Select the first id.
     * */
    useEffect(() => {
        const condition = !someIdSelected && selectedIds && setSelectedIds;
        if (condition) {
            setSelectedIds([
                ...selectedIds.filter((id) => !servicesIds.includes(id)),
                servicesIds[0],
            ]);
        }
    }, [selectedIds, servicesIds, setSelectedIds, someIdSelected]);

    if (!selectedIds || !setSelectedIds) {
        return null;
    }

    return (
        <Column gap="xxs">
            {props.services.map((service) => (
                <div key={service.id}>
                    <FlexRow>
                        <RadioButton
                            id={service.id}
                            label={service.name}
                            onChange={() => {
                                setSelectedIds([
                                    ...selectedIds.filter(
                                        (id) => !servicesIds.includes(id)
                                    ),
                                    service.id,
                                ]);
                            }}
                            value={selectedIds.some((id) => id === service.id)}
                        />
                        <SubServicePrice
                            service={{ type: 'service', service: service }}
                        />
                    </FlexRow>
                    <IndentedDescription>
                        {service.description}
                    </IndentedDescription>
                </div>
            ))}
        </Column>
    );
}

import { useMutation, useQueryClient } from '@tanstack/react-query';
import httpDelete from 'src/utils/http/httpDelete';

export const useDeleteDamageAppraiser = (
    setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (id: number) =>
            httpDelete(`api/v3/damageAppraiser/id/${id}`),
        onSuccess: () => {
            void queryClient.invalidateQueries({
                queryKey: ['damageAppraiser'],
            });
            setModalIsOpen(false);
        },
    });
};

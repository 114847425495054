import { useQuery } from '@tanstack/react-query';
import { CarServiceDeal } from '../../my-car/types/CarServiceDeal';
import proxyGet from 'src/utils/data-mocks/proxyGet';

export const useCarServiceDeals = (vin: string | undefined) => {
    const { data, isLoading } = useQuery({
        queryKey: ['carServiceDeals', vin],
        queryFn: () =>
            proxyGet<CarServiceDeal[]>(`api/v3/carServices/${vin}`).then(
                (res) => res.data
            ),
        enabled: !!vin,
        staleTime: 1000 * 60 * 60,
    });
    return { deals: data, isLoadingDeal: isLoading };
};

export const useCarMobilityGuarantee = (
    deals: CarServiceDeal[] | undefined
) => {
    const mobility = deals?.find(
        ({ serviceId }) => serviceId === 'mobilityGuarantee'
    );

    const status =
        mobility?.status === 'ACTIVE'
            ? 'Gyldig'
            : mobility?.status === 'INACTIVE'
            ? 'Utløpt'
            : '---';

    const lastUpdatedMilage = calculateDays(mobility?.lastUpdatedMileage);
    return { status: status, lastUpdatedMilage: lastUpdatedMilage };
};

const calculateDays = (date?: string) => {
    if (!date) return '';
    const now = new Date();
    const then = new Date(date);
    const difference = now.getTime() - then.getTime();
    const days = Math.ceil(difference / (1000 * 3600 * 24));
    return days;
};

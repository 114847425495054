import {
    Column,
    Headline,
    LoadingSpinner,
    RadioButton,
    TextContainer,
    TextField,
} from '@moller/design-system';
import { useCallback, useEffect, useState } from 'react';
import CarRelationContent from 'src/features/add-car/components/car-relation-content/CarRelationContent';
import searchCarState from 'src/features/add-car/utils/searchCarState';
import { ddRumPrivacySetting } from 'src/utils/datadog';
import CarRelation from 'src/utils/enums/CarRelation';
import lang, { TextKey } from 'src/utils/lang';
import formatCarName from 'src/utils/string/formatCarName';
import { REG_NO_FULL_LENGTH } from 'src/utils/string/regex';
import { styled } from 'styled-components';
import { ActionBar } from '../home/components/action/ActionBar';
import {
    AddCarSearchResponse,
    getSearchCar,
} from '../home/queries/useAddCarFunctions';
import isRegNoValid from './utils/isRegNoValid';

const RegNrInput = styled(TextField)`
    width: 100%;
    max-width: 400px;
`;

interface AddCarProps {
    readonly licensePlate?: string;
}

export const AddCar = ({ licensePlate }: AddCarProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [registrationNumber, setRegistrationNumber] = useState(
        licensePlate ?? ''
    );
    const [carRelation, setCarRelation] = useState<CarRelation>();
    const [searchCarData, setSearchCarData] = useState<AddCarSearchResponse>();
    const [searchCarError, setSearchCarError] = useState('');
    const options = [
        { id: CarRelation.OWNER, label: 'iOwnCar' },
        { id: CarRelation.USER, label: 'iAmCarUser' },
        { id: CarRelation.LEASER, label: 'leasingCar' },
        { id: CarRelation.COMPANY, label: 'companyCar' },
    ];

    const onChangeRegNo = useCallback((value: string) => {
        setSearchCarError('');
        setSearchCarData(undefined);
        setCarRelation(undefined);
        const input = value.toUpperCase()?.replace(/\s/g, '');
        setRegistrationNumber(input);
        if (input.length > REG_NO_FULL_LENGTH)
            setSearchCarError('addCarSearchFormatError');
        if (isRegNoValid(input)) {
            setIsLoading(true);
            getSearchCar(input, setSearchCarData, setSearchCarError)
                .then(() => setIsLoading(false))
                .catch(() => setIsLoading(false));
        }
    }, []);

    useEffect(() => {
        if (licensePlate) {
            onChangeRegNo(licensePlate);
        }
    }, [licensePlate, onChangeRegNo]);

    return (
        <>
            <Column gap="s" {...ddRumPrivacySetting('allow')}>
                <>
                    <Headline>Legg til bil</Headline>
                    <RegNrInput
                        {...ddRumPrivacySetting('mask-user-input')}
                        onChange={(e) => onChangeRegNo(e.target.value)}
                        label={lang.addCarInputLabel}
                        value={registrationNumber}
                        autoFocus
                        validity={{
                            type: searchCarState(
                                searchCarError,
                                isRegNoValid(registrationNumber) &&
                                    searchCarData != undefined
                            ),
                            message: searchCarError
                                ? lang[searchCarError as TextKey]
                                : searchCarData
                                ? formatCarName(
                                      searchCarData.brand,
                                      searchCarData.carModel
                                  )
                                : '',
                        }}
                    ></RegNrInput>
                    {!searchCarError &&
                        (isLoading ? (
                            <LoadingSpinner />
                        ) : (
                            searchCarData && (
                                <>
                                    <TextContainer>
                                        <Headline $as="h3">
                                            {lang.carRelationship}
                                        </Headline>
                                    </TextContainer>
                                    <Column gap="s">
                                        {options.map((option) => (
                                            <RadioButton
                                                onChange={() =>
                                                    setCarRelation(option.id)
                                                }
                                                value={
                                                    carRelation === option.id
                                                }
                                                name={option.id}
                                                key={option.id}
                                                label={
                                                    lang[
                                                        option.label as TextKey
                                                    ]
                                                }
                                            />
                                        ))}
                                    </Column>
                                </>
                            )
                        ))}
                </>
                {searchCarData ? (
                    <CarRelationContent
                        registrationNumber={registrationNumber}
                        carRelation={carRelation}
                        searchCarData={searchCarData}
                    />
                ) : (
                    <ActionBar />
                )}
            </Column>
        </>
    );
};

import {
    CheckboxCardGroup,
    Column,
    Description,
    FormProvider,
} from '@moller/design-system';
import { QueryObserverSuccessResult } from '@tanstack/react-query';
import { ServiceViewModel } from 'external-apis/src/types/port';
import { useFlag } from 'feature-toggle';
import { isAnnataAdapter } from '../../_api/adapterUtils';
import { Services } from '../../_api/http/services';
import { BilholdNextButton } from '../../components/bilhold/BilholdNextButton';
import { BilholdInnerViewLayout } from '../../components/bilhold/BilholdView';
import { ExpandableServiceDescription } from '../../components/services/ExpandableServiceDescription';
import { SERVICE_GROUP_IDS } from '../../lib/constants/serviceConstants';
import {
    LanguageContextType,
    useLanguageContext,
} from '../../lib/languages/languageContext';
import { SectionState } from '../sectionUtils';
import { CommentField } from './CommentField';
import {
    ServiceOrGroup,
    isRecommendedGroup,
    isTireGroup,
    preProcessServices,
} from './groupServices';
import { GlassService, getGlassDamageComment } from './groups/GlassService';
import {
    ServiceGroup,
    ServiceGroupComponent,
    ServiceGroupProps,
} from './groups/ServiceGroup';
import { ServicePrice } from './price/ServicePrice';
import {
    SelectedService,
    StdServicesForm,
    useStandardServicesForm,
} from './useSectionForm';
import { StandardServicesData } from './useStandardServices';

type StandardServicesEditProps = {
    servicesForm: ReturnType<typeof useStandardServicesForm>;
    services: ServiceViewModel[];
    setSectionState: (x: SectionState<StandardServicesData>) => void;
    vin: string;
    dealerNumbers: string[];
    servicesResponse: QueryObserverSuccessResult<Services>;
    mileage: string;
    setMileage: (x: string) => void;
    setIsBookingRequest: (x: boolean) => void;
};

function getServiceGroupDescription(
    group: ServiceGroup,
    lc: LanguageContextType
) {
    // TODO: This text should ideally be in the service catalog? But service groups don't have description
    //       there, as it's not really a concept. It doesn't make much sense to have this in a translation file,
    //       when the sub-choice descriptions come from the service catalog and won't match the chosen language.
    if (isRecommendedGroup(group)) {
        return (
            'Bilen kan varsle om service eller det kan være behov for oljeskift, inspeksjon o.l.<br>' +
            lc.standardServices.mileage_description
        );
    }
    if (isTireGroup(group)) {
        return 'Vi hjelper deg med å bytte dekk, og kan oppbevare hjulene dine på dekkhotell.';
    }
}

function renderServiceComponents(
    item: ServiceOrGroup,
    props: Omit<ServiceGroupProps, 'group'>
) {
    if (item.type === 'group') {
        return (
            <ServiceGroupComponent
                vin={props.vin}
                dealerNumbers={props.dealerNumbers}
                servicesForm={props.servicesForm}
                group={item.group}
                servicesResponse={props.servicesResponse}
                setMileage={props.setMileage}
                mileage={props.mileage}
            />
        );
    } else {
        const servicesForm = props.servicesForm;
        const service = item.service;
        const selectedValues = servicesForm.state.raw.selectedIds;
        const selected = selectedValues.some(
            (selectedId) => selectedId === service.id
        );

        const hasComment =
            service.additionalInfo?.some((x) => x.infoType === 'Comment') &&
            selected;
        const isGlassService = service.category === 'GlassDamage' && selected;

        if (hasComment) {
            return (
                <CommentField
                    serviceForm={servicesForm}
                    service={item.service}
                />
            );
        }

        if (isGlassService) {
            return <GlassService servicesForm={servicesForm} />;
        }
    }
}

export function StandardServicesEdit(props: StandardServicesEditProps) {
    const [lc] = useLanguageContext();

    const servicesAndGroups = preProcessServices(lc, props.services);
    const useBookingRequestEmail = useFlag('use-booking-request-email');

    const multichoiceOptions = servicesAndGroups.map((x) => {
        if (x.type === 'group') {
            return {
                value: x.group.id,
                label: x.group.name,
                description: getServiceGroupDescription(x.group, lc),
                item: x,
            };
        } else {
            return {
                value: x.service.id,
                label: x.service.name,
                description: x.service.description,
                item: x,
            };
        }
    });

    const updateSectionData = (event: StdServicesForm) => {
        const selectedServices: StandardServicesData['selectedServices'] =
            event.selectedIds
                .filter((serviceId) => !SERVICE_GROUP_IDS.includes(serviceId))
                .map((id) => props.services.find((x) => x.id === id))
                .filter(
                    (service): service is ServiceViewModel =>
                        service !== undefined
                )
                .map((selectedService) => {
                    if (selectedService.category === 'GlassDamage') {
                        //Handle legacy Glass damage service
                        return {
                            serviceId: selectedService.id,
                            comment: getGlassDamageComment(
                                event.glassDamageKind,
                                event.glassDamageWhen,
                                event.glassDamageWhere,
                                lc
                            ).comment,
                            serviceCategory: selectedService.category,
                            serviceType: selectedService.serviceType,
                            adapterId: selectedService.adapterId,
                            dealerSpecificInformation:
                                selectedService.dealerSpecificInformation,
                        };
                    }
                    return {
                        serviceId: selectedService.id,
                        comment: ((comment?: string) =>
                            !comment
                                ? undefined
                                : `${lc.standardServices.other_service_title}\n${comment}`)(
                            event.serviceAdditionalInfo.find(
                                (x) => x.serviceId === selectedService.id
                            )?.comment
                        ),
                        serviceCategory: selectedService.category,
                        serviceType: selectedService.serviceType,
                        adapterId: selectedService.adapterId,
                        dealerSpecificInformation:
                            selectedService.dealerSpecificInformation,
                    };
                });

        if (shouldBeBookingRequest(selectedServices, useBookingRequestEmail)) {
            props.setIsBookingRequest(true);
        } else {
            props.setIsBookingRequest(false);
        }

        const mileage = event.mileage;
        props.setSectionState({
            viewMode: 'done',
            data: { selectedServices: selectedServices, mileage },
        });
    };

    const inputProps = props.servicesForm.inputProps('selectedIds');
    const { value: selectedValues, validity } = inputProps;
    const cardGroupValue = Object.fromEntries(
        selectedValues.map((x) => [x, true])
    );

    return (
        <FormProvider
            id={'standardserviceslist-formprovider'}
            form={props.servicesForm}
            hideNecessityText={true}
            onSubmit={(event) => updateSectionData(event)}
        >
            <BilholdInnerViewLayout>
                <CheckboxCardGroup
                    id={`standardserviceslist-selectedIds`}
                    value={cardGroupValue}
                    validity={validity}
                    density="compact"
                    onChange={(option, value) => {
                        const newValue = { ...cardGroupValue, [option]: value };
                        const newSelectedValues = Object.entries(newValue)
                            .filter(([_key, val]) => val)
                            .map(([key]) => key);
                        const currentOption = multichoiceOptions.find(
                            (x) => x.value === option
                        );
                        if (currentOption?.item.type === 'group' && !value) {
                            // When deselecting a group, also deselect all services in the group
                            const groupServices =
                                currentOption.item.group.services;
                            inputProps.setValue(
                                newSelectedValues.filter(
                                    (serviceId) =>
                                        !groupServices.some(
                                            (groupService) =>
                                                serviceId === groupService.id
                                        )
                                )
                            );
                        } else {
                            inputProps.setValue(newSelectedValues);
                        }
                    }}
                    gap="xxs"
                    cards={multichoiceOptions.map((option) => {
                        const isSelected = cardGroupValue[option.value];

                        /*
                        TODO: this conditional is to hide the "Fra 599" price when the
                        tire group is opened. This is how it worked previously.
                        Discuss with Markus how this should work now.

                        TODO: We also hide the price for "recommended services" when opened,
                        and instead show it below the service checkboxes, as it makes
                        more sense for the price sum to be below the constituent prices.
                        We could potentially always show the price at the bottom of the card
                        (below children). Discuss with Markus.
                        */
                        const hideServicePrice =
                            option.item.type === 'group' &&
                            (isTireGroup(option.item.group) ||
                                isRecommendedGroup(option.item.group)) &&
                            isSelected;
                        return {
                            value: option.value,
                            label: option.label,
                            description: (
                                <Column gap="xs">
                                    {option.description && (
                                        <ExpandableServiceDescription
                                            description={option.description}
                                            forceExpanded={isSelected}
                                        />
                                    )}
                                    {!hideServicePrice && (
                                        <ServicePrice
                                            service={option.item}
                                            selectedServiceIds={
                                                props.servicesForm.state.raw
                                                    .selectedIds
                                            }
                                        />
                                    )}
                                </Column>
                            ),
                            children: renderServiceComponents(
                                option.item,
                                props
                            ),
                        };
                    })}
                />
                <Description>{lc.prices.disclaimer_price}</Description>
                <BilholdNextButton />
            </BilholdInnerViewLayout>
        </FormProvider>
    );
}

// This function is used to determine if it should be a booking request or not. Currrently it is only a booking request if it is an Annata booking and a standard service is selected. Only recommended services will be proper bookings.
function shouldBeBookingRequest(
    selectedServices: SelectedService[],
    useBookingRequestEmail: boolean
) {
    const isAnnataBooking = selectedServices.some((service) =>
        isAnnataAdapter(service?.adapterId)
    );
    const hasSelectedBookingRequestService = selectedServices.some(
        (x) => x?.serviceCategory !== 'RecommendedService'
    );
    return (
        isAnnataBooking &&
        (hasSelectedBookingRequestService || useBookingRequestEmail)
    );
}

import { datadogRum } from '@datadog/browser-rum';
import { LogoBrand } from '@moller/design-system/foundation/logos/Logo.js';
import { CarStatusType, CarWithStatus } from 'src/types/CarWithStatus';
import toLowerCaseWithCapitalize from 'src/utils/string/toLowerCaseWithCapitalize';
import { getThemeFromDealerName } from 'theming';

export const selectHomeTheme = (
    car?: CarWithStatus,
    preferredDealer?: string
): LogoBrand | null => {
    switch (car?.statusType) {
        case CarStatusType.PENDING_CAR:
        case CarStatusType.ORDERED_CAR:
            if (car?.car.brand) {
                return mapToLogoBrand(car?.car.brand);
            }
            return null;
        case CarStatusType.EXISTING_CAR:
            if (preferredDealer) {
                return mapToLogoBrand(preferredDealer);
            }
            return null;
        default:
            return null;
    }
};

const allDealerLogos = [
    'berge',
    'bilhold',
    'fremme',
    'sulland',
    'slatlem',
    'gumpen',
    'dahles',
    'autoria',
    'albjerk',
    'alta',
    'kirkenes',
    'mobile',
    'audi',
    'skoda',
    'vw',
] as string[];

const mapToLogoBrand = (preferredDealerOrCarBrand: string) => {
    const theme = getThemeFromDealerName(preferredDealerOrCarBrand);
    if (theme !== 'bilhold') {
        datadogRum.setGlobalContext({
            themeBranding: {
                dealerLogo: toLowerCaseWithCapitalize(theme),
            },
        });
        return theme;
    }

    const dealerLogo = allDealerLogos.find((dealer) =>
        preferredDealerOrCarBrand.toLowerCase().includes(dealer)
    );
    if (dealerLogo) {
        datadogRum.setGlobalContext({
            themeBranding: {
                dealerLogo: toLowerCaseWithCapitalize(dealerLogo),
            },
        });
        return dealerLogo as LogoBrand;
    }
    datadogRum.setGlobalContext({
        themeBranding: {
            dealerLogo: 'Bilhold',
        },
    });
    return 'bilhold' as LogoBrand;
};

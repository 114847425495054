import { useQuery } from '@tanstack/react-query';
import { useFetchersContext } from 'external-apis';
import {
    AvailableServicesQuery,
    ServiceType,
    ServiceViewModel,
} from 'external-apis/src/types/port';
import { pipe } from 'fp-ts/function';
import { groupBy } from 'fp-ts/lib/NonEmptyArray';
import { FriendlyQueryError } from '../../lib/errors/PortError';
import {
    LanguageContextType,
    useLanguageContext,
} from '../../lib/languages/languageContext';
import { STALE_TIME } from '../../lib/query-client/config';
import { useGetApiLocaleConfig } from '../apiLocale';

const useFindServices = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.port.fetcher
        .path('/services/search')
        .method('post')
        .create();
};

type UseGetServices = {
    vin?: string;
    dealerNumbers?: string[];
    mileage?: string;
    keepPreviousData?: boolean;
};

export function useGetServices(props: UseGetServices, enabled = true) {
    const [lc] = useLanguageContext();
    const { vin, dealerNumbers, mileage } = props;
    const { countryCode } = useGetApiLocaleConfig();
    const findServices = useFindServices();
    const body: AvailableServicesQuery = {
        vin: vin ?? '',
        dealerFilter: {
            selectedDealerNumbers: dealerNumbers,
        },
        mileage: mileage ? +mileage : null,
        countryCode,
    };

    return useQuery<Services, Error>({
        keepPreviousData: props.keepPreviousData,
        queryKey: [body],
        queryFn: () =>
            getServices(body, lc, findServices).then((x) => ({
                ...x,
                Recommended: x.Recommended,
            })),
        staleTime: STALE_TIME.hour,
        cacheTime: STALE_TIME.hour,
        enabled: !!(vin && dealerNumbers) && enabled,
    });
}

export type Services = {
    [x in ServiceType]: ServiceViewModel[];
};

async function getServices(
    body: AvailableServicesQuery,
    lc: LanguageContextType,
    findServices: ReturnType<typeof useFindServices>
) {
    try {
        const result = await findServices(body);
        const groupedServices = pipe<
            ServiceViewModel[],
            Record<ServiceType, ServiceViewModel[]>
        >(
            result.data,
            groupBy((x) => x.serviceType)
        );
        // groupBy can return undefined for a ServiceType
        return {
            Standard: groupedServices.Standard ?? [],
            Additional: groupedServices.Additional ?? [],
            Transport: groupedServices.Transport ?? [],
            Delivery: groupedServices.Delivery ?? [],
            Recommended: groupedServices.Recommended ?? [],
            Waiting: groupedServices.Waiting ?? [],
            None: groupedServices.None ?? [],
        };
    } catch (e) {
        if (e instanceof findServices.Error) {
            throw new FriendlyQueryError(
                lc.errors.port_services_default,
                e,
                e.status
            );
        }
        throw e;
    }
}

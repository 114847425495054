import { useMutation, useQueryClient } from '@tanstack/react-query';
import getCurrentEnvironment from 'src/utils/environment/getCurrentEnvironment';
import put from 'src/utils/http/put';

export const useUpdateMileage = (vin: string, onSuccess: () => void) => {
    const queryClient = useQueryClient();
    const { bilholdApiUrl } = getCurrentEnvironment();

    return useMutation({
        mutationFn: (mileage: string) =>
            put(
                `${bilholdApiUrl}/v1/cars/${vin}/mileage`,
                { mileage: parseInt(mileage) },
                {}
            ),
        onSuccess: () => {
            void queryClient.invalidateQueries({
                queryKey: ['mileage', vin],
            });
            void queryClient.invalidateQueries({
                queryKey: ['carProfile'],
            });
            onSuccess();
        },
    });
};

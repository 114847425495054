import {
    AlertBanner,
    breakpointMobile,
    IconButton,
    LabelText,
    ProgressBar,
    Subtitle,
} from '@moller/design-system';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
    PATH_SA_DEAL,
    PATH_SERVICE_DEAL,
    PATH_SV_DEAL,
} from 'src/routing/routes';
import { withErrorBoundary } from 'src/utils/ErrorBoundary';
import { styled } from 'styled-components';
import { SvaServiceDeal } from '../types/CarServiceDeal';
import ServiceDealStatus from '../types/ServiceDealStatuses';
import ServiceDealType from '../types/ServiceDealTypes';

const SVA_USED_CAR_MILEAGE = 999999;

interface SvaContentProps {
    [ServiceDealType.SA]: {
        header: string;
        infoLink: string;
    };
    [ServiceDealType.SV]: {
        header: string;
        infoLink: string;
    };
}
const svaContent: SvaContentProps = {
    [ServiceDealType.SA]: {
        header: 'Serviceavtale',
        infoLink: `${PATH_SERVICE_DEAL}/${PATH_SA_DEAL}`,
    },
    [ServiceDealType.SV]: {
        header: 'Service- og vedlikeholdsavtale',
        infoLink: `${PATH_SERVICE_DEAL}/${PATH_SV_DEAL}`,
    },
};
export const InfoContainerCard = styled.div`
    background-color: var(--moller-color-surface-variant);
    color: var(--moller-color-on-surface-variant);
    border: solid var(--moller-stroke-small)
        var(--moller-color-on-surface-variant);
    padding: var(--moller-spacing-s);
    border-radius: var(--moller-radius-card);
    display: flex;
    flex-direction: column;
    ${LabelText.selector} {
        padding-bottom: var(--moller-spacing-s);
        margin: 0;
    }
    @media screen and (min-width: ${breakpointMobile}) {
        grid-column: 1 / 2 span;
    }
`;

const GridLayout = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--moller-spacing-xl);
    padding: var(--moller-spacing-s) 0;
    @media screen and (min-width: ${breakpointMobile}) {
        grid-template-columns: 1fr 1fr;
    }
`;

const ProgressBarLabel = styled.p`
    margin-top: var(--moller-spacing-xs);
`;

export const SvaServiceContainer = ({
    svaServiceDeal,
}: {
    svaServiceDeal: SvaServiceDeal;
}) => {
    const deal = svaContent[svaServiceDeal?.serviceId];

    if (!deal?.header) {
        return <></>;
    }

    const {
        agreementMileage,
        currentMileage,
        activationDate,
        agreementExpirationDate,
        status,
        statusReason,
    } = svaServiceDeal;

    const agreementStart = moment(activationDate);
    const agreementEnd = moment(agreementExpirationDate);
    const totalMonths = agreementEnd.diff(agreementStart, 'months');
    const diffToTotal = totalMonths - moment().diff(agreementStart, 'months');
    const monthsRemaining = diffToTotal >= 0 ? diffToTotal : 0;
    const MonthPercentage =
        ((totalMonths - monthsRemaining) / totalMonths) * 100;

    const isNewCar = agreementMileage != SVA_USED_CAR_MILEAGE;
    const MilagePercentage = (currentMileage / agreementMileage) * 100;

    const suspended = status === ServiceDealStatus.SUSPENDED;
    const suspendedReason = statusReason.toLowerCase();
    if (suspended) {
        return (
            <InfoContainerCard>
                <LabelText>{deal.header}</LabelText>
                <Subtitle>
                    Din avtale er midlertidig stoppet grunnet {suspendedReason}
                </Subtitle>
            </InfoContainerCard>
        );
    }
    return (
        <InfoContainerCard>
            <LabelText $as={'h2'}>{deal.header}</LabelText>
            <GridLayout>
                <div>
                    <ProgressBar size="large" percentage={MonthPercentage} />
                    <ProgressBarLabel>
                        {monthsRemaining} måneder gjenstår
                    </ProgressBarLabel>
                </div>
                {isNewCar ? (
                    <div>
                        <ProgressBar
                            percentage={
                                MilagePercentage <= 100 ? MilagePercentage : 100
                            }
                            size="large"
                        />
                        <ProgressBarLabel>
                            {currentMileage <= agreementMileage
                                ? currentMileage
                                : agreementMileage}{' '}
                            av {agreementMileage} km
                        </ProgressBarLabel>
                    </div>
                ) : null}
            </GridLayout>
            <IconButton
                icon={'chevron_right'}
                label="Les mer om avtalen"
                showLabel="left"
                $as={Link}
                to={deal.infoLink}
                style={{ alignSelf: 'flex-end' }}
            />
        </InfoContainerCard>
    );
};

const fallback = (retry: () => void) => (
    <AlertBanner
        type="warning"
        message="Vi greide dessverre ikke å vise informasjon om din serviceavtale. Prøv igjen senere!"
        action={{ label: 'Prøv igjen', onClick: retry }}
    />
);

export const SvaService = withErrorBoundary('SvaService', SvaServiceContainer, {
    fallback,
});

import {
    DealerSpecificInformation,
    ServiceCategory,
    ServiceType,
} from 'external-apis/src/types/port';
import { GLASS_DAMAGE_ID } from '../../lib/constants/serviceConstants';
import { useLocalFormState } from '@moller/design-system';
import { useSectionValidators } from './useSectionValidators';
import {
    noValidation,
    required,
} from '@moller/design-system/utilities/validation';
import { TireChangeFormState } from './features/tireChange/tireChangeFormState';

export type SelectedService = {
    serviceId: string;
    adapterId: string;
    comment?: string;
    serviceCategory?: ServiceCategory;
    serviceType: ServiceType;
    dealerSpecificInformation: DealerSpecificInformation[];
};

export type StdServicesForm = {
    selectedIds: string[];
    tireChange: TireChangeFormState;
    serviceAdditionalInfo: SelectedService[];
    glassDamageRadioOption: (typeof GLASS_DAMAGE_ID)[keyof typeof GLASS_DAMAGE_ID];
    glassDamageKind: (typeof GLASS_DAMAGE_ID)[keyof typeof GLASS_DAMAGE_ID];
    glassDamageWhen: string;
    glassDamageWhere: string;
    mileage: string;
};

export function useStandardServicesForm(
    validators: ReturnType<typeof useSectionValidators>,
    selectedIds: StdServicesForm['selectedIds'] | undefined
) {
    return useLocalFormState<StdServicesForm>(
        {
            selectedIds: selectedIds ?? [],
            tireChange: { selectedId: undefined, open: false },
            glassDamageRadioOption: '5-large',
            glassDamageKind: '5-large',
            glassDamageWhen: '',
            glassDamageWhere: '',
            serviceAdditionalInfo: [],
            mileage: '',
        },
        {
            selectedIds: required(validators.validServiceIds),
            tireChange: noValidation(),
            serviceAdditionalInfo: validators.validServiceAdditionalInfo,
            glassDamageRadioOption: noValidation(),
            glassDamageKind: noValidation(),
            glassDamageWhen: validators.validGlassDamageDate,
            glassDamageWhere: validators.validGlassDamageLocation,
            mileage: validators.validMileage,
        },
        {
            showNecessityOn: 'optional',
        }
    );
}

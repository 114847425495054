import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { PUBLIC_API_PREFIX } from 'src/constants';
import Brand from 'src/types/Brand';
import CountryCode from 'src/types/CountryCode';
import Dealer from 'src/types/Dealer';
import { SimpleCoordinates } from 'src/types/SimpleCoordinates';
import get from 'src/utils/http/get';
import { DealerSearchDto } from './DealerSearchDto';
import { dealerSorters } from './dealerSorter';
import { getKilometersBetween } from './getKilometersBetween';
import { mapDealer } from './mapDealer';

export const useDealers = (
    brand: Brand | undefined,
    location: SimpleCoordinates | undefined,
    dealerGroup: string,
    dealerType: string
) => {
    const { data: dealers, isLoading } = useQuery({
        queryKey: ['dealerSearch', CountryCode.NO, dealerType],
        queryFn: () =>
            get<DealerSearchDto[]>(
                `${PUBLIC_API_PREFIX}/dealerSearch?countryCode=${CountryCode.NO}&dealerType=${dealerType}`
            ).then((res) => res.data.map(mapDealer)),
        staleTime: 1000 * 60 * 60,
        placeholderData: [],
    });

    const memoizedDealers = useMemo(
        () =>
            dealers &&
            dealers
                .map((dealer: Dealer) => ({
                    ...dealer,
                    distanceFromClient: getKilometersBetween(
                        location,
                        dealer.location.coordinates
                    ),
                }))
                .filter(
                    (dealer) =>
                        dealer.name
                            .toUpperCase()
                            .includes(dealerGroup.toUpperCase()) &&
                        (!brand ||
                            dealer.serviceCertifications?.includes(brand))
                )
                .sort(
                    location
                        ? dealerSorters[0].sortFunction
                        : dealerSorters[1].sortFunction
                ),
        [brand, dealerGroup, dealers, location]
    );

    return { isLoading, dealers: memoizedDealers };
};

import { IconButton } from '@moller/design-system';
import { useNavigate } from 'react-router-dom';
import {
    PATH_EXPLORE,
    PATH_HOME,
    PATH_MESSAGES,
    PATH_MY_PREFERRED_DEALER,
    PATH_MY_PROFILE,
    PATH_PROFILE_OVERVIEW,
} from 'src/routing/routes';
import MessageBadge from 'src/shared/message-badge/MessageBadge';
import { useMessageThreads } from 'src/utils/hooks/useMessageThreads';
import lang from 'src/utils/lang';
import { styled } from 'styled-components';
import {
    getNumberOfUnOpenedMessages,
    getUrlToUnreadMessages,
} from '../contact/utils/messagesUtils';

const MessageIconContainer = styled.div`
    width: fit-content;
    position: relative;
`;

export const HeaderOptions = () => {
    const navigate = useNavigate();
    const { data: messages } = useMessageThreads();

    const numberOfUnreadMessages = getNumberOfUnOpenedMessages(messages);
    const urlToUnreadMessages = getUrlToUnreadMessages(messages);

    return (
        <>
            <IconButton
                icon="home"
                label={lang.frontpage}
                showLabel="right"
                onClick={() => navigate(PATH_HOME)}
                variantOverride={
                    location.pathname.includes(PATH_HOME) ? 'filled' : undefined
                }
            />
            <MessageIconContainer>
                <IconButton
                    icon="chat"
                    label={lang.messages}
                    showLabel="right"
                    onClick={() => navigate(urlToUnreadMessages)}
                    variantOverride={
                        location.pathname.includes(PATH_MESSAGES)
                            ? 'filled'
                            : undefined
                    }
                />
                <MessageBadge unread={numberOfUnreadMessages} />
            </MessageIconContainer>
            <IconButton
                icon="explore"
                label={lang.explore}
                showLabel="right"
                onClick={() => navigate(PATH_EXPLORE)}
                variantOverride={
                    location.pathname.includes(PATH_EXPLORE)
                        ? 'filled'
                        : undefined
                }
            />
            <IconButton
                icon="settings"
                label={lang.settings}
                showLabel="right"
                onClick={() => navigate(PATH_PROFILE_OVERVIEW)}
                variantOverride={
                    location.pathname.includes(PATH_PROFILE_OVERVIEW) ||
                    location.pathname.includes(PATH_MY_PROFILE) ||
                    location.pathname.includes(PATH_MY_PREFERRED_DEALER)
                        ? 'filled'
                        : undefined
                }
            />
        </>
    );
};

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import loadImage from 'blueimp-load-image';
import { ImageType } from 'react-images-uploading';
import get from 'src/utils/http/get';
import httpDelete from 'src/utils/http/httpDelete';
import post from 'src/utils/http/post';
import { loadImageUrls } from './loadImageUrl';
import { getDamageQueryKey } from './useDamageAppraisals';

export interface DamageMedia {
    id: number;
    mediaStorageId: string;
    tag: string;
    type: string;
}
const getMediaQueryKey = (damageId?: null | number) =>
    ['damageMedia', damageId] as const;
export const useGetMedia = (damageId?: null | number) => {
    return useQuery({
        queryKey: getMediaQueryKey(damageId),
        queryFn: async () => {
            const res = await get<DamageMedia[] | undefined>(
                `api/v3/media?relatedId=${damageId}`
            );
            if (res.data) {
                return await loadImageUrls(res.data);
            }
            return [];
        },
        enabled: !!damageId,
    });
};
export type ImageUploadType = {
    relatedId: number;
    tag: string;
    dataURL: string;
    file: File;
};

async function resizeImage(image: File): Promise<string> {
    const options = {
        maxWidth: 1280,
        maxHeight: 1280,
        canvas: true,
        orientation: 1,
    };
    const res = await loadImage(image, options);
    return (res.image as HTMLCanvasElement).toDataURL('image/jpeg');
}

export const useUploadMedia = (relatedId: number) => {
    const qc = useQueryClient();
    return useMutation({
        onSuccess: async (result, uploadImgs) => {
            const queryKey = getMediaQueryKey(relatedId);
            await qc.cancelQueries(queryKey);
            const resizedDataUrls = await Promise.all(
                uploadImgs.map((img) => resizeImage(img.file))
            );

            qc.setQueryData<ImageType[]>(queryKey, (existingImages) => {
                return [
                    ...(existingImages ?? []),
                    ...uploadImgs.map((img, idx) => ({
                        id: result[idx].data.id,
                        tag: img.tag,
                        dataURL: resizedDataUrls[idx],
                    })),
                ];
            });
            await qc.invalidateQueries(getDamageQueryKey(relatedId));
        },
        mutationFn: (images: ImageUploadType[]) =>
            Promise.all(
                images.map(async ({ relatedId, tag, file }) => {
                    const resizedDataUrl = await resizeImage(file);
                    return post<{ id: number }>('api/v3/media', {
                        relatedId: relatedId,
                        tag: tag,
                        data: resizedDataUrl.replace(
                            /data:image\/(.+);base64,/,
                            ''
                        ),
                    });
                })
            ),
    });
};

export const useDeleteMedia = (relatedId: number) => {
    const qc = useQueryClient();
    return useMutation({
        onSuccess: async (_, deleteImg) => {
            const queryKey = getMediaQueryKey(relatedId);
            await qc.cancelQueries(queryKey);
            qc.setQueryData<ImageType[]>(queryKey, (existingImages) => {
                return existingImages?.filter((img) => deleteImg.id !== img.id);
            });
        },
        mutationFn: ({ id }: { id: number }) =>
            httpDelete(`api/v3/deleteMedia/${id}`),
    });
};

import { ServiceGroup } from '../groups/ServiceGroup';
import { ServicePriceType } from './ServicePrice';
import { isRecommendedGroup, ServiceOrGroup } from '../groupServices';
import { CURRENCY_NOK, ServiceViewModel } from 'external-apis/src/types/port';

export function getServiceGroupPrice(
    serviceGroup: ServiceGroup,
    selectedServiceIds?: string[]
): ServicePriceType {
    if (isRecommendedGroup(serviceGroup)) {
        const selectedServices = getSelectedServices(
            serviceGroup,
            selectedServiceIds
        );
        const sumPerDealer = getSumPricePerDealer(selectedServices);
        return getGroupServicePrice(sumPerDealer);
    }
    const prices = serviceGroup.services.map((service) =>
        getServicePrice(service)
    );
    return getGroupServicePrice(prices);
}

function getGroupServicePrice(
    servicePrices: ServicePriceType[]
): ServicePriceType {
    const sortedPrices = sortServicePrices(servicePrices);
    const leastExpensive = sortedPrices[0];
    const mostExpensive = sortedPrices[sortedPrices.length - 1];
    const isUnknown =
        !leastExpensive || leastExpensive.type === 'PRICE_UNKNOWN';
    if (isUnknown) {
        return {
            type: 'PRICE_UNKNOWN',
        };
    }
    const isFixed =
        mostExpensive &&
        mostExpensive.type !== 'PRICE_UNKNOWN' &&
        leastExpensive.price === mostExpensive.price;
    if (isFixed) {
        return leastExpensive;
    }
    return {
        ...leastExpensive,
        type: 'PRICE_FROM',
    };
}

export const calculatePrice = (
    serviceOrGroup: ServiceOrGroup,
    selectedServiceIds?: string[]
): ServicePriceType => {
    if (serviceOrGroup.type === 'service') {
        return getServicePrice(serviceOrGroup.service);
    }
    return getServiceGroupPrice(serviceOrGroup.group, selectedServiceIds);
};

function sortServicePrices(
    servicePrices: ServicePriceType[]
): ServicePriceType[] {
    const getPrice = (price: ServicePriceType) => {
        if (price.type === 'PRICE_UNKNOWN') {
            return Number.MAX_VALUE;
        }
        return price.price;
    };

    return servicePrices.sort((a, b) => {
        const priceA = getPrice(a);
        const priceB = getPrice(b);
        if (priceB < priceA) return 1;
        if (priceB > priceA) return -1;
        return 0;
    });
}

function getServicePrice(
    service: ServiceViewModel,
    dealerNumber?: string
): ServicePriceType {
    const filteredPrices = service.dealerSpecificInformation
        .filter((dealer) =>
            dealerNumber ? dealer.dealerNumber === dealerNumber : true
        )
        .map((info) => info.price?.fixedPrice);
    const hasOnlyNull = filteredPrices.every((price) => price === null);
    if (hasOnlyNull) {
        return {
            type: 'PRICE_UNKNOWN',
        };
    }
    const pricesSorted = filteredPrices.sort((a, b) =>
        (a ?? 0) > (b ?? 0) ? 1 : -1
    );

    const leastExpensive = pricesSorted[0];
    const mostExpensive = pricesSorted[filteredPrices.length - 1];
    if (
        (mostExpensive && leastExpensive == mostExpensive) ||
        mostExpensive === 0
    ) {
        return {
            price: mostExpensive,
            type: 'PRICE_FIXED',
            currency:
                service.dealerSpecificInformation[0].price?.currency ??
                CURRENCY_NOK,
        };
    }
    if (leastExpensive != undefined) {
        return {
            price: leastExpensive,
            type: 'PRICE_FROM',
            currency:
                service.dealerSpecificInformation[0].price?.currency ??
                CURRENCY_NOK,
        };
    }

    return {
        type: 'PRICE_UNKNOWN',
    };
}

export function getSummarizedPrice(
    selectedServices: ServiceViewModel[]
): ServicePriceType {
    const sumPerDealer = getSumPricePerDealer(selectedServices);
    return getGroupServicePrice(sumPerDealer);
}

function getSumPricePerDealer(
    selectedServices: ServiceViewModel[]
): ServicePriceType[] {
    const listOfDealers = selectedServices.reduce((acc, elmt) => {
        const dealerNumbers = elmt.dealerSpecificInformation.map(
            (dealerNumber) => dealerNumber.dealerNumber
        );
        return new Set([...acc, ...dealerNumbers]);
    }, new Set<string>([]));

    return [...listOfDealers].map((dealerNumber) => {
        const dealerPrices = selectedServices.map((service) =>
            getServicePrice(service, dealerNumber)
        );
        return getSumOfPrices(dealerPrices);
    });
}

export const hasUnknownPrice = (prices: ServicePriceType[]): boolean =>
    prices.some((price) => price.type === 'PRICE_UNKNOWN');

function getSumOfPrices(prices: ServicePriceType[]): ServicePriceType {
    if (hasUnknownPrice(prices)) {
        return {
            type: 'PRICE_UNKNOWN',
        };
    }
    const allPricesFixed = prices.every(
        (price) => price.type === 'PRICE_FIXED'
    );
    const sum = prices.reduce<number>((sum, element) => {
        if (element.type === 'PRICE_UNKNOWN') {
            return sum;
        }
        return sum + element.price;
    }, 0);
    return {
        type: allPricesFixed ? 'PRICE_FIXED' : 'PRICE_FROM',
        price: Math.ceil(sum),
        currency: CURRENCY_NOK,
    };
}

export const getSelectedServices = (
    serviceGroup: ServiceGroup,
    selectedServiceIds?: string[]
): ServiceViewModel[] =>
    serviceGroup.services.filter((service) =>
        selectedServiceIds?.some(
            (selectedServiceId) => service.id === selectedServiceId
        )
    );

import {
    AddCarRelationFeasibilityResult,
    CarRelationType,
} from 'external-apis/src/types/bilhold';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Company from 'src/features/add-car/components/company/Company';
import Leaser from 'src/features/add-car/components/leaser/Leaser';
import Owner from 'src/features/add-car/components/owner/Owner';
import User from 'src/features/add-car/components/user/User';
import { useAddCar } from 'src/features/home/queries/useAddCarFunctions';
import { PATH_HOME } from 'src/routing/routes';
import CarRelation from 'src/utils/enums/CarRelation';
import {
    convertAddCarMethod,
    convertAddCarReason,
} from 'src/utils/string/formatCarVariables';

interface CarRelationContentProps {
    carRelation?: CarRelationType;
    registrationNumber: string;
    addCarRelationResult: AddCarRelationFeasibilityResult;
}

export const CarRelationContent = ({
    carRelation,
    registrationNumber,
    addCarRelationResult,
}: CarRelationContentProps) => {
    const [errorMessage, setErrorMessage] = useState('');

    const navigate = useNavigate();

    const redirect = () => {
        navigate(PATH_HOME);
    };

    const { mutate: addCarMutate, isLoading } = useAddCar(
        redirect,
        setErrorMessage
    );

    const addCar = (
        relationType: CarRelation,
        leaser?: string,
        companyCar?:
            | string
            | null
            | {
                  company: {
                      name: string | undefined;
                      electronicAddress: string | undefined;
                  };
              }
    ) => {
        addCarMutate({
            registrationNumber,
            vin: '',
            relationType,
            leaser,
            companyCar,
        });
    };

    const chosenRelation =
        addCarRelationResult.relationDetails?.relationTypes.find(
            (relation) => relation.type === carRelation
        );

    switch (carRelation) {
        case 'Owner':
            return (
                <Owner
                    reasons={
                        chosenRelation?.reasons?.map((x) =>
                            convertAddCarReason(x)
                        ) ?? []
                    }
                    addCarFunction={addCar}
                    addCarError={errorMessage}
                    setErrorMessage={setErrorMessage}
                />
            );
        case 'Leaser':
            return (
                <Leaser
                    reasons={
                        chosenRelation?.reasons?.map((x) =>
                            convertAddCarReason(x)
                        ) ?? []
                    }
                    addCarFunction={addCar}
                    addCarError={errorMessage}
                    isLoading={isLoading}
                    setErrorMessage={setErrorMessage}
                />
            );
        case 'User':
            return (
                <User
                    reasons={
                        chosenRelation?.reasons?.map((x) =>
                            convertAddCarReason(x)
                        ) ?? []
                    }
                    method={convertAddCarMethod(chosenRelation?.method)}
                    addCarFunction={addCar}
                    addCarError={errorMessage}
                    isLoading={isLoading}
                />
            );
        case 'CompanyCar':
            return (
                <Company
                    reasons={
                        chosenRelation?.reasons?.map((x) =>
                            convertAddCarReason(x)
                        ) ?? []
                    }
                    addCarFunction={addCar}
                    addCarError={errorMessage}
                    isLoading={isLoading}
                    setErrorMessage={setErrorMessage}
                />
            );

        default:
            return <></>;
    }
};

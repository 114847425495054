import {
    runValidation,
    validRegistrationNumber,
} from '@moller/design-system/utilities/validation';
import { useEffect, useState } from 'react';
import { useGetRegisteredVehicles } from './_api/http/vehicle';
import { useAppConfig } from './lib/config/configContext';
import { useFastTrack } from './lib/useFastTrack';
import { useAdditionalServices } from './sections/additional-services/AdditionalServicesSection';
import { useChooseDealer } from './sections/choose-dealer/ChooseDealerSection';
import { useChooseTimeSlot } from './sections/choose-timeslot/ChooseTimeslotSection';
import { useChooseVehicle } from './sections/choose-vehicle/ChooseVehicleSection';
import { useConfirmBooking } from './sections/confirm-booking/ConfirmBookingSection';
import { useStandardServices } from './sections/standard-services/useStandardServices';
import { useTransportServices } from './sections/transport-services/TransportServicesSection';

export function useBookingState() {
    const config = useAppConfig();
    const { registrationNumber, vin } = config.bookingParameters;
    const vehicles = useGetRegisteredVehicles();
    const vehicleFromVin = vehicles.data?.find(
        (x) => x.vehicleIdentificationNumber === vin
    );
    const fastTrackType = useFastTrack();
    const isFastTrack = !!fastTrackType.fastTrack;

    const [isBookingRequest, setIsBookingRequest] = useState(false);

    const validation = runValidation(
        registrationNumber ?? '',
        undefined,
        validRegistrationNumber
    );

    const chooseVehicle = useChooseVehicle({
        initialState:
            validation.isValid && validation.value
                ? {
                      viewMode: 'done',
                      data: { registrationNumber: validation.value },
                  }
                : { viewMode: 'edit' },
    });

    const setVehicle = chooseVehicle.props.setSectionState;
    useEffect(() => {
        if (vehicleFromVin) {
            setVehicle({
                viewMode: 'done',
                data: { registrationNumber: vehicleFromVin.plateNumber },
            });
        }
    }, [setVehicle, vehicleFromVin]);

    const chooseDealer = useChooseDealer({
        initialState: {
            viewMode: 'hidden',
        },
        dependencies: { chooseVehicle: chooseVehicle.result },
    });

    const standardServices = useStandardServices({
        initialState: {
            viewMode: 'hidden',
        },
        dependencies: {
            chooseVehicle: chooseVehicle.result,
            chooseDealer: chooseDealer.result,
        },
    });

    const chooseTimeSlot = useChooseTimeSlot({
        initialState: {
            viewMode: 'hidden',
        },
        dependencies: {
            chooseVehicle: chooseVehicle.result,
            chooseDealer: chooseDealer.result,
            standardServices: standardServices.result,
        },
        isBookingRequest,
    });

    const transportServices = useTransportServices({
        initialState: {
            viewMode: 'hidden',
        },
        dependencies: {
            chooseVehicle: chooseVehicle.result,
            chooseTimeSlot: chooseTimeSlot.result,
            chooseStandardServices: standardServices.result,
            chooseDealer: chooseDealer.result,
        },
        isFastTrack,
    });

    const additionalServices = useAdditionalServices({
        initialState: {
            viewMode: 'hidden',
        },
        dependencies: {
            chooseVehicle: chooseVehicle.result,
            chooseTimeSlot: chooseTimeSlot.result,
            transportServices: transportServices.result,
            chooseDealer: chooseDealer.result,
        },
        isFastTrack,
    });

    const confirmBooking = useConfirmBooking({
        initialState: {
            viewMode: 'edit',
        },
        dependencies: {
            chooseVehicle: chooseVehicle.result,
            chooseDealer: chooseDealer.result,
            standardServices: standardServices.result,
            chooseTimeSlot: chooseTimeSlot.result,
            transportServices: transportServices.result,
            additionalServices: additionalServices.result,
        },
    });

    return {
        chooseVehicle,
        chooseDealer,
        standardServices,
        chooseTimeSlot,
        additionalServices,
        transportServices,
        confirmBooking,
        isBookingRequest,
        setIsBookingRequest,
        isFastrack: !!fastTrackType.fastTrack,
    };
}

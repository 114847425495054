import moment from 'moment';
import {
    DATE_FORMAT_LONG,
    DATE_FORMAT_SHORT_LONG,
    HUMAN_DATE_FORMAT,
    TIME_FORMAT,
} from 'src/constants';
import lang from 'utils/lang';
import mapWeekDayNumberToString from '../dates/mapWeekDayNumberToString';

// 22. januar 2023 | 1.januar 2023
export const formatDate = (dateText: string) => {
    const months = [
        'january',
        'february',
        'march',
        'april',
        'may',
        'june',
        'july',
        'august',
        'september',
        'october',
        'november',
        'december',
    ] as const;
    const date = new Date(dateText);
    return `${date.getDate()}. ${
        lang[months[date.getMonth()]]
    } ${date.getFullYear()}`;
};

// 01. januar 2023 | 23. januar 2023 | I dag | I går
export const formatMessageDate = (timestamp: string) => {
    const date = moment(timestamp);
    const today = moment();
    const yesterday = moment().subtract(1, 'days');

    if (date.isSame(today, 'day')) {
        return lang.todayTime;
    }
    if (date.isSame(yesterday, 'day')) {
        return lang.yesterdayTime;
    }
    return date.format(DATE_FORMAT_LONG);
};

// Onsdag 1. februar 2023 | Torsdag 23. februar 2023
export const getFormatDateWithDay = (timestamps: string) => {
    const date = moment(timestamps);
    const day = lang[mapWeekDayNumberToString(date.day())];
    const formattedDate = formatDate(date.toISOString());

    return `${day} ${formattedDate}`;
};

export const sortByTime = (a?: string, b?: string) => {
    const timeA = moment(a ?? null);
    const timeB = moment(b ?? null);
    if (timeA > timeB) {
        return 1;
    }
    if (timeA < timeB) {
        return -1;
    }
    return 0;
};

// 1. feb. 2023 | 23. jan 2023
export const formatShortDate = (date: string) => {
    const time = moment(date);
    return time.format(DATE_FORMAT_SHORT_LONG);
};

// 09:00 | 10:30
export const formatTime = (date: string) => {
    const time = moment(date);
    return time.format(TIME_FORMAT);
};

// 1. februar | 23. januar
export const FormatDayAndMonth = (date: string) => {
    const time = moment(date);
    return time.format(HUMAN_DATE_FORMAT);
};

// 21 | 3
export const getNumberOfRemainingDays = (date: string) => {
    const day = moment(date);
    return day.diff(moment(), 'days');
};

export const formatDateWithFormat = (
    date: string | undefined,
    format: string
) => {
    if (!date) {
        return '';
    }
    const time = moment(date);
    return time.format(format);
};

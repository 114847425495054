import { TextArea } from '@moller/design-system';
import { ServiceViewModel } from 'external-apis/src/types/port';
import { BilholdInnerViewLayout } from '../../components/bilhold/BilholdView';
import { useLanguageContext } from '../../lib/languages/languageContext';
import { SelectedService, useStandardServicesForm } from './useSectionForm';

const MAX_COMMENT_LENGTH = 1000;

export function CommentField(props: {
    serviceForm: ReturnType<typeof useStandardServicesForm>;
    service: ServiceViewModel;
}) {
    const [lc] = useLanguageContext();
    const value =
        props.serviceForm.state.raw.serviceAdditionalInfo.find(
            (serv) => serv.serviceId === props.service.id
        )?.comment ?? '';
    const counterText = () => {
        const count = value?.length || 0;
        return `${count} / ${MAX_COMMENT_LENGTH}`;
    };
    const inputProps = props.serviceForm.inputProps('serviceAdditionalInfo');

    return (
        <BilholdInnerViewLayout>
            <TextArea
                onChange={(e) => {
                    e.preventDefault();
                    const oldSelectedValues = inputProps.value;
                    const currentServiceAdditionalInfo = oldSelectedValues.find(
                        (x) => x.serviceId == props.service.id
                    );
                    const newValue: SelectedService = {
                        ...currentServiceAdditionalInfo,
                        serviceId: props.service.id,
                        comment: e.target.value,
                        serviceType: props.service.serviceType,
                        serviceCategory: props.service.category ?? 'None',
                        dealerSpecificInformation:
                            props.service.dealerSpecificInformation,
                        adapterId: props.service.adapterId,
                    };
                    const filteredOldSelectedValues = oldSelectedValues.filter(
                        (x) => x.serviceId !== props.service.id
                    );
                    const newValues = [...filteredOldSelectedValues, newValue];
                    inputProps.setValue(newValues);
                }}
                value={value}
                label={lc.standardServices.other_service_label}
                maxLength={MAX_COMMENT_LENGTH}
                counterText={counterText()}
                validity={inputProps.validity}
            />
        </BilholdInnerViewLayout>
    );
}

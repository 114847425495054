import { Column } from '@moller/design-system';
import { ServiceViewModel } from 'external-apis/src/types/port';
import { useEffect, useRef } from 'react';
import { BilholdInnerViewLayout } from '../../../components/bilhold/BilholdView';
import { FlexRow } from '../../../components/FlexRow';
import { MultiCheckboxInput } from '../../../lib/form/MultiChoiceInput';
import { useLanguageContext } from '../../../lib/languages/languageContext';
import { SubServicePrice } from '../price/ServicePrice';
import { useStandardServicesForm } from '../useSectionForm';
import { IndentedDescription } from './components/IndentedDescription';

type RecommendedServicesProps = {
    servicesForm: ReturnType<typeof useStandardServicesForm>;
    recommendedServices: ServiceViewModel[];
    isLoading: boolean;
    shouldShowServices: boolean;
};

export const RecommendedServices: React.FC<RecommendedServicesProps> = ({
    servicesForm,
    isLoading,
    recommendedServices,
    shouldShowServices,
}) => {
    const [lc] = useLanguageContext();
    const setValue = servicesForm.setValue('selectedIds');

    const servicesIds = recommendedServices.map((x) => x.id);
    const selectedIds = servicesForm.state.raw.selectedIds;
    const someRecommendedServiceIsSelected = selectedIds.some((x) =>
        servicesIds.includes(x)
    );

    const ref = useRef(false);

    // select all services on first mount
    useEffect(() => {
        if (
            !someRecommendedServiceIsSelected &&
            recommendedServices.length > 0 &&
            !isLoading &&
            !ref.current
        ) {
            setValue([
                ...selectedIds.filter((x) => !servicesIds.includes(x)),
                ...servicesIds,
            ]);
            ref.current = true;
        }
    }, [
        someRecommendedServiceIsSelected,
        isLoading,
        selectedIds,
        servicesIds,
        setValue,
        recommendedServices.length,
    ]);

    if (isLoading || !shouldShowServices) {
        return null;
    }

    if (recommendedServices.length < 1) {
        return (
            <BilholdInnerViewLayout>
                <BilholdInnerViewLayout>
                    {lc.standardServices.no_recommended_services}
                </BilholdInnerViewLayout>
            </BilholdInnerViewLayout>
        );
    }

    const multichoiceOptions = recommendedServices.map((x) => ({
        value: x.id,
        label: x.name,
        item: x,
    }));

    return (
        <BilholdInnerViewLayout>
            <Column gap="xxs">
                <p>{lc.standardServices.recommended_work}</p>
                <MultiCheckboxInput
                    id={'recommended-services-list'}
                    options={multichoiceOptions}
                    field={'selectedIds'}
                    hideErrorText={true}
                    render={(checkbox, item) => (
                        <RecommendedService
                            key={item.id}
                            checkbox={checkbox}
                            service={item}
                        />
                    )}
                />
            </Column>
        </BilholdInnerViewLayout>
    );
};

interface RecommendedServiceProps {
    service: ServiceViewModel;
    checkbox: JSX.Element;
}

const RecommendedService: React.FC<RecommendedServiceProps> = ({
    service,
    checkbox,
}) => {
    return (
        <div>
            <FlexRow key={service.id}>
                {checkbox}
                {service.serviceType !== 'Waiting' && (
                    <SubServicePrice
                        service={{ type: 'service', service: service }}
                    />
                )}
            </FlexRow>
            <IndentedDescription>{service.description}</IndentedDescription>
        </div>
    );
};

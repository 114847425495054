import no from './no';

export type TextKey = keyof typeof no;

export default no;

/**
 * Format a string with placeholders. Accepts multiple parameters, of which every parameter following the first
 * will be used to replace placeholders, where the first replaces {0}, the second replaces {1} and so on.
 */
export function stringFormat(
    formatString: string,
    ...args: (string | number | undefined)[]
) {
    let value = formatString;
    if (args.length >= 1) {
        for (let i = 0; i < args.length; i += 1) {
            let oldValue: string;

            do {
                oldValue = value;
                value = value.replace(`{${i}}`, args[i]?.toString() ?? '');
            } while (oldValue !== value);
        }
    }
    return value;
}

export const mittDekkhotellDealers = [
    '851', // Møller Bil Tønsberg
    '040', // Møller Bil Asker og Bærum
    '150', // Møller Bil Minde
    '155', // Møller Bil Drotningsvik
    '156', // Møller Bil Åsane
    '550', // Møller Bil Hvam
    '137', // Møller Bil Rud
    '011', // Møller Bil Oslo Vest
    '020', // Møller Bil Ryen
    '108', // Møller Bil Kokstad
    '032', // Møller Bil Kalbakken
    '160', // Møller Bil Nestun
    '089', // Møller Bil Bryn
    '010', // Møller Bil Oslo Vest
];

export const dealersWithDekkhotell = [
    '250', // Møller Bil Elverum
    '370', // Møller Bil Hamar
    '340', // Møller Bil Gran
    '594', // Møller Bil Lillehammer- Skoda
    '083', // Møller Bil Etterstad
    '940', // Møller Bil Ålesund
    '030', // Møller Bil Ensjø
    '795', // Møller Bil Ganddal
    '108', // Møller Bil Kokstad
    '241', // Møller Bil Tynset
];

import { AllowedIcons } from '@moller/design-system';
import Car from 'src/types/Car';
import { OrderedCar } from 'src/types/OrderedCar';
import lang from 'src/utils/lang';
import { formatMileage } from 'src/utils/string/formatMilage';
import { useCarInformation } from './useCarInformation';
import {
    useCarMobilityGuarantee,
    useCarServiceDeals,
} from './useCarServiceDeals';
import { useContract } from './useContract';

interface DetailProps {
    readonly carType: string;
    readonly myCar?: Car;
    readonly orderedCar?: OrderedCar;
}

export const useCorrectDetails = ({
    carType,
    myCar,
    orderedCar,
}: DetailProps) => {
    let technicalDetailsIcons: AllowedIcons[] = ['speed', 'build', 'verified'];
    let technicalDetailsLabels: string[] = [
        'Kilometer',
        'Neste service',
        'Mobilitetsgaranti',
    ];
    let technicalDetailsValues: string[] = ['---', '---', '---'];
    let lastUpdatedMilageValue = 0;

    const { data: details } = useCarInformation(myCar?.vin);
    const deals = useCarServiceDeals(myCar?.vin);
    const { lastUpdatedMilage } = useCarMobilityGuarantee(deals?.deals);
    const { data: orderedContract } = useContract(orderedCar?.contractId);

    const fuelType: string =
        orderedContract?.contract?.car?.drivetrain?.engine?.fuelType !=
        undefined
            ? lang[
                  `contract_fuel_type_${orderedContract?.contract?.car?.drivetrain?.engine?.fuelType}`
              ]
            : '---';
    const fuel_icon =
        details?.fuelType === 'Elektrisk' ? 'ev_station' : 'gas_station';
    switch (carType) {
        case 'MYCAR':
            lastUpdatedMilageValue = +lastUpdatedMilage;
            technicalDetailsIcons = ['speed', 'assignment', fuel_icon];
            technicalDetailsLabels = ['Kilometer', 'Verksted', 'Drivstoff'];
            technicalDetailsValues = [
                `${formatMileage(`${myCar?.mileage}`)} km`,
                'Historikk',
                details?.fuelType ?? '---',
            ];
            return {
                technicalDetailsIcons: technicalDetailsIcons,
                technicalDetailsLabels: technicalDetailsLabels,
                technicalDetailsValues: technicalDetailsValues,
                lastUpdatedMilage: lastUpdatedMilageValue,
            };

        case 'ORDERED':
            technicalDetailsIcons = ['car', 'ev_station', 'assignment'];
            technicalDetailsLabels = ['Årsmodell', 'Drivstoff', 'Ordrenummer'];
            technicalDetailsValues = [
                `${orderedCar?.model?.year}`,
                `${fuelType}`,
                `${orderedCar?.orderNumber}`,
            ];
            return {
                technicalDetailsIcons: technicalDetailsIcons,
                technicalDetailsLabels: technicalDetailsLabels,
                technicalDetailsValues: technicalDetailsValues,
                lastUpdatedMilage: lastUpdatedMilageValue,
            };
        default:
            return {
                technicalDetailsIcons: technicalDetailsIcons,
                technicalDetailsLabels: technicalDetailsLabels,
                technicalDetailsValues: technicalDetailsValues,
                lastUpdatedMilage: lastUpdatedMilageValue,
            };
    }
};

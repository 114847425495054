import { breakpointMobile, Link, TextContainer } from '@moller/design-system';
import lang from 'src/utils/lang';
import { styled } from 'styled-components';
import { Section, SectionTextLayout } from '../components';

const LinkRow = styled.div`
    display: flex;
    gap: var(--moller-spacing-l);
    padding-bottom: var(--moller-spacing-m);

    margin-top: var(--moller-spacing-l);
    flex-wrap: wrap;
    @media screen and (max-width: ${breakpointMobile}) {
        flex-direction: column;
        gap: var(--moller-spacing-s);
    }
`;

export const RoadAssistanceContent = () => {
    return (
        <TextContainer>
            <p>
                Hvis bilen din ikke er kjørbar og du trenger umiddelbar
                assistanse har vi partnere som kan bistå deg. Du kan ringe eller
                bestille veihjelp digitalt selv om du ikke har gyldig
                mobilitetsgaranti, men bileier belastes da for kostnader ved
                eventuell utrykning.
            </p>
            <p>Bestill veihjelp digitalt her:</p>
            <div>
                <LinkRow>
                    <Link href="https://vikingredning.no/audi-mobilitetsgaranti/">
                        <b>Audi</b>
                    </Link>
                    <Link href="https://vikingredning.no/seat-mobilitetsgaranti/">
                        <b>SEAT</b>
                    </Link>
                    <Link href="https://vikingredning.no/seat-mobilitetsgaranti/">
                        <b>CUPRA</b>
                    </Link>
                    <Link href="https://vikingredning.no/skoda-mobilitetsgaranti/">
                        <b>Škoda</b>
                    </Link>
                    <Link href="https://vikingredning.no/volkswagen-mobilitetsgaranti/">
                        <b>Volkswagen</b>
                    </Link>
                    <Link href="https://www.volkswagen.no/no/service/bilulykke-og-bilberging.html">
                        <b>Volkswagen ID</b>
                    </Link>
                </LinkRow>
            </div>
            <p>
                Du kan også ringe på telefonnummer{' '}
                <Link href="tel:91504444">915 04 444</Link>
            </p>
        </TextContainer>
    );
};

export const RoadAssistance = () => {
    return (
        <Section alignRigth={true}>
            <SectionTextLayout title={lang.roadAssistance} wideText={true}>
                <RoadAssistanceContent />
            </SectionTextLayout>
        </Section>
    );
};

import {
    AlertBanner,
    Column,
    GhostButton,
    Headline,
    Modal,
    PrimaryButton,
    Row,
    SecondaryButton,
    TextField,
} from '@moller/design-system';
import { Divider } from '@mui/material';
import { ConfigProvider } from 'booking/lib/config/configContext';
import { LanguageProvider } from 'booking/lib/languages/languageContext';
import { useFlag } from 'feature-toggle';
import { RefObject, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    useDeleteDamageAppraisal,
    useExistingDamage,
    useNewDamage,
    useUpdateDamageAppraisal,
} from 'src/features/damage/utils/useDamageAppraisals';
import { PATH_HOME } from 'src/routing/routes';
import { CenteredPageContent } from 'src/shared/CenteredPageContent';
import Car from 'src/types/Car';
import { withErrorBoundary } from 'src/utils/ErrorBoundary';
import lang from 'src/utils/lang';
import { styled } from 'styled-components';
import { useCarProfile } from '../home/queries/useCarProfile';
import DamageCar from './components/DamageCar';
import DamageDealer from './components/DamageDealer';
import DamageDetails from './components/DamageDetails';
import DamagePayment from './components/DamagePayment';
import DamageSummary from './components/DamageSummary';
import DamageAppraisal from './utils/DamageAppraisal';
import { hasValidImages, hasValidPaymentInfo } from './utils/damageValidation';
import { useGetMedia } from './utils/useMedia';

export const SectionTitle = styled.p`
    margin-top: 0;
    margin-bottom: 0;
    font-size: large;
`;

export const StyledTextField = styled(TextField)`
    width: 100%;
    max-width: 300px;
`;

function useInitialisedDamageObject() {
    const idFromParam = useParams<{ id?: string }>()?.id;
    const { data: initialDamage, isError: initialError } = useNewDamage();
    const { data: existingDamage, isError: existingError } = useExistingDamage(
        idFromParam ? parseInt(idFromParam) : undefined
    );
    const [damage, setDamage] = useState<DamageAppraisal | undefined>();
    const isError = initialError || existingError;

    useEffect(() => {
        if (!existingDamage && initialDamage) {
            setDamage(initialDamage);
        } else {
            setDamage(existingDamage);
        }
    }, [existingDamage, initialDamage]);
    return { damage, isError };
}

const DamageAppraisalIndex = () => {
    const { damage, isError } = useInitialisedDamageObject();
    const navigate = useNavigate();
    useEffect(() => {
        if (damage?.status === 'SENT') {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
    }, [damage?.status]);

    return (
        <ConfigProvider
            // TODO: Denne er KUN nødvendig for å få satt countryCode i useGetDealers.
            //       Burde heller sende den som parameter?
            config={{
                apiLocaleId: 'no',
                bookingParameters: { serviceIds: [], categories: [] },
                defaultApiLocale: 'no',
                searchableDealerNumbers: [],
            }}
        >
            <LanguageProvider languageId="no">
                <CenteredPageContent>
                    {damage?.status === 'SENT' ? (
                        <Column gap="s">
                            <DamageSummary damage={damage} />
                            <PrimaryButton onClick={() => navigate(PATH_HOME)}>
                                {lang.toFrontpage}
                            </PrimaryButton>
                        </Column>
                    ) : damage ? (
                        <DamageAppraisalView damage={damage} />
                    ) : isError ? (
                        <AlertBanner
                            type={'error'}
                            message={'Det har skjedd noe muffens'}
                        />
                    ) : (
                        <></>
                    )}
                </CenteredPageContent>
            </LanguageProvider>
        </ConfigProvider>
    );
};

export const NextStepButton = ({
    disabled,
    setStepDone,
}: {
    disabled: boolean;
    setStepDone: (value: boolean) => void;
}) => {
    return (
        <Row horizontalAlign="end">
            <PrimaryButton
                disabled={disabled}
                onClick={() => setStepDone(true)}
            >
                {lang.next}
            </PrimaryButton>
        </Row>
    );
};

/**
 * Like `useState` but will scroll the next step into view when the state is updated to "done"
 */
function useStepDoneState(
    initialState: boolean,
    nextStepRef: RefObject<HTMLElement>
) {
    const [done, setDone] = useState(initialState);
    useEffect(() => {
        if (done) {
            nextStepRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }, [done, nextStepRef]);

    return [done, setDone] as const;
}

const DamageAppraisalView = ({ damage }: { damage: DamageAppraisal }) => {
    const { data } = useCarProfile();
    const cars = data?.existingCars;
    const navigate = useNavigate();
    const [isShowingModal, setIsShowingModal] = useState(false);
    const [car, setCar] = useState<Car | undefined>(() =>
        cars?.find((x) => x.vin === damage.vin)
    );
    const {
        mutate: updateDamage,
        isError: isErrorOnUpdate,
        isLoading: isUpdating,
    } = useUpdateDamageAppraisal(damage.id);
    const {
        mutate: deleteDamage,
        isError: isDeleteError,
        isLoading: isDeleting,
    } = useDeleteDamageAppraisal();

    const images = useGetMedia(damage.id);
    const validDescriptionStep =
        damage.vin?.length > 1 &&
        !!damage.description &&
        hasValidImages(images.data);

    const paymentHeadingRef = useRef<HTMLHeadingElement>(null);
    const dealerHeadingRef = useRef<HTMLHeadingElement>(null);
    const summaryHeadingRef = useRef<HTMLHeadingElement>(null);

    const [detailsStepDone, setDetailsStepDone] = useStepDoneState(
        validDescriptionStep,
        paymentHeadingRef
    );
    const [paymentStepDone, setPaymentStepDone] = useStepDoneState(
        validDescriptionStep && hasValidPaymentInfo(damage),
        dealerHeadingRef
    );
    const [dealerStepDone, setDealerStepDone] = useStepDoneState(
        validDescriptionStep &&
            hasValidPaymentInfo(damage) &&
            !!damage.dealerId,
        summaryHeadingRef
    );

    const allStepsDone = detailsStepDone && paymentStepDone && dealerStepDone;
    const showHolidayInfo = useFlag('showHolidayInfo');

    return (
        <Column gap="l">
            <Headline>{lang.dmgAppraiserTitle}</Headline>
            <Divider />
            {showHolidayInfo && (
                <AlertBanner
                    type={'info'}
                    message={
                        'Det kan være begrenset kapasitet hos enkelte skadesentere grunnet ferie. Det kan derfor ta lenger tid før du får svar.'
                    }
                />
            )}
            <DamageCar setCar={setCar} cars={cars} damage={damage} />
            <Divider />
            <DamageDetails
                damage={damage}
                detailsStepDone={detailsStepDone}
                setDetailsStepDone={setDetailsStepDone}
                car={car}
            />
            <Divider />
            <DamagePayment
                damage={damage}
                detailsStepDone={detailsStepDone}
                paymentStepDone={paymentStepDone}
                setPaymentStepDone={setPaymentStepDone}
                headingRef={paymentHeadingRef}
            />
            <Divider />
            <DamageDealer
                car={car}
                damage={damage}
                paymentStepDone={paymentStepDone}
                dealerStepDone={dealerStepDone}
                setDealerStepDone={setDealerStepDone}
                headingRef={dealerHeadingRef}
            />
            <Divider />
            {allStepsDone && (
                <DamageSummary damage={damage} headingRef={summaryHeadingRef} />
            )}
            <>
                <Row horizontalAlign="spaceBetween">
                    <GhostButton
                        onClick={() => {
                            if (!damage?.id) {
                                navigate(PATH_HOME);
                            } else {
                                setIsShowingModal(true);
                            }
                        }}
                    >
                        {lang.cancel}
                    </GhostButton>
                    {allStepsDone && (
                        <PrimaryButton
                            onClick={() => updateDamage({ status: 'READY' })}
                            loading={{
                                isLoading:
                                    isUpdating || damage.status === 'READY',
                                loadingText: 'Sender...',
                            }}
                        >
                            {lang.send}
                        </PrimaryButton>
                    )}
                </Row>
                {isErrorOnUpdate && (
                    <AlertBanner
                        type="error"
                        message="Det skjedde dessverre en feil. Prøv gjerne å send inn taksten på nytt."
                    />
                )}
                <Modal
                    isOpen={isShowingModal}
                    handleClose={() => setIsShowingModal(false)}
                    action={
                        <>
                            <PrimaryButton
                                onClick={() => {
                                    setIsShowingModal(false);
                                    navigate(PATH_HOME);
                                }}
                            >
                                {lang.save}
                            </PrimaryButton>
                            <SecondaryButton
                                loading={{
                                    isLoading: isDeleting,
                                    loadingText: 'Sletter...',
                                }}
                                onClick={() => {
                                    deleteDamage(damage.id, {
                                        onSuccess: () => {
                                            navigate(PATH_HOME);
                                            setIsShowingModal(false);
                                        },
                                    });
                                }}
                            >
                                {lang.delete_report}
                            </SecondaryButton>
                        </>
                    }
                >
                    <Column gap="s">
                        Du kan trykke på lagre om du vil fortsette å fylle ut
                        taksten senere. Taksten finnes på bilsiden til bilen som
                        er valgt.
                        {isDeleteError && (
                            <AlertBanner
                                type="error"
                                message="Det skjedde dessverre en feil. Prøv gjerne å slett taksten på nytt."
                            />
                        )}
                    </Column>
                </Modal>
            </>
        </Column>
    );
};

const StyledAlertBanner = styled(AlertBanner)`
    margin: var(--moller-spacing-l) var(--moller-spacing-s);
`;

const fallback = (retry: () => void) => (
    <StyledAlertBanner
        type="error"
        message="Vi greide dessverre ikke å vise siden for digital skadetakst."
        action={{ label: 'Prøv igjen', onClick: retry }}
    />
);

const DamageAppraisalIndexWithErrorBoundary = withErrorBoundary(
    'Damage',
    DamageAppraisalIndex,
    { fallback }
);

export default DamageAppraisalIndexWithErrorBoundary;

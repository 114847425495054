import { datadogRum } from '@datadog/browser-rum';
import { useQuery } from '@tanstack/react-query';
import { useFetchersContext } from 'external-apis';
import { components } from 'external-apis/src/schema/port';
import { DELETED_BOOKING } from 'src/constants';

export type BookingViewModel = components['schemas']['BookingViewModel'];
export type Dealer = components['schemas']['Dealer'];

const useFindBookings = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.port.fetcher.path('/bookings').method('get').create();
};

async function getBookings(findBookings: ReturnType<typeof useFindBookings>) {
    const deletedBooking = localStorage.getItem(DELETED_BOOKING);
    const result = await findBookings({});
    if (!Array.isArray(result.data)) {
        datadogRum.addError(
            'Appointments data is not an array. The app would have crashed.'
        );
        return [];
    }
    return result.data.filter(
        (booking) =>
            !isDeletedAppointment(
                booking.startTime,
                booking.vin,
                booking.dealer.id,
                deletedBooking
            )
    );
}

export const appointmentsQueryKey = 'appointments';
export const useAppointments = () => {
    const findBookings = useFindBookings();
    return useQuery<BookingViewModel[], Error>({
        queryKey: [appointmentsQueryKey],
        queryFn: () => getBookings(findBookings),
    });
};

// Hack to fix problem with sync in Genus. Should be deleted when we are 100% in Dynamics
function isDeletedAppointment(
    startTime: string,
    vin: string,
    dealer: string,
    deletedBooking: string | null
) {
    try {
        if (!deletedBooking) {
            return false;
        }
        const parsedItem = JSON.parse(deletedBooking) as {
            startTime: string;
            dealer: string;
            vin: string;
        };
        if (!startTime || !dealer || !vin) {
            return false;
        }
        return (
            startTime === parsedItem.startTime &&
            vin === parsedItem.vin &&
            dealer === parsedItem.dealer
        );
    } catch (e) {
        return false;
    }
}

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import Message from 'src/features/contact/types/Message';
import {
    default as IThread,
    default as MessageThread,
} from 'src/features/contact/types/MessageThread';
import { getUuid } from 'src/utils/auth/authenticationUtils';
import getCurrentEnvironment, {
    getCurrentEnvironmentName,
} from 'src/utils/environment/getCurrentEnvironment';
import { GET_MESSAGE_THREADS } from 'src/utils/http/endpoints';
import get from 'src/utils/http/get';
import httpDelete from 'src/utils/http/httpDelete';
import post from 'src/utils/http/post';

export const useMessageThreads = () => {
    const { messageCenterUrl } = getCurrentEnvironment();
    const { uuid } = getUuid();

    return useQuery({
        queryKey: ['messageThreads', uuid],
        queryFn: () =>
            get<MessageThread[]>(`${messageCenterUrl}/${GET_MESSAGE_THREADS}`, {
                axiosConfig: { headers: { participantId: uuid } },
            }).then((response) => response.data),
        enabled: !!uuid,
        staleTime: 0, // Want messages to be updated as soon as possible
    });
};

export const useMessageThread = (threadId?: string) => {
    const { messageCenterUrl } = getCurrentEnvironment();
    const { uuid } = getUuid();

    return useQuery({
        queryKey: ['messageThread', threadId],
        queryFn: () =>
            get<IThread>(
                `${messageCenterUrl}/${GET_MESSAGE_THREADS}/${threadId}`,
                {
                    axiosConfig: { headers: { participantId: uuid } },
                }
            ).then((response) => response.data),
        enabled: !!uuid && !!threadId,
    });
};

export const useMessagesInThread = (threadId?: string) => {
    const { messageCenterUrl } = getCurrentEnvironment();
    const { uuid } = getUuid();

    return useQuery({
        queryKey: ['messagesInThread', threadId],
        staleTime: 0,
        cacheTime: 0,
        queryFn: () =>
            get<Message[]>(`${messageCenterUrl}/threads/${threadId}/messages`, {
                axiosConfig: {
                    headers: { participantId: uuid },
                    params: { type: 'IN_APP' },
                },
            }).then((response) => response.data),
        enabled: !!uuid && !!threadId,
    });
};

export const usePostMessage = (
    threadId: string,
    name: string,
    onSuccess: () => void
) => {
    const { messageCenterUrl } = getCurrentEnvironment();
    const { uuid } = getUuid();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (content: string) =>
            post(`${messageCenterUrl}/threads/${threadId}`, [
                {
                    source: 'BILHOLD',
                    type: 'IN_APP',
                    sender: {
                        senderId: uuid,
                        name,
                        internal: false,
                    },
                    content,
                },
            ]),
        onSuccess: () => {
            void queryClient.invalidateQueries(['messagesInThread', threadId]);
            onSuccess();
        },
    });
};

interface ChatData {
    data: {
        threadId: string;
    };
}

interface MessageData {
    content: string;
    registrationNumber: string;
}

export const useNewChatThread = (name: string, externalId: number) => {
    const { messageCenterUrl } = getCurrentEnvironment();
    const { uuid } = getUuid();
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const url = `${messageCenterUrl}/threads/v2`;

    const env = getCurrentEnvironmentName();
    const targetParticipant = env === 'prod' ? 'G006' : 'G050';

    return useMutation<ChatData, Error, MessageData>({
        mutationFn: (messageData) =>
            post(url, {
                thread: {
                    title: messageData.registrationNumber,
                    subtitle: 'Test subtitle',
                    externalId,
                    category: 'CHAT',
                    participants: [
                        {
                            participantId: uuid,
                            name,
                            seen: false,
                            internal: false,
                            deleted: false,
                        },
                        {
                            participantId: targetParticipant,
                            name: 'Bilhold',
                            seen: false,
                            internal: true,
                            deleted: false,
                        },
                    ],
                },
                messages: [
                    {
                        source: 'BILHOLD',
                        type: 'IN_APP',
                        sender: {
                            senderId: uuid,
                            name: name,
                            internal: false,
                        },
                        content: messageData.content,
                    },
                ],
            }),
        onSuccess: async (data: ChatData) => {
            await queryClient.invalidateQueries(['messagesInThread']);

            navigate(`/messages/messages-contact/thread/${data.data.threadId}`);
        },
    });
};

export const useDeleteMessage = (threadId: string, onSuccess: () => void) => {
    const { messageCenterUrl } = getCurrentEnvironment();
    const { uuid } = getUuid();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: () =>
            httpDelete(`${messageCenterUrl}/threads/${threadId}`, {
                axiosConfig: { headers: { participantId: uuid } },
            }),

        onSuccess: () => {
            void queryClient.invalidateQueries(['messagesInThread', threadId]);
            void queryClient.invalidateQueries(['messageThreads']);
            onSuccess();
        },
    });
};

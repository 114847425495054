enum Brand {
    AUDI = 'AUDI',
    VW = 'VW',
    SKODA = 'SKODA',
    NYTTE = 'NYTTE',
    SEAT = 'SEAT',
    CUPRA = 'CUPRA',
    UNKNOWN = 'UNKNOWN',
    OTHER = 'OTHER',
}

export default Brand;

//TODO brandMapper methods can be removed when no more brand specific api calls goes to CarCare(Old bilhold backend)
//Brand Enum values should also be updated to reflect values from new backend
export const brandMapper = (brand?: string | null): Brand => {
    const lowerCaseBrand = brand?.toLowerCase();

    switch (lowerCaseBrand) {
        case 'vw':
        case 'volkswagen':
            return Brand.VW;
        case 'audi':
            return Brand.AUDI;
        case 'skoda':
            return Brand.SKODA;
        case 'nytte':
        case 'volkswagencommercialvehicles':
            return Brand.NYTTE;
        case 'seat':
            return Brand.SEAT;
        case 'cupra':
            return Brand.CUPRA;
        case 'other':
            return Brand.OTHER;
        default:
            return Brand.UNKNOWN;
    }
};

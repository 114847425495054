import { PrimaryButton, TextContainer } from '@moller/design-system';
import HtmlReactParser from 'html-react-parser';
import { useState } from 'react';
import ConfirmWrapper from 'src/features/add-car/components/confirm-wrapper/ConfirmWrapper';
import AddCarMethod from 'src/features/add-car/utils/AddCarMethod';
import userReasons from 'src/features/add-car/utils/userReasons';
import { AddCarRelationReason } from 'src/features/home/queries/useAddCarFunctions';
import CarRelation from 'src/utils/enums/CarRelation';
import lang, { TextKey } from 'src/utils/lang';

interface UserProps {
    readonly reasons: AddCarRelationReason[];
    readonly method?: AddCarMethod;
    readonly addCarFunction: (relationType: CarRelation) => void;
    readonly addCarError: string;
    readonly isLoading: boolean;
}

const User = ({
    reasons,
    method,
    addCarFunction,
    addCarError,
    isLoading,
}: UserProps) => {
    const reason = userReasons[reasons[0]];
    const [showRelationContent, setShowRelationContent] = useState(false);

    return (
        <>
            {reasons.length > 0 && (
                <TextContainer>
                    <h2>
                        {reason ? lang[reason.title] : lang.defaultReasonTitle}
                    </h2>
                    <p>
                        {reason
                            ? HtmlReactParser(lang[reason.description])
                            : lang.defaultReasonDescription}
                    </p>
                    {!showRelationContent && (
                        <PrimaryButton
                            onClick={() =>
                                reason?.continue && setShowRelationContent(true)
                            }
                        >
                            {lang[reason?.buttonText as TextKey]}
                        </PrimaryButton>
                    )}
                </TextContainer>
            )}
            {(showRelationContent || !reasons.length) && (
                <ConfirmWrapper
                    onClick={() => addCarFunction(CarRelation.USER)}
                    isLoading={isLoading}
                    errorMessage={addCarError}
                    addCarWithCode={method === AddCarMethod.CODE}
                />
            )}
        </>
    );
};

export default User;

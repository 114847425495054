import {
    formInputs,
    GhostButton,
    InputFieldValidity,
    Row,
} from '@moller/design-system';
import { styled } from 'styled-components';
import { useLanguageContext } from '../../../lib/languages/languageContext';
import { StdServicesForm } from '../useSectionForm';

const MileageButton = styled(GhostButton)`
    margin-top: var(--moller-spacing-xxs);
`;

const { TextField: FormTextField } = formInputs<StdServicesForm>();

type MileageProps = {
    onClick: () => void;
    helperText: string;
    showValidityInvalid: boolean;
    isLoading: boolean;
};
export function Mileage({
    helperText,
    onClick,
    isLoading,
    showValidityInvalid,
}: MileageProps) {
    const [lc] = useLanguageContext();
    const validity: InputFieldValidity = showValidityInvalid
        ? {
              type: 'error',
              message: lc.errors.mileage_validation_required,
          }
        : {
              type: 'none',
          };

    return (
        <Row style={{ gap: `0 var(--moller-spacing-xxs)` }} flexWrap="wrap">
            <FormTextField
                field="mileage"
                label={lc.standardServices.mileage}
                validity={validity}
                helperText={helperText}
                onKeyUp={(ev) => {
                    if (ev.key === 'Enter') {
                        onClick();
                    }
                }}
            />
            <MileageButton
                onClick={onClick}
                loading={{
                    isLoading,
                    loadingText: 'Loading recommended services.',
                }}
            >
                {lc.standardServices.find_recommended_services}
            </MileageButton>
        </Row>
    );
}

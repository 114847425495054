import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import get from 'src/utils/http/get';
import httpDelete from 'src/utils/http/httpDelete';
import post from 'src/utils/http/post';
import DamageAppraisal from './DamageAppraisal';

const DAMAGE_APPRAISER_ENDPOINT = `api/v3/damageAppraiser`;

export const getDamageQueryKey = (damageId?: number | null) =>
    damageId
        ? (['existingDamageAppraisal', damageId] as const)
        : (['newDamageAppraisal'] as const);

export const useNewDamage = () => {
    return useQuery({
        queryFn: () =>
            get<DamageAppraisal>(DAMAGE_APPRAISER_ENDPOINT).then(
                (res) => res.data
            ),
        queryKey: getDamageQueryKey(),
    });
};

export const useExistingDamage = (damageId?: number) => {
    return useQuery({
        queryFn: () =>
            get<DamageAppraisal>(
                `${DAMAGE_APPRAISER_ENDPOINT}/id/${damageId}`
            ).then((res) => res.data),
        queryKey: getDamageQueryKey(damageId),
        enabled: !!damageId,
    });
};

export const useDeleteDamageAppraisal = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (damageId: number | null) =>
            httpDelete(`${DAMAGE_APPRAISER_ENDPOINT}/id/${damageId}`),
        onSuccess: (_, damageId) =>
            void queryClient.invalidateQueries({
                queryKey: getDamageQueryKey(damageId),
            }),
    });
};

export const useUpdateDamageAppraisal = (damageId: number | null) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const isIdInParam = !!useParams().id;
    // This mutation does optimistic updates by updating the cache, see
    // https://tanstack.com/query/v5/docs/react/guides/optimistic-updates
    return useMutation({
        mutationFn: async (damage: Partial<DamageAppraisal>) => {
            const existingDamage =
                await queryClient.ensureQueryData<DamageAppraisal>(
                    getDamageQueryKey(damageId)
                );
            const endpoint = damage.id
                ? `${DAMAGE_APPRAISER_ENDPOINT}/id/${damage.id}`
                : DAMAGE_APPRAISER_ENDPOINT;
            return await post<DamageAppraisal>(endpoint, {
                ...existingDamage,
                ...damage,
            }).then((res) => res.data);
        },
        onMutate: async (damageUpdates) => {
            const queryKey = getDamageQueryKey(damageId);
            // Cancel any outgoing refetches
            // (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({ queryKey: queryKey });

            // Snapshot the previous value
            const existingDamage =
                await queryClient.ensureQueryData<DamageAppraisal>(queryKey);

            // Optimistically update to the new value
            queryClient.setQueryData<DamageAppraisal>(queryKey, (old) => ({
                ...old,
                ...(damageUpdates as DamageAppraisal),
            }));

            // Return a context object with the snapshotted value
            return { existingDamage };
        },
        onSuccess: (result) => {
            // When being assigned an id from the backend, initialise the query cache for that id
            // and navigate to the correct path
            if (result.id && !isIdInParam) {
                queryClient.setQueryData<DamageAppraisal>(
                    getDamageQueryKey(result.id),
                    result
                );
                navigate(result.id?.toString(), { replace: true });
            }
        },
        // If the mutation fails,
        // use the context returned from onMutate to roll back
        onError: (err, vars, context) => {
            queryClient.setQueryData<DamageAppraisal>(
                getDamageQueryKey(damageId),
                context?.existingDamage
            );
        },
        // Always refetch after error or success
        onSettled: () => {
            void queryClient.invalidateQueries({
                queryKey: getDamageQueryKey(damageId),
            });
        },
    });
};

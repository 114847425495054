import { Description } from '@moller/design-system';
import { useState } from 'react';
import { styled } from 'styled-components';
import SentimentIcons from './SentimentIcons';
import SentimentInput from './SentimentInput';

const StyledDescription = styled(Description)`
    margin-top: var(--moller-spacing-l);
    margin-bottom: var(--moller-spacing-m);
    text-align: center;
    font-size: 1rem;
`;

export type Sentiment =
    | 'sentiment_dissatisfied'
    | 'sentiment_neutral'
    | 'sentiment_satisfied'
    | '';

export type SentimentInputType = {
    showInput: boolean;
    sentiment: Sentiment;
};

const FeedbackPrompt = () => {
    const [showRatingIcons, setShowRatingIcons] = useState(true);
    const [sentiment, setSentiment] = useState<Sentiment>('');

    if (!showRatingIcons && !sentiment) {
        return null;
    }

    if (!showRatingIcons && sentiment) {
        return (
            <SentimentInput sentiment={sentiment} setSentiment={setSentiment} />
        );
    }

    return (
        <>
            <StyledDescription>Hva synes du om denne siden?</StyledDescription>
            <SentimentIcons
                setShowRatingIcons={setShowRatingIcons}
                setSentiment={setSentiment}
            />
        </>
    );
};

export default FeedbackPrompt;

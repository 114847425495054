import { fromByteArray } from 'base64-js';
import { saveAs } from 'file-saver';
import getHeaders from 'src/utils/http/utils/headers';
import { isIOSSafari } from 'src/utils/mobile';
import { toServerUrl } from '../http/utils/toServerUrl';

export async function fetchBlob(url: string) {
    const headers = await getHeaders(url, true);
    const res = await fetch(url, { headers });
    const blob = await res.blob();
    return blob;
}

export async function loadImageWithAuth(url: string) {
    const blob = await fetchBlob(url);
    const byteArray = new Uint8Array(await blob.arrayBuffer());
    return `data:${blob.type};base64,${fromByteArray(byteArray)}`;
}

export async function openPdfWithAuth(
    filePath: string,
    fileName?: string,
    adapterId?: string,
    enabledAnnataInvoice?: boolean
) {
    const blobdata = await fetchBlob(
        toServerUrl(filePath, adapterId, enabledAnnataInvoice)
    );
    if (isIOSSafari()) {
        // window.open doesn't work on iOS Safari, but for this particular browser
        // file-saver will open the file in the current window
        saveAs(blobdata, fileName);
    } else {
        // On other browsers file-saver will download the file directly instead of opening it in the browser.
        // Therefore we don't use file-saver when we're not on iOS Safari
        const fileURL = URL.createObjectURL(blobdata);
        window.open(fileURL);
    }
}

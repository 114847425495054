import { useQuery } from '@tanstack/react-query';
import DamageAppraiser from 'src/types/DamageAppraiser';
import get from 'src/utils/http/get';

export const useDamageAppraisers = () => {
    return useQuery({
        queryKey: ['damageAppraiser'],
        queryFn: () =>
            get<DamageAppraiser[]>(`api/v3/damageAppraisers`).then(
                (res) => res.data
            ),
    });
};

import {
    AddCarMethod,
    AddCarRequestRelationReason,
    Brand,
    CarRelationType,
} from 'external-apis/src/types/bilhold';
import OldAddCarMethod from 'src/features/add-car/utils/AddCarMethod';
import CarRelation from '../enums/CarRelation';
import toLowerCaseWithCapitalize from './toLowerCaseWithCapitalize';

const convertBrand = (brand?: Brand) => {
    if (!brand) return 'Ukjent';

    switch (brand) {
        case 'VolkswagenCommercialVehicles':
            return 'Volkswagen';
        case 'Cupra':
            return 'CUPRA';
        case 'Skoda':
            return 'Škoda';
        case 'Other':
            return 'Ukjent';
        default:
            return brand;
    }
};

export const formatCarName = (brand?: Brand, modelName?: string | null) => {
    return `${convertBrand(brand)} ${toLowerCaseWithCapitalize(
        modelName ?? ''
    )}`;
};

export const convertCarRelationType = (carRelation: CarRelationType) => {
    switch (carRelation) {
        case 'CompanyCar':
            return CarRelation.COMPANY;
        case 'Leaser':
            return CarRelation.LEASER;
        case 'Owner':
            return CarRelation.OWNER;
        case 'User':
            return CarRelation.USER;
    }
};

export const convertAddCarMethod = (method?: AddCarMethod) => {
    switch (method) {
        case 'Code':
            return OldAddCarMethod.CODE;
        case 'Manual':
            return OldAddCarMethod.MANUAL;
        case 'None':
        default:
            OldAddCarMethod.NONE;
    }
};

// convert add car reason as a mean to update the frontend in small steps
export const convertAddCarReason = (reason: AddCarRequestRelationReason) => {
    switch (reason) {
        case 'OwnerNotExistingInMvr':
            return 'OWNER_NOT_EXISTING_IN_MVR';
        case 'ProprietorMobileNotValid':
            return 'PROPRIETOR_MOBILE_NOT_VALID';
        case 'ProprietorNotExisting':
            return 'PROPRIETOR_NOT_EXISTING';
        case 'ProprietorNotValid':
            return 'PROPRIETOR_NOT_VALID';
        case 'ProprietorNotValidLeaser':
            return 'PROPRIETOR_NOT_VALID_LEASER';
        case 'ProprietorNotValidOwner':
            return 'PROPRIETOR_NOT_VALID_OWNER';
        case 'RelationAlreadyExisting':
            return 'RELATION_ALREADY_EXISTING';
        case 'RelationAlreadyExistingOrganization':
            return 'RELATION_ALREADY_EXISTING_ORGANIZATION';
        case 'RelationAlreadyExistingOrganizationExt':
            return 'RELATION_ALREADY_EXISTING_ORGANIZATION_EXT';
        case 'RelationAlreadyExistingPrivate':
            return 'RELATION_ALREADY_EXISTING_PRIVATE';
    }
};

import {
    ActionChip,
    Column,
    InfoCard,
    LeadText,
    Subtitle,
    TextField,
} from '@moller/design-system';
import { useEffect, useState } from 'react';
import { DealerType } from 'src/constants';
import {
    PreferredDealer,
    useDeletePreferredDealer,
    useEditPreferredDealer,
} from 'src/features/home/queries/usePreferredDealer';
import Brand from 'src/types/Brand';
import Dealer from 'src/types/Dealer';
import { useNamedQueryParams } from 'src/utils/browser/useNamedQueryParams';
import lang from 'src/utils/lang';
import { styled } from 'styled-components';
import { useClientLocation } from './utils/useClientLocation';
import { useDealers } from './utils/useDealers';

const StyledContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: var(--moller-spacing-xxl) auto;
    width: 100%;
    max-width: 600px;
`;

const StyledCard = styled(InfoCard)`
    margin-bottom: var(--moller-spacing-s);
    background-color: var(--moller-color-background);
`;

const StyledLeadText = styled(LeadText)`
    margin: var(--moller-spacing-s) 0;
`;

const StyledSmallLeadText = styled(LeadText)`
    font-size: larger;
    margin: var(--moller-spacing-s) 0;
`;

const SelectPreferredDealer = ({
    brand,
    handleClose,
    preferredDealer,
}: {
    brand: Brand;
    handleClose: () => void;
    preferredDealer?: PreferredDealer;
}) => {
    const {
        mutate: editPreferredDealer,
        isLoading: isUpdatingDealer,
        isSuccess: hasUpdatedDealer,
    } = useEditPreferredDealer(brand);
    const {
        mutate: deletePreferredDealer,
        isLoading: isDeletingDealer,
        isSuccess: hasDeletedDealer,
    } = useDeletePreferredDealer(brand);
    const location = void useClientLocation();
    const dealerGroup = useNamedQueryParams('dealerGroup')[0] || '';
    const [search, setSearch] = useState<string>('');
    const [searchResultsList, setSearchResultsList] = useState<Dealer[]>([]);
    const [newPreferredDealerId, setNewPreferredDealerId] = useState<
        string | undefined
    >(preferredDealer?.dealerId);

    const { dealers } = useDealers(
        brand,
        location,
        dealerGroup,
        DealerType.SERVICE_CENTER
    );

    useEffect(() => {
        if (search && dealers) {
            const filteredDealers = dealers.filter((dealer) => {
                const dealerName = dealer.name.toLowerCase();
                const dealerAddress = dealer.location.address.toLowerCase();
                const searchQuery = search.toLowerCase();

                return (
                    dealerName.includes(searchQuery) ||
                    dealerAddress.includes(searchQuery)
                );
            });

            setSearchResultsList(filteredDealers);
        } else if (dealers) {
            setSearchResultsList(dealers);
        }
    }, [search, dealers]);

    useEffect(() => {
        if (hasUpdatedDealer || hasDeletedDealer) {
            handleClose();
        }
    }, [hasUpdatedDealer, hasDeletedDealer, handleClose]);

    if (!dealers) {
        return <p>{lang.errorTryAgainLater}</p>;
    }

    return (
        <StyledContainer>
            {preferredDealer && (
                <>
                    <StyledLeadText>
                        <b>Din forhandler</b>
                    </StyledLeadText>
                    <StyledCard>
                        <Column gap={'xs'}>
                            <Column>
                                <Subtitle>
                                    {preferredDealer.dealerName}
                                </Subtitle>
                                <p>{preferredDealer.address}</p>
                            </Column>
                            <ActionChip
                                icon="clear"
                                loading={{
                                    isLoading: isDeletingDealer,
                                    loadingText: 'Fjerner preferert forhandler',
                                }}
                                onClick={() => deletePreferredDealer()}
                                label="Fjern"
                            />
                        </Column>
                    </StyledCard>
                </>
            )}
            <StyledSmallLeadText>
                Velg foretrukket forhandler
            </StyledSmallLeadText>
            <TextField
                id="search-dealer"
                label="Finn forhandler"
                leadingIcon={'search'}
                autoCorrect="off"
                onChange={(e) => setSearch(e.target.value)}
            />
            {searchResultsList.map((dealer) => {
                return (
                    <StyledCard key={dealer.mnetDealerId}>
                        <Column gap={'xxs'}>
                            <Column>
                                <Subtitle>{dealer.name}</Subtitle>
                                <p>{dealer.location.address}</p>
                            </Column>
                            <ActionChip
                                icon="check"
                                loading={{
                                    isLoading:
                                        isUpdatingDealer &&
                                        dealer.mnetDealerId ===
                                            newPreferredDealerId,
                                    loadingText:
                                        'Oppdaterer preferert forhandler',
                                }}
                                onClick={() => {
                                    editPreferredDealer(dealer.mnetDealerId);
                                    setNewPreferredDealerId(
                                        dealer.mnetDealerId
                                    );
                                }}
                                label="Velg"
                            />
                        </Column>
                    </StyledCard>
                );
            })}
        </StyledContainer>
    );
};

export default SelectPreferredDealer;

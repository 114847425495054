import MessageThread from 'src/features/contact/types/MessageThread';
import { PATH_MESSAGES } from 'src/routing/routes';

export const messageIsSeen = (m: MessageThread) => {
    return m?.participants?.find((p) => !p.internal)?.seen;
};

export const getNumberOfUnOpenedMessages = (
    messages?: MessageThread[]
): number => {
    if (!Array.isArray(messages)) {
        return 0;
    }

    return messages?.filter((m) => !messageIsSeen(m))?.length;
};

type MessageCategory = 'damage_appraiser' | 'chat' | null;

const getUnreadStatusParam = (messages?: MessageThread[]): MessageCategory => {
    if (!Array.isArray(messages)) {
        return null;
    }

    const unopenedMessages = messages?.filter((m) => !messageIsSeen(m));

    if (unopenedMessages.some((m) => m.category === 'DAMAGE_APPRAISER')) {
        return 'damage_appraiser';
    }

    if (unopenedMessages.some((m) => m.category === 'CHAT')) {
        return 'chat';
    }

    return null;
};

export function getUrlToUnreadMessages(messages?: MessageThread[]): string {
    const unreadStatus = getUnreadStatusParam(messages);

    if (!unreadStatus) {
        return PATH_MESSAGES;
    }

    return `${PATH_MESSAGES}?category=${unreadStatus}`;
}

export function getMessageWithAppointmentId(
    messages: MessageThread[] | undefined,
    appointmentIdAsNumber: number
): MessageThread | undefined {
    return messages
        ?.filter((m) => m.category === 'CHAT')
        ?.filter((m) => m.externalId === appointmentIdAsNumber)[0];
}

/*
    Function to be used for making id to connect message to appointmentid. 

    Resulting number is relatively safe, as the id only needs to be unique for the user's appointments.
*/
export function partialAppointmentGuidToSafeNumberId(guid: string): number {
    const partialGuid = guid.replace(/-/g, '').slice(0, 7); // ExternalId is stored as int, meaning maximum number value of 2147483647. 7 digits in hex can be maximum 16777215.

    const uniqueNumber = parseInt(partialGuid, 16); // Converting from hex to int

    return uniqueNumber;
}

import { useQuery } from '@tanstack/react-query';
import DamageAppraiser from 'src/types/DamageAppraiser';
import get from 'src/utils/http/get';

export const useDamageAppraiser = (damageId?: number) => {
    return useQuery({
        queryKey: ['damageAppraiser', damageId],
        queryFn: () =>
            get<DamageAppraiser>(`api/v3/damageAppraiser/id/${damageId}`).then(
                (response) => response.data
            ),
        enabled: !!damageId,
    });
};

import { useQuery } from '@tanstack/react-query';
import proxyGet from 'src/utils/data-mocks/proxyGet';
import getCurrentEnvironment from 'src/utils/environment/getCurrentEnvironment';

export interface MileageResponse {
    vin: string;
    currentMileage: number;
    lastUpdatedMileage: string;
}

export const useMileage = (vin: string) => {
    const { bilholdApiUrl } = getCurrentEnvironment();

    return useQuery({
        queryKey: ['mileage', vin],
        queryFn: () =>
            proxyGet<MileageResponse>(
                `${bilholdApiUrl}/v1/cars/${vin}/mileage`
            ).then((res) => res.data),
    });
};

import { Column } from '@moller/design-system';
import { useFlag } from 'feature-toggle';
import { ChildThemeProvider, getThemeFromDealerName } from 'theming';
import { useGetDealers } from '../../../_api/http/dealers';
import { useAuthContext } from '../../../lib/auth/authContext';
import { useFastTrack } from '../../../lib/useFastTrack';
import { Done } from '../../sectionUtils';
import { ConfirmBookingDependencies } from '../ConfirmBookingSection';
import {
    useAdobeTrackingConfirmBooking,
    useReportConfirmBooking,
} from '../confirmBookingReport';
import { ConfirmBookingDoneInternal } from './ConfirmBookingDoneInternal';

export function ConfirmBookingDone({
    dependencies,
    isBookingRequest,
}: {
    dependencies: Done<ConfirmBookingDependencies>;
    isBookingRequest: boolean;
}) {
    const authScheme = useAuthContext();
    const dealerResponse = useGetDealers();

    const dealerThemeFrom = (dealerNumber: string) =>
        getThemeFromDealerName(
            dealerResponse.data?.find((x) => x.id === dealerNumber)?.name ?? ''
        );
    const dealerNumber = dependencies.chooseDealer.data.dealerNumber;

    const reportingProps = {
        registrationNumber: dependencies.chooseVehicle.data.registrationNumber,
        selectedDealerNumber: dependencies.chooseDealer.data.dealerNumber,
        standardServices: dependencies.standardServices.data.selectedServices,
        reservedTimeSlot: dependencies.chooseTimeSlot.data.reservedTimeSlot,
        additionalServices: dependencies.additionalServices,
        transportServices: dependencies.transportServices,
        mileage: dependencies.standardServices.data.mileage,
    };

    const enabled = useFlag('enable-adobe-tracking');

    const fastTrackType = useFastTrack();
    const isFastTrack = !!fastTrackType.fastTrack;

    useReportConfirmBooking(reportingProps);
    useAdobeTrackingConfirmBooking({
        formName: isFastTrack ? 'Booking Fasttrack' : 'Booking',
        enabled,
        ...reportingProps,
    });
    return (
        <ChildThemeProvider
            theme={dealerThemeFrom(dependencies.chooseDealer.data.dealerNumber)}
        >
            <Column gap="m">
                <ConfirmBookingDoneInternal
                    email={authScheme.user.email}
                    reservedTimeSlot={
                        dependencies.chooseTimeSlot.data.reservedTimeSlot
                    }
                    selectedDealerNumber={
                        dependencies.chooseDealer.data.dealerNumber
                    }
                    registrationNumber={
                        dependencies.chooseVehicle.data.registrationNumber
                    }
                    phone={authScheme.user.phone}
                    mileage={dependencies.standardServices.data.mileage}
                    isBookingRequest={isBookingRequest}
                    dealerNumber={dealerNumber}
                />
            </Column>
        </ChildThemeProvider>
    );
}

import { ServiceViewModel } from 'external-apis/src/types/port';
import React from 'react';
import { styled } from 'styled-components';
import { useLanguageContext } from '../../lib/languages/languageContext';
import { getSummarizedPrice } from '../../sections/standard-services/price/util';
import { BoldText } from '../BoldText';

type ServicePriceSummarizedProps = {
    services: ServiceViewModel[];
};

export const ServicePriceSummary: React.FC<ServicePriceSummarizedProps> = ({
    services,
}) => {
    const [lc] = useLanguageContext();

    const sum = getSummarizedPrice(services);
    if (sum.type === 'PRICE_UNKNOWN') {
        return <BoldText>{lc.prices.price_calculated_at_dealer}</BoldText>;
    }
    if (sum.price === 0) {
        return <BoldText>{lc.prices.free}</BoldText>;
    }
    const pricePrefix =
        sum.type === 'PRICE_FROM' ? lc.components.price_from : '';
    return (
        <BoldText>
            {pricePrefix} {sum.price} {lc.prices.currency}
        </BoldText>
    );
};

export const TotalPriceContainer = styled.div`
    display: flex;
    justify-content: end;
    gap: var(--moller-spacing-xxs);
`;

import {
    AlertBanner,
    Column,
    InfoCard,
    PrimaryButton,
    RadioButton,
    Row,
    TextContainer,
} from '@moller/design-system';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { DealerType } from 'src/constants';
import {
    HeadingPrizeCard,
    TextSmallThin,
    TextThin,
} from 'src/features/competition/textComponents/TDFText';
import {
    UpdateStartPrizeDataPayload,
    useUpdateCompetitionStartPrize,
} from 'src/features/competition/utils/useUpdateCompetitionStartPrize';
import { useClientLocation } from 'src/features/user-profile/my-preferred-dealer/utils/useClientLocation';
import { useDealers } from 'src/features/user-profile/my-preferred-dealer/utils/useDealers';
import Brand from 'src/types/Brand';
import { useNamedQueryParams } from 'src/utils/browser/useNamedQueryParams';
import { styled } from 'styled-components';
import bottleImage from '../../images/TDT_Cycling_Bottle.jpg';
import capImage from '../../images/TDT_Cycling_Cap.jpg';
import { ConfirmModal } from '../ConfirmModal';

const StyledList = styled.ul`
    padding: 0 var(--moller-spacing-s);
    width: 95%;
`;
const PrizesCard = styled(InfoCard)`
    background-color: white;
    color: black;
    margin: var(--moller-spacing-s) 0;
    border: 0;
`;
const TDTRadioButton = styled(RadioButton)`
    font-size: 1rem;
    align-items: center;
    color: black;
`;

const TDTPicture = styled.img`
    width: 70px;
    align-content: center;
`;
const StyledMenuItem = styled(MenuItem)`
    font-size: 0.75rem;
`;

const orderBeforeDate = new Date(2024, 5, 24);

interface Props {
    dealer: string | undefined;
    prize: string | undefined;
    hasWon: boolean | undefined;
}

export const StartPrize = ({ dealer, prize, hasWon }: Props) => {
    const today = new Date();
    const canOrder = today < orderBeforeDate;
    const location = void useClientLocation();

    const [startPrizeCheckbox, setStartPrizeCheckbox] = useState<
        string | undefined
    >(undefined);
    const [newPreferredDealerId, setNewPreferredDealerId] = useState<
        string | undefined
    >(undefined);
    const [isConfirmPrizeModalOpen, setIsConfirmPrizeModalOpen] =
        useState<boolean>(false);

    const hasOrdered = dealer && prize;
    const dealerGroup = useNamedQueryParams('dealerGroup')[0] || '';
    const { dealers } = useDealers(
        undefined,
        location,
        dealerGroup,
        DealerType.SALES
    );

    const skodaDealer = dealers?.filter((dealer) =>
        dealer?.saleCertifications?.includes(Brand.SKODA)
    );

    const optionsDealer = skodaDealer
        ?.map((dealer) => ({
            label: dealer.name,
            value: dealer.mnetDealerId,
        }))
        .concat([
            {
                label: 'Vil ikke ha premie',
                value: '0',
            },
        ]);

    useEffect(() => {
        if (dealer || prize) {
            setNewPreferredDealerId(dealer);
            setStartPrizeCheckbox(prize);
        }
    }, [dealer, prize]);

    const onUpdate = (event: SelectChangeEvent) => {
        setNewPreferredDealerId(event.target.value);
    };

    const {
        mutate: updatePrize,
        isLoading: isUpdating,
        isError,
    } = useUpdateCompetitionStartPrize();

    const onOrder = () => {
        const startPrize =
            newPreferredDealerId === '0'
                ? 'vil ikke ha premie'
                : startPrizeCheckbox;
        const changedPrizeData: UpdateStartPrizeDataPayload = {
            dealer: newPreferredDealerId,
            prize: startPrize,
        };
        updatePrize(changedPrizeData);
    };

    if (hasWon && !hasOrdered) {
        const disableButton = isUpdating || !canOrder;
        const disableOrderButton =
            ((!newPreferredDealerId || !startPrizeCheckbox) &&
                newPreferredDealerId !== '0') ||
            disableButton;

        return (
            <PrizesCard>
                {isError && (
                    <AlertBanner
                        type="error"
                        message={
                            'Det oppstod en feil når vi prøvde å lagre valget ditt. Prøv igjen senere.'
                        }
                    />
                )}
                <Column horizontalAlign={'start'} gap={'s'}>
                    <TextContainer>
                        <HeadingPrizeCard>Oppstartspremie</HeadingPrizeCard>
                        {!canOrder ? (
                            <TextSmallThin>
                                Beklager, du har gått glipp av fristen for å
                                bestille premie.
                            </TextSmallThin>
                        ) : (
                            <TextSmallThin>
                                Du var blant de første 1000 som fullførte første
                                etappe i touren. Velg den premien du ønsker:
                            </TextSmallThin>
                        )}
                    </TextContainer>
                    <Row
                        horizontalAlign={'spaceBetween'}
                        verticalAlign={'center'}
                        style={{ width: '100%' }}
                    >
                        <TDTRadioButton
                            disabled={disableButton}
                            label={'Škoda sykkelflaske'}
                            id={'Škoda sykkelflaske'}
                            value={startPrizeCheckbox === 'Škoda sykkelflaske'}
                            onChange={() =>
                                setStartPrizeCheckbox('Škoda sykkelflaske')
                            }
                        />
                        <TDTPicture
                            src={bottleImage}
                            alt={'Škoda sykkelflaske'}
                        />
                    </Row>
                    <Row
                        horizontalAlign={'spaceBetween'}
                        verticalAlign={'center'}
                        style={{ width: '100%' }}
                    >
                        <TDTRadioButton
                            disabled={disableButton}
                            label={'Škoda sykkelcaps'}
                            id={'Škoda sykkelcaps'}
                            value={startPrizeCheckbox === 'Škoda sykkelcaps'}
                            onChange={() =>
                                setStartPrizeCheckbox('Škoda sykkelcaps')
                            }
                        />
                        <TDTPicture src={capImage} alt={'Škoda sykkelcaps'} />
                    </Row>
                    <Column
                        gap={'xxs'}
                        verticalAlign={'start'}
                        horizontalAlign={'start'}
                        style={{ width: '100%' }}
                    >
                        <TextSmallThin>
                            Premien må hentes hos din foretrukne bilforhandler.
                            Velg her:
                        </TextSmallThin>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                                Forhandler
                            </InputLabel>
                            <Select
                                disabled={disableButton}
                                id="preferredDealer"
                                label="Forhandler"
                                onChange={onUpdate}
                                value={
                                    newPreferredDealerId
                                        ? newPreferredDealerId
                                        : ''
                                }
                            >
                                {optionsDealer?.map((dealer) => (
                                    <StyledMenuItem
                                        key={dealer.value}
                                        value={dealer.value}
                                    >
                                        {dealer.label}
                                    </StyledMenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <PrimaryButton
                            onClick={() => setIsConfirmPrizeModalOpen(true)}
                            disabled={disableOrderButton}
                            style={{ alignSelf: 'center' }}
                        >
                            <TextThin>Lagre valg</TextThin>
                        </PrimaryButton>
                        <TextSmallThin style={{ alignSelf: 'center' }}>
                            Du må lagre valget ditt innen 23.06.2024
                        </TextSmallThin>
                    </Column>
                </Column>
                <ConfirmModal
                    isOpen={isConfirmPrizeModalOpen}
                    setIsConfirmPrizeModalOpen={setIsConfirmPrizeModalOpen}
                    onOrder={onOrder}
                    isUpdating={isUpdating}
                />
            </PrizesCard>
        );
    }

    if (hasOrdered) {
        let prize = undefined;
        if (startPrizeCheckbox === 'Škoda sykkelflaske') {
            prize = bottleImage;
        } else if (startPrizeCheckbox === 'Škoda sykkelcaps') {
            prize = capImage;
        }
        return (
            <PrizesCard>
                <Column horizontalAlign={'start'} gap={'s'}>
                    <TextContainer>
                        <HeadingPrizeCard>Oppstartspremie</HeadingPrizeCard>
                        <TextSmallThin>Du valgte:</TextSmallThin>
                    </TextContainer>
                    <Row
                        horizontalAlign={'spaceBetween'}
                        verticalAlign={'center'}
                        style={{ width: '100%' }}
                    >
                        {prize ? (
                            <>
                                <TextThin>{startPrizeCheckbox}</TextThin>
                                <TDTPicture
                                    src={prize}
                                    alt={startPrizeCheckbox}
                                />
                            </>
                        ) : (
                            <TextThin>
                                Du har valgt å ikke få en premie
                            </TextThin>
                        )}
                    </Row>
                    {prize && (
                        <TextSmallThin>
                            Premien kan hentes hos din valgte bilforhandler i
                            tidsrommet 1. juli til 21. juli. Hent premien din
                            hos:
                        </TextSmallThin>
                    )}
                    <TextThin>
                        {skodaDealer &&
                            skodaDealer.find(
                                (dealer) =>
                                    dealer.mnetDealerId === newPreferredDealerId
                            )?.name}
                    </TextThin>
                </Column>
            </PrizesCard>
        );
    }

    return (
        <PrizesCard>
            <Column horizontalAlign={'start'} gap={'s'}>
                <TextContainer>
                    <HeadingPrizeCard>Oppstartspremie</HeadingPrizeCard>
                    <TextSmallThin>
                        Vær blant de første 1000 som fullfører første etappe i
                        touren og velg én av følgende premier:
                    </TextSmallThin>
                </TextContainer>
                <StyledList>
                    <li>
                        <Row
                            horizontalAlign={'spaceBetween'}
                            verticalAlign={'center'}
                            style={{ width: '100%' }}
                        >
                            <TextThin>Škoda sykkelflaske</TextThin>
                            <TDTPicture
                                src={bottleImage}
                                alt={'Škoda bottleImage'}
                            />
                        </Row>
                    </li>
                    <li>
                        <Row
                            horizontalAlign={'spaceBetween'}
                            verticalAlign={'center'}
                            style={{ width: '100%' }}
                        >
                            <TextThin>Škoda sykkelcaps</TextThin>
                            <TDTPicture src={capImage} alt={'Škoda capImage'} />
                        </Row>
                    </li>
                </StyledList>
                <Column
                    horizontalAlign={'center'}
                    verticalAlign={'center'}
                    style={{ width: '100%' }}
                >
                    <TextSmallThin>
                        Du må fullføre første etappe innen 23. juni for å vinne
                        premie
                    </TextSmallThin>
                </Column>
            </Column>
        </PrizesCard>
    );
};

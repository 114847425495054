import '@moller/design-system/fonts/audi';
import '@moller/design-system/fonts/autoria';
import '@moller/design-system/fonts/bilhold';
import '@moller/design-system/fonts/cupra';
import '@moller/design-system/fonts/dahles';
import '@moller/design-system/fonts/gumpen';
import '@moller/design-system/fonts/moller';
import '@moller/design-system/fonts/skoda';
import '@moller/design-system/fonts/VW';
import { LogoBrand } from '@moller/design-system/foundation/logos/Logo.js';
import { audi } from '@moller/gnist-themes/themes/audi.css.js';
import { autoria } from '@moller/gnist-themes/themes/autoria.css.js';
import { bilholdLight } from '@moller/gnist-themes/themes/bilholdLight.css.js';
import { cupra } from '@moller/gnist-themes/themes/cupra.css.js';
import { dahles } from '@moller/gnist-themes/themes/dahles.css.js';
import { gumpen } from '@moller/gnist-themes/themes/gumpen.css.js';
import { mollerBil } from '@moller/gnist-themes/themes/mollerBil.css.js';
import { skodaDark } from '@moller/gnist-themes/themes/skodaDark.css.js';
import { vw } from '@moller/gnist-themes/themes/vw.css.js';
import { globalTextStyles } from '@moller/gnist-themes/typography.css.js';
import React, {
    createContext,
    ReactNode,
    useContext,
    useLayoutEffect,
    useState,
} from 'react';

const carThemes = ['skoda', 'vw', 'nytte', 'audi', 'cupra'] as const;
const dealerThemes = [
    'gumpen',
    'dahles',
    'autoria',
    'mollerbil',
    'bilhold',
] as const;
const themes = [...carThemes, ...dealerThemes];

export function getThemeFromLocalStorage() {
    return getThemeFromDealerName(
        window.localStorage.getItem('preferredDealerName') ?? ''
    );
}

export function getThemeFromDealerName(dealerName: string): LogoBrand {
    const normalized = (() => {
        let x = dealerName.toLowerCase();
        x = x.replace('ø', 'o');
        x = x.includes('erik arnesen') ? 'moller' : x;
        x = x.includes('moller') ? 'mollerbil' : x;
        x = x.includes('grenland') ? 'gumpen' : x;
        x = x.includes('autosenteret notodden') ? 'gumpen' : x;
        x = x.includes('svebjorn') ? 'svebjorn' : x;
        x = x.includes('autoria') ? 'autoria' : x;
        x = x.includes('dahles') ? 'dahles' : x;
        x = x.includes('gumpen') ? 'gumpen' : x;
        return x;
    })();

    const dealerWithBranding = ((n: string): n is LogoBrand =>
        themes.some((x) => x === n))(normalized);
    if (dealerWithBranding) {
        return normalized;
    }
    return 'bilhold';
}

const getCssThemeClass = (theme: string) => {
    switch (theme) {
        case 'mollerbil':
            return mollerBil;
        case 'gumpen':
            return gumpen;
        case 'skoda':
            return skodaDark;
        case 'vw':
        case 'nytte':
            return vw;
        case 'dahles':
            return dahles;
        case 'autoria':
            return autoria;
        case 'audi':
            return audi;
        case 'cupra':
            return cupra;
        case 'bilhold':
        default:
            return bilholdLight;
    }
};

const isDarkTheme = (theme: LogoBrand) => {
    if (theme === 'skoda') {
        return true;
    }

    return false;
};

const ThemeContext = createContext<
    | {
          theme: LogoBrand;
          isDarkTheme: boolean;
          setTheme: (theme: LogoBrand | null) => void;
      }
    | undefined
>(undefined);

export const useThemeContext = () => {
    const ctx = useContext(ThemeContext);

    if (!ctx) {
        throw new Error(
            'useThemeContext must be used within a ThemeContextProvider'
        );
    }

    return ctx;
};

export const ThemeContextProvider: React.FC<{
    defaultTheme: LogoBrand;
    overrideTheme?: LogoBrand | null;
    children?: ReactNode;
}> = ({ defaultTheme, overrideTheme, children }) => {
    const setTheme = (theme: LogoBrand | null) => {
        setState({
            ...state,
            theme: theme || defaultTheme,
            isDarkTheme: isDarkTheme(theme || defaultTheme),
        });
    };

    const initialTheme = overrideTheme ?? defaultTheme;

    const initState = {
        theme: initialTheme,
        isDarkTheme: isDarkTheme(initialTheme),
        setTheme: setTheme,
    };

    const [state, setState] = useState(initState);
    const selectedTheme = state.theme;

    useLayoutEffect(() => {
        document.body.classList.add(getCssThemeClass(selectedTheme));
        return () => {
            document.body.classList.remove(getCssThemeClass(selectedTheme));
        };
    }, [selectedTheme]);

    // Add text style classes
    useLayoutEffect(() => {
        globalTextStyles.forEach((c) => document.body.classList.add(c));
    }, []);

    return (
        <ThemeContext.Provider value={state}>{children}</ThemeContext.Provider>
    );
};

export const ChildThemeProvider: React.FC<{
    theme: LogoBrand | null;
    overrideTheme?: LogoBrand | null;
    children?: ReactNode;
}> = ({ theme, overrideTheme, children }) => {
    const parentThemeState = useThemeContext();
    const { setTheme } = parentThemeState;

    useLayoutEffect(() => {
        if (theme !== null) {
            setTheme(theme);
        }

        return () => {
            setTheme(null);
        };
    }, [setTheme, theme]);

    const selectedTheme = overrideTheme || theme || parentThemeState.theme;

    const state = {
        theme: selectedTheme,
        isDarkTheme: isDarkTheme(selectedTheme),
        setTheme: parentThemeState.setTheme,
    };

    return (
        <ThemeContext.Provider value={state}>{children}</ThemeContext.Provider>
    );
};

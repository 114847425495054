import { useQuery } from '@tanstack/react-query';
import { useFetchersContext } from 'external-apis';
import { TireHotelDealResponse } from 'external-apis/src/types/carcare';

const useFindTireHotelDeal = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.carcare.fetcher
        .path('/api/v3/tireHotelDeal/vin/{vehicleIdentificationNumber}')
        .method('get')
        .create();
};

export function useGetTireHotelDeal(vin: string, opts?: { enabled?: boolean }) {
    const { enabled = true } = opts ?? {};
    const getTireHotelDeal = useFindTireHotelDeal();
    return useQuery({
        queryKey: ['getTireHotelDeal', vin],
        // Need to invalidate cache after customer has visited tire-hotel fasttrack
        // enable = false with cache is initialized as success
        cacheTime: 0,
        queryFn: () =>
            getTireHotelDeal({ vehicleIdentificationNumber: vin }).then(
                (x) => x.data[0]
            ),
        // We apparently only use the first element of the response?
        // Logic copied from old fast track
        enabled: enabled && vin.length > 0,
    });
}

export function isActiveTireHotelDeal(
    deal: TireHotelDealResponse | undefined
): deal is Omit<TireHotelDealResponse, 'dealerNumber'> & {
    dealerNumber: string;
} {
    if (!deal) {
        return false;
    }
    // Status check logic copied from old fast track
    return (
        deal.dealerNumber !== null &&
        deal.status !== null &&
        deal.status !== 'Passiv'
    );
}

import {
    getSanityDataset,
    PROJECT_ID,
    SANITY_API_VERSION,
} from '@bilhold/sanity';
import { createClient, QueryParams } from '@sanity/client';
import { useQuery } from '@tanstack/react-query';
import getCurrentEnvironment from 'src/utils/environment/getCurrentEnvironment';

export const sanityClient = createClient({
    projectId: PROJECT_ID,
    dataset: getSanityDataset(getCurrentEnvironment().name),
    useCdn: true,
    apiVersion: SANITY_API_VERSION,
});

const fetchSanityData = async <T>(query: string, params: QueryParams) => {
    return sanityClient.fetch<T>(query, params);
};

export const useSanityData = <T>(query: string, params: QueryParams = {}) => {
    return useQuery<T>({
        queryKey: ['sanityData', query, params],
        queryFn: () => fetchSanityData<T>(query, params),
    });
};

import { useQuery } from '@tanstack/react-query';
import proxyGet from 'src/utils/data-mocks/proxyGet';

export const useTireHotelDealMDH = (vin?: string) => {
    return useQuery({
        queryKey: ['MDHTireHotelDeal', vin],
        queryFn: () =>
            proxyGet<{ isTireHotelCar: boolean }>(
                `api/v1/tire-hotel-deal/vin/${vin}`
            ).then((res) => res.data),
        enabled: !!vin,
    });
};

import { ServiceGroup } from './groups/ServiceGroup';
import { SERVICE_GROUP_ID } from '../../lib/constants/serviceConstants';
import { LanguageContextType } from '../../lib/languages/languageContext';
import { Dictionary, groupBy } from 'lodash';
import { ServiceViewModel } from 'external-apis/src/types/port';

export type ServiceOrGroup =
    | {
          type: 'service';
          service: ServiceViewModel;
      }
    | {
          type: 'group';
          group: ServiceGroup;
      };

function groupServicesByCategory(service: ServiceViewModel) {
    switch (service.category) {
        case 'Tire':
            return 'tireServices';
        case 'RecommendedService':
            return 'recommendedServices';
        default:
            return 'otherServices';
    }
}

export function getSelectedServiceGroups(
    selectedServiceIds: string[],
    servicesAndGroups: ServiceOrGroup[]
) {
    const selectedGroupIds = servicesAndGroups.reduce<string[]>(
        (accumulator, current) => {
            if (current.type === 'group' && current.group.services.length > 0) {
                const serviceIds = current.group.services.map(
                    (service) => service.id
                );
                if (
                    serviceIds.some((service) =>
                        selectedServiceIds.includes(service)
                    )
                ) {
                    return [...accumulator, current.group.id];
                }
            }
            return accumulator;
        },
        []
    );

    return [...new Set(selectedGroupIds)];
}

export const isTireGroup = (serviceGroup: ServiceGroup) =>
    serviceGroup.id === SERVICE_GROUP_ID.tireGroup;

export const isRecommendedGroup = (serviceGroup: ServiceGroup) =>
    serviceGroup.id === SERVICE_GROUP_ID.recommendedGroup;

function sortServicesByPrice(services: ServiceViewModel[]) {
    return services.sort(
        (a, b) =>
            (b.dealerSpecificInformation[0]?.price?.fixedPrice || 0) -
            (a.dealerSpecificInformation[0]?.price?.fixedPrice || 0)
    );
}

function getTireGroupOrService(
    lc: LanguageContextType,
    services: ServiceViewModel[]
): ServiceOrGroup {
    if (services.length === 1)
        return {
            type: 'service',
            service: services[0],
        };

    const sortedServices = sortServicesByPrice(services);

    return {
        type: 'group',
        group: {
            id: SERVICE_GROUP_ID.tireGroup,
            name: lc.standardServices.custom_service_groups.tire_group_title,
            services: sortedServices,
        },
    };
}

function getRecommendedGroup(
    lc: LanguageContextType,
    services: ServiceViewModel[]
): ServiceOrGroup {
    return {
        type: 'group',
        group: {
            id: SERVICE_GROUP_ID.recommendedGroup,
            name: lc.standardServices.custom_service_groups
                .recommended_group_title,
            services,
        },
    };
}

export const preProcessServices = (
    lc: LanguageContextType,
    services?: ServiceViewModel[]
): ServiceOrGroup[] => {
    if (!services) {
        return [];
    }

    const { tireServices, recommendedServices, otherServices } = groupBy(
        services,
        groupServicesByCategory
    ) as Dictionary<ServiceViewModel[] | undefined>;

    const tireGroupOrService = getTireGroupOrService(lc, tireServices ?? []);
    const recommendedGroup = getRecommendedGroup(lc, recommendedServices ?? []);
    const otherServicesSorted = sortOtherServiceLast(otherServices ?? []);
    const otherServicesMapped = otherServicesSorted?.map((service) => ({
        type: 'service' as const,
        service,
    }));

    return [
        recommendedGroup,
        tireGroupOrService,
        ...otherServicesMapped,
    ].filter(
        (x) =>
            (x.type === 'group' &&
                x.group.services &&
                x.group.services.length > 0) ||
            (x.type === 'service' && x.service)
    );
};

function sortOtherServiceLast(otherServicesMapped: ServiceViewModel[]) {
    return [...otherServicesMapped].sort((a, b) => {
        const diagnosisFirst =
            a.category === 'Diagnosis' && b.category !== 'Diagnosis';
        const diagnosisSecond =
            a.category !== 'Diagnosis' && b.category === 'Diagnosis';
        if (diagnosisFirst) return 1;
        if (diagnosisSecond) return -1;
        return 0;
    });
}

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Company from 'src/features/add-car/components/company/Company';
import Leaser from 'src/features/add-car/components/leaser/Leaser';
import Owner from 'src/features/add-car/components/owner/Owner';
import User from 'src/features/add-car/components/user/User';
import {
    AddCarSearchResponse,
    useAddCar,
} from 'src/features/home/queries/useAddCarFunctions';
import { PATH_HOME } from 'src/routing/routes';
import CarRelation from 'src/utils/enums/CarRelation';

interface CarRelationContentProps {
    readonly carRelation: CarRelation | undefined;
    readonly registrationNumber: string;
    readonly searchCarData: AddCarSearchResponse;
}

const CarRelationContent = ({
    carRelation,
    registrationNumber,
    searchCarData,
}: CarRelationContentProps) => {
    const [errorMessage, setErrorMessage] = useState('');

    const navigate = useNavigate();

    const redirect = () => {
        navigate(PATH_HOME);
    };

    const { mutate: addCarMutate, isLoading } = useAddCar(
        redirect,
        setErrorMessage
    );

    const addCar = (
        relationType: CarRelation,
        leaser?: string,
        companyCar?:
            | string
            | null
            | {
                  company: {
                      name: string | undefined;
                      electronicAddress: string | undefined;
                  };
              }
    ) => {
        addCarMutate({
            registrationNumber,
            vin: searchCarData.vin,
            relationType,
            leaser,
            companyCar,
        });
    };

    const chosenRelation = searchCarData?.relationTypes.find(
        (relation) => relation.type === carRelation
    );

    switch (carRelation) {
        case CarRelation.OWNER:
            return (
                <Owner
                    reasons={chosenRelation?.reasons ?? []}
                    addCarFunction={addCar}
                    addCarError={errorMessage}
                    setErrorMessage={setErrorMessage}
                />
            );
        case CarRelation.LEASER:
            return (
                <Leaser
                    reasons={chosenRelation?.reasons ?? []}
                    addCarFunction={addCar}
                    addCarError={errorMessage}
                    isLoading={isLoading}
                    setErrorMessage={setErrorMessage}
                />
            );
        case CarRelation.USER:
            return (
                <User
                    reasons={chosenRelation?.reasons ?? []}
                    method={chosenRelation?.method}
                    addCarFunction={addCar}
                    addCarError={errorMessage}
                    isLoading={isLoading}
                />
            );
        case CarRelation.COMPANY:
            return (
                <Company
                    reasons={chosenRelation?.reasons ?? []}
                    addCarFunction={addCar}
                    addCarError={errorMessage}
                    isLoading={isLoading}
                    setErrorMessage={setErrorMessage}
                />
            );

        default:
            return <></>;
    }
};

export default CarRelationContent;

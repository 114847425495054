import { useAutoAnimate } from '@formkit/auto-animate/react';
import { Column, Row } from '@moller/design-system';
import { QueryObserverSuccessResult } from '@tanstack/react-query';
import {
    ServiceCampaign,
    ServiceViewModel,
} from 'external-apis/src/types/port';
import { useState } from 'react';
import { Services } from '../../../_api/http/services';
import { SERVICE_GROUP_IDS } from '../../../lib/constants/serviceConstants';
import { useLanguageContext } from '../../../lib/languages/languageContext';
import { ServicePrice } from '../price/ServicePrice';
import { useStandardServicesForm } from '../useSectionForm';
import { Mileage } from './Mileage';
import { RecommendedServices } from './RecommendedServices';
import { TireService } from './TireService';

export type ServiceGroup = {
    id: (typeof SERVICE_GROUP_IDS)[number];
    name: string;
    services: ServiceViewModel[];
    campaigns?: ServiceCampaign[];
};

export interface ServiceGroupProps {
    servicesForm: ReturnType<typeof useStandardServicesForm>;
    group: ServiceGroup;
    vin: string;
    dealerNumbers: string[];
    servicesResponse: QueryObserverSuccessResult<Services>;
    setMileage: (x: string) => void;
    mileage: string;
}

export function ServiceGroupComponent({
    group,
    servicesForm,
    servicesResponse,
    setMileage,
    mileage,
}: ServiceGroupProps) {
    switch (group.id) {
        case 'custom-tire-group':
            return (
                <TireService
                    services={group.services}
                    servicesForm={servicesForm}
                />
            );
        case 'custom-recommended-group':
            return (
                <>
                    <RecommendedServicesGroup
                        servicesForm={servicesForm}
                        servicesResponse={servicesResponse}
                        services={group.services}
                        setMileage={setMileage}
                        mileage={mileage}
                    />
                    <Row horizontalAlign="end">
                        <ServicePrice
                            service={{ type: 'group', group }}
                            selectedServiceIds={
                                servicesForm.state.raw.selectedIds
                            }
                        />
                    </Row>
                </>
            );
        default:
            return null;
    }
}

type RecommendedServicesGroupProps = Pick<
    ServiceGroupProps,
    'servicesForm' | 'servicesResponse' | 'setMileage' | 'mileage'
> & { services: ServiceViewModel[] };

function RecommendedServicesGroup({
    servicesForm,
    servicesResponse,
    services,
    mileage,
    setMileage,
}: RecommendedServicesGroupProps) {
    const [animationParent] = useAutoAnimate();
    const rawMileage = servicesForm.state.raw.mileage;
    const validatedMileage = servicesForm.state.validated.mileage;
    const mileageIsValid = servicesForm.state.validation.mileage.isValid;

    const [invalidMileageOnClick, setInvalidMileageOnClick] = useState(false);
    const showValidityInvalid =
        (!mileageIsValid && !!rawMileage) || invalidMileageOnClick;

    const setValidatedMileage = () => {
        if (mileageIsValid && validatedMileage) {
            setMileage(validatedMileage);
            setInvalidMileageOnClick(false);
        } else {
            setInvalidMileageOnClick(true);
        }
    };

    // dont show general service before we have tried to fetch services
    const recommendedServices = mileage ? services : [];

    const [lc] = useLanguageContext();

    return (
        <Column gap="xxs" ref={animationParent}>
            <Mileage
                onClick={setValidatedMileage}
                helperText={lc.standardServices.mileage_helper_text}
                showValidityInvalid={showValidityInvalid}
                isLoading={servicesResponse.isFetching}
            />
            <RecommendedServices
                servicesForm={servicesForm}
                recommendedServices={recommendedServices}
                isLoading={servicesResponse.isFetching}
                shouldShowServices={!!mileage}
            />
        </Column>
    );
}

import { Description, InputFieldValidity } from '@moller/design-system';
import {
    BookedServiceModel,
    BookingCreateModelQuery,
    ReservedTimeSlotViewModel,
} from 'external-apis/src/types/port';
import { useEffect, useRef, useState } from 'react';
import { styled } from 'styled-components';
import { useConfirmBooking } from '../../../_api/http/booking';
import { useGetServices } from '../../../_api/http/services';
import { useGetVehicle } from '../../../_api/http/vehicle';
import { BoldText } from '../../../components/BoldText';
import { FlexColumn } from '../../../components/FlexColumn';
import { FlexRow } from '../../../components/FlexRow';
import { QueryError } from '../../../components/QueryError';
import { BilholdErrorText } from '../../../components/bilhold/BilholdErrorText';
import { BilholdViewLayout } from '../../../components/bilhold/BilholdView';
import { CancelButton } from '../../../components/bilhold/CancelButton';
import {
    ServicePriceSummary,
    TotalPriceContainer,
} from '../../../components/prices/ServicePriceSummary';
import { useLanguageContext } from '../../../lib/languages/languageContext';
import { Done, SectionState, checkDoneDependencies } from '../../sectionUtils';
import { SelectedService } from '../../standard-services/useSectionForm';
import { StandardServicesData } from '../../standard-services/useStandardServices';
import {
    ConfirmBookingDependencies,
    ConfirmButton,
} from '../ConfirmBookingSection';

type ConfirmBookingEditProps = {
    setSectionState: (x: SectionState<null>) => void;
    dependencies: ConfirmBookingDependencies;
    invalidateAppointmentsQuery: () => void;
};

export function ConfirmBookingEdit({
    dependencies,
    invalidateAppointmentsQuery,
    ...props
}: ConfirmBookingEditProps) {
    if (checkDoneDependencies(dependencies)) {
        return (
            <ConfirmBookingWithDoneDependencies
                dependencies={dependencies}
                invalidateAppointmentsQuery={invalidateAppointmentsQuery}
                {...props}
            />
        );
    }

    return (
        <ConfirmBookingWithoutDoneDependencies dependencies={dependencies} />
    );
}

type ConfirmBookingWithDependenciesProps = {
    dependencies: Done<ConfirmBookingDependencies>;
    setSectionState: ConfirmBookingEditProps['setSectionState'];
    invalidateAppointmentsQuery: () => void;
};

function ConfirmBookingWithDoneDependencies({
    dependencies,
    invalidateAppointmentsQuery,
    ...props
}: ConfirmBookingWithDependenciesProps) {
    const confirmButtonRef = useRef<HTMLButtonElement | null>(null);

    const registrationNumber =
        dependencies.chooseVehicle.data.registrationNumber;
    const mileage = dependencies.standardServices.data.mileage;
    const selectedStandardServices =
        dependencies.standardServices.data.selectedServices;
    const timeslot = dependencies.chooseTimeSlot.data.reservedTimeSlot;

    function onSuccessBooking() {
        invalidateAppointmentsQuery();

        props.setSectionState({
            viewMode: 'done',
            data: null,
        });
    }

    const confirmBooking = useConfirmBooking(onSuccessBooking);

    const [lc] = useLanguageContext();

    const vehicleResponse = useGetVehicle(registrationNumber);
    const serviceResponse = useGetServices({
        vin: vehicleResponse.data?.vehicleIdentificationNumber,
        dealerNumbers: [dependencies.chooseDealer.data.dealerNumber],
        mileage,
    });

    // TODO: better query check when multiple calls (useQueries)
    if (vehicleResponse.isError) {
        return (
            <QueryError
                isError={vehicleResponse.isError}
                error={vehicleResponse.error}
            />
        );
    }

    //  This should not be true due to caching
    if (vehicleResponse.isLoading) {
        return (
            <FlexRowConfirmButtons>
                <CancelButton />
                <ConfirmButton isLoading={true} hidden />
            </FlexRowConfirmButtons>
        );
    }

    const vin = vehicleResponse.data.vehicleIdentificationNumber;
    const bookingId = timeslot?.bookingId ?? createGUID();
    const dealerNumber = dependencies.chooseDealer.data.dealerNumber;
    const adapterId = dependencies.chooseDealer.data.adapterId;

    const getBookedServices = (
        selectedServices: StandardServicesData['selectedServices'],
        transportServiceIds?: string[],
        additionalServicesIds?: string[]
    ): BookedServiceModel[] => {
        const otherServices = [
            ...(transportServiceIds ?? []),
            ...(additionalServicesIds ?? []),
        ].map((serviceId): BookedServiceModel => {
            return {
                serviceId: serviceId,
                additionalInfo: [],
            };
        });
        const mappedStandardServices = selectedServices.map((x) => ({
            serviceId: x.serviceId,
            additionalInfo: x.comment ? [{ comment: x.comment }] : [],
        }));
        return [...mappedStandardServices, ...otherServices];
    };

    const body = (): BookingCreateModelQuery => {
        return {
            bookingId,
            vin,
            businessOperation: getBusinessOperation(
                timeslot,
                dealerNumber,
                selectedStandardServices
            ),
            services: getBookedServices(
                selectedStandardServices,
                dependencies.transportServices.data.serviceIds,
                dependencies.additionalServices.data.serviceIds
            ),
            mileage: mileage === '' ? null : +mileage,
            dealerNumber,
            startTime: timeslot?.startTime,
            adapterId,
            customerWaitingAtDealer:
                dependencies.transportServices.data.isWaiting,
            countryCode: 'NOR',
        };
    };

    const services = serviceResponse.isSuccess
        ? [
              ...serviceResponse.data.Delivery,
              ...serviceResponse.data.Standard,
              ...serviceResponse.data.Transport,
              ...serviceResponse.data.Additional,
              ...serviceResponse.data.Recommended,
          ].filter((x) => body().services.some((y) => x.id === y.serviceId))
        : undefined;
    return (
        <BilholdViewLayout>
            {services && (
                <TotalPriceContainer>
                    <BoldText>{lc.prices.total}</BoldText>
                    <ServicePriceSummary services={services} />
                </TotalPriceContainer>
            )}
            <Description>{lc.prices.disclaimer_price_material}</Description>
            <FlexRowConfirmButtons>
                <CancelButton />
                <ConfirmButton
                    onClick={() => {
                        confirmBooking.mutate(body());
                    }}
                    isLoading={confirmBooking.isLoading}
                    ref={confirmButtonRef}
                />
            </FlexRowConfirmButtons>
            <QueryError
                isError={confirmBooking.isError}
                error={confirmBooking.error}
            />
        </BilholdViewLayout>
    );
}

const ConfirmButtonWithValidationFlexColumn = styled(FlexColumn)`
    align-items: flex-end;
`;

type ConfirmBookingWithoutDoneDependenciesProps = {
    dependencies: ConfirmBookingEditProps['dependencies'];
};

export const FlexRowConfirmButtons = styled(FlexRow)`
    align-items: center;
    gap: var(--moller-spacing-m);
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
`;

export function ConfirmBookingWithoutDoneDependencies({
    dependencies,
}: ConfirmBookingWithoutDoneDependenciesProps) {
    const [lc] = useLanguageContext();
    const [showErrorText, setShowErrorText] = useState<boolean>(false);
    const validity: InputFieldValidity = {
        type: 'error',
        message: lc.errors.confirm_booking_validation,
    };

    useEffect(() => {
        setShowErrorText(false);
    }, [dependencies]);

    return (
        <ConfirmButtonWithValidationFlexColumn>
            <FlexRowConfirmButtons>
                <CancelButton />
                <ConfirmButton
                    isLoading={false}
                    onClick={() => {
                        setShowErrorText(true);
                    }}
                    hidden
                />
            </FlexRowConfirmButtons>
            {showErrorText && (
                <BilholdErrorText
                    id={'confirm-error-text'}
                    validity={validity}
                />
            )}
        </ConfirmButtonWithValidationFlexColumn>
    );
}

const getBusinessOperation = (
    reservedTimeslot: ReservedTimeSlotViewModel | undefined,
    selectedDealerNumbner: string,
    selectedServices: SelectedService[]
) => {
    if (reservedTimeslot) {
        return reservedTimeslot.businessOperation;
    }
    const selectedDealerSpecificInfo =
        selectedServices[0].dealerSpecificInformation.find(
            (d) => d.dealerNumber === selectedDealerNumbner
        );
    return selectedDealerSpecificInfo?.businessOperation ?? 'unknown';
};

function createGUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function (c) {
            const r = (Math.random() * 16) | 0,
                v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        }
    );
}

import { Fetcher } from 'openapi-typescript-fetch';
import { Environment } from '../..';
import { ENVIRONMENTS, backendUrl } from '../environment';
import { paths } from '../schema/carcare';
import { FetcherConfiguration } from './fetchersProvider';

const urls: { [x in (typeof ENVIRONMENTS)[number]]: string } = {
    prod: 'https://api.mittbilhold.no/bilhold',
    stage: 'https://api-staging.mittbilhold.no/bilhold',
    test: 'https://api-test.mittbilhold.no/bilhold',
    dev: 'https://api-dev.mittbilhold.no/bilhold',
    local: 'https://api-dev.mittbilhold.no/bilhold',
};

export const fetcher: ReturnType<typeof Fetcher.for<paths>> =
    Fetcher.for<paths>();

export function configureCarCareFetcher({
    localEnvironmentOverride,
    environment,
    middlewares,
}: FetcherConfiguration) {
    const baseUrl = backendUrl({
        environment,
        urls,
        localEnvironmentOverride,
    });
    fetcher.configure({
        baseUrl,
        init: {
            headers: {
                'Content-Type': 'application/json',
            },
        },
        use: middlewares,
    });
    return {
        baseUrl,
        fetcher,
        environment,
    };
}

export function carCareBaseUrl(
    environment: Environment,
    localEnvironmentOverride?: string
) {
    return backendUrl({
        environment,
        urls,
        localEnvironmentOverride,
    });
}

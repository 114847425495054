import Dealer from 'src/types/Dealer';
import { DealerSearchDto } from './DealerSearchDto';

export const mapDealer = (dealer: DealerSearchDto): Dealer => {
    const geolocation = dealer.geolocation || {
        postalAreaNameCode: '',
        zipCode: '',
        address: '',
        latitude: null,
        longitude: null,
    };
    return {
        mnetDealerId: dealer.dealerNumber,
        name: dealer.dealerName,
        location: {
            postalArea: geolocation.postalAreaNameCode,
            zipCode: geolocation.zipCode,
            address: geolocation.address,
            coordinates: {
                latitude: geolocation.latitude,
                longitude: geolocation.longitude,
            },
        },
        serviceCertifications: dealer.serviceCertifications,
        saleCertifications: dealer.saleCertifications,
    };
};

import { Column, Headline, Icon } from '@moller/design-system';
import { Divider } from '@mui/material';
import { useAppointments } from 'src/features/appointment/api/useAppointments';
import { CenteredPageContent } from 'src/shared/CenteredPageContent';
import ContactItem from 'src/shared/contact-item/ContactItem';
import { IconContainer } from './MessageThreadsChat';
import { SendMessageInNewChat } from './SendMessageInNewChat';

// TODO: This is a super hacky component and must be updated
export const MessageThreadsChatNewMessage = () => {
    const id = window.location.pathname.split('/messages-contact/new/')[1];
    const { data, isLoading } = useAppointments();
    const appointment = data?.find((booking) => booking.id === id);

    const selectedDealer = appointment?.dealer;

    if (!selectedDealer || isLoading) {
        return null;
    }

    const dealerAddress = `${selectedDealer.streetAddress}, ${selectedDealer.zipCode} ${selectedDealer.city}`;
    return (
        <CenteredPageContent showBackButton>
            <Column gap={'m'}>
                <Column gap={'m'}>
                    <Headline>{selectedDealer.name}</Headline>
                    <p>
                        Lurer du på noe angående din verkstedtime? Vi svarer deg
                        innen 1 virkedag (åpningstid 09-15). Ring oss om det er
                        noe som haster.
                    </p>
                    <Column gap="xs">
                        <ContactItem
                            phoneLabel="Ring forhandler"
                            phoneNumber={selectedDealer.phoneNumber}
                        />
                        <IconContainer>
                            <Icon icon="location" />
                            {dealerAddress}
                        </IconContainer>
                    </Column>
                    <Divider />
                    <SendMessageInNewChat appointment={appointment} />
                </Column>
            </Column>
        </CenteredPageContent>
    );
};

import {
    breakpointTablet,
    Headline,
    TextContainer,
} from '@moller/design-system';
import { LogoBrand } from '@moller/design-system/foundation/logos/Logo.js';
import { PageLayout } from 'src/features/my-car/components/PageLayout';
import CarVideos from 'src/types/CarVideos';
import { themeOverride } from 'src/utils/dev-tools/devToolsUtils';
import lang from 'src/utils/lang';
import { Themes } from 'src/utils/themes/selectTheme';
import { styled } from 'styled-components';
import { ChildThemeProvider } from 'theming';
import { CarVideo } from './CarVideo';

const VideoGrid = styled.div`
    margin-top: var(--moller-spacing-xxl);
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--moller-spacing-3xl);

    @media screen and (min-width: ${breakpointTablet}) {
        grid-template-columns: 1fr 1fr;
    }
`;

interface CarVideoContainerProps {
    videos: CarVideos[];
    theme: Themes;
}
export const CarVideoContainer = (props: CarVideoContainerProps) => {
    return (
        <ChildThemeProvider
            theme={props.theme.toLocaleLowerCase() as LogoBrand}
            overrideTheme={themeOverride}
        >
            <PageLayout showBackButton>
                <Headline>{lang.videos}</Headline>
                {props.videos.length === 0 ? (
                    <TextContainer>
                        <p>{lang.noVideos}</p>
                    </TextContainer>
                ) : (
                    <VideoGrid>
                        {props.videos.map((video) => (
                            <CarVideo key={video.title} video={video} />
                        ))}
                    </VideoGrid>
                )}
            </PageLayout>
        </ChildThemeProvider>
    );
};

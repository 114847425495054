import { datadogRum } from '@datadog/browser-rum';
import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import Status from 'http-status';
import { Dispatch, SetStateAction } from 'react';
import { PUBLIC_API_PREFIX } from 'src/constants';
import { AUTH_EVENT_SUCCESS } from 'src/utils/auth/constants';
import getCurrentEnvironment from '../environment/getCurrentEnvironment';
import forceUpdateApp, {
    getForceUpdateInProgress,
} from '../native/forceUpdateApp';
import getHeaders, { getAnonymousHeaders } from './utils/headers';
import initialHttpState, { HttpState } from './utils/HttpState';
import isAbsoluteUrl from './utils/isAbsoluteUrl';
import defaultOnAuthError from './utils/onAuthError';
import processUrl from './utils/processUrl';
import urlRequiresAuth from './utils/urlRequiresAuth';

export interface RequestConfig {
    axiosConfig?: Omit<AxiosRequestConfig, 'method' | 'url' | 'data'>;
    isAnonymous?: boolean;
    onAuthError?: () => void;
    setHttpState?: Dispatch<SetStateAction<HttpState>>;
}

export async function doRequest<T = unknown>(
    method: 'GET' | 'DELETE' | 'POST' | 'PUT' | 'PATCH' | 'HEAD',
    path: string,
    data?: unknown,
    config?: RequestConfig
): Promise<AxiosResponse<T>> {
    const {
        axiosConfig,
        onAuthError,
        isAnonymous = path.includes(PUBLIC_API_PREFIX),
        setHttpState,
    } = config ?? {};

    if (!['GET', 'HEAD'].includes(method) && getForceUpdateInProgress()) {
        throw new Error('Updating app');
    }

    setHttpState?.({ ...initialHttpState, isLoading: true });
    const { serverUrl } = getCurrentEnvironment();
    const isBilholdUrl = !isAbsoluteUrl(path);
    const absoluteUrl = isBilholdUrl ? `${serverUrl}/${path}` : path;
    const defaultHeaders = isAnonymous
        ? await getAnonymousHeaders(path, isBilholdUrl)
        : await getHeaders(path, isBilholdUrl);
    const headers = {
        ...defaultHeaders,
        ...axiosConfig?.headers,
    };

    try {
        const response = await Axios.request<T>({
            method,
            url: processUrl(absoluteUrl),
            data,
            ...axiosConfig,
            headers,
        });

        setHttpState?.({
            ...initialHttpState,
            isSuccess: true,
        });

        if (urlRequiresAuth(path)) {
            document.dispatchEvent(new Event(AUTH_EVENT_SUCCESS));
        }

        return response;
    } catch (err) {
        const axiosError = err as AxiosError;

        if (Axios.isCancel(err)) {
            setHttpState?.(initialHttpState);
        } else {
            setHttpState?.({
                ...initialHttpState,
                isFailed: true,
            });

            if (axiosError && axiosError.response?.status !== 0) {
                datadogRum.addError(axiosError, {
                    request: {
                        url: axiosError.config?.url,
                        method: axiosError.config?.method,
                    },
                    response: {
                        status: axiosError.response?.status,
                        data: axiosError.response?.data,
                    },
                });
            }
        }

        if (axiosError.response?.status) {
            if (axiosError.response.status === Status.IM_A_TEAPOT) {
                forceUpdateApp();
            }

            if (axiosError.response.status === Status.UNAUTHORIZED) {
                (onAuthError || defaultOnAuthError)();
            }
        }

        throw err;
    }
}

import { useGetCustomer } from '../../_api/http/customer';
import { useGetDealers } from '../../_api/http/dealers';
import { useGetServices } from '../../_api/http/services';
import { useGetVehicle } from '../../_api/http/vehicle';
import { Done } from '../sectionUtils';
import { StandardServicesDependencies } from './useStandardServices';

export function useFetchedData(
    dependencies: Done<StandardServicesDependencies | undefined>,
    mileage: string
) {
    const vehicleResponse = useGetVehicle(
        dependencies?.chooseVehicle.data.registrationNumber
    );
    const vin = vehicleResponse.data?.vehicleIdentificationNumber;
    const dealerNumbers = dependencies && [
        dependencies.chooseDealer.data.dealerNumber,
    ];
    const customerResponse = useGetCustomer();
    const dealerResponse = useGetDealers();
    const keepPreviousData = !!mileage;
    const serviceResponse = useGetServices({
        vin,
        dealerNumbers,
        mileage,
        keepPreviousData,
    });
    return {
        vehicleResponse,
        dealerResponse,
        customerResponse,
        serviceResponse,
    };
}

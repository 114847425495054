import {
    Accordion,
    AlertBanner,
    Column,
    DisplayText,
    Link,
    NoticeText,
    Subtitle,
    TextContainer,
} from '@moller/design-system';
import { useParams } from 'react-router-dom';
import lang from 'src/utils/lang';
import { styled } from 'styled-components';
import {
    HistoricBooking,
    useWorkshopHistory,
} from '../query/useWorkshopHistory';

import { Skeleton } from '@mui/material';
import { useFlag } from 'feature-toggle';
import moment from 'moment';
import { openPdfWithAuth } from 'src/utils/auth/authUtils';
import { isMobileApp } from 'src/utils/mobile';
import { openFileForMobileApp } from 'src/utils/native/pdf';
import {
    DAY_OF_MONTH_FORMAT,
    MONTH_AS_STRING_SHORTENED,
    YEAR_FORMAT,
} from 'src/utils/string/formatTime';

const WorshopHistoryList = styled.ul`
    margin: var(--moller-spacing-s) 0;
    list-style-type: none;
    padding-inline-start: 0;
`;

const Card = styled.div`
    background-color: var(--moller-color-surface-variant);
    margin: var(--moller-spacing-xxs) 0;
    padding: var(--moller-spacing-s);
    border-radius: var(--moller-radius-card);
    box-shadow: var(--moller-elevation-low);
`;

const openInvoice = async (
    invoiceLink: string,
    invoiceNumber: string,
    adapterId: string,
    enabledAnnataInvoice: boolean
) => {
    const fileName = `Bilhold-${lang.invoice}-${invoiceNumber}.pdf`;
    if (isMobileApp()) {
        void openFileForMobileApp(
            invoiceLink,
            'application/pdf',
            fileName,
            adapterId,
            enabledAnnataInvoice
        );
    } else {
        await openPdfWithAuth(
            invoiceLink,
            fileName,
            adapterId,
            enabledAnnataInvoice
        );
    }
};

const StyledHeaderContainer = styled.div`
    display: flex;
    gap: var(--moller-spacing-l);
    padding: var(--moller-spacing-s) 0;
    font-weight: 500;
`;

const StyledDateNumber = styled(DisplayText)`
    text-align: center;
    line-height: 1;
    font-size: var(--moller-type-small-display-font-size);
`;

const StyledSubtitle = styled(Subtitle)`
    line-height: 1.1;
    margin-bottom: var(--moller-spacing-base);
`;

const StyledAccordionBody = styled(TextContainer)`
    padding: var(--moller-spacing-s) 0;
    ${Link.selector} {
        display: inline-block;
        margin-top: var(--moller-spacing-s);
        cursor: pointer;
    }
`;

const WorkshopHistoryCard = ({
    workshopHistory,
}: {
    workshopHistory: HistoricBooking;
}) => {
    const enabledAnnataInvoice = useFlag('UseAnnataHistory');
    const renderHeader = () => (
        <StyledHeaderContainer>
            <Column verticalAlign="start" horizontalAlign="end">
                <NoticeText>
                    {moment(workshopHistory.date).format(
                        MONTH_AS_STRING_SHORTENED
                    )}
                </NoticeText>
                <StyledDateNumber forwardedAs={'span'}>
                    {moment(workshopHistory.date).format(DAY_OF_MONTH_FORMAT)}
                </StyledDateNumber>
            </Column>
            <Column verticalAlign="center" gap={'base'}>
                <StyledSubtitle>{workshopHistory.type}</StyledSubtitle>
                <TextContainer>{workshopHistory.dealerName}</TextContainer>
            </Column>
        </StyledHeaderContainer>
    );

    return (
        <Card>
            <Accordion head={renderHeader()}>
                <StyledAccordionBody>
                    <Subtitle>Utført Arbeid:</Subtitle>
                    <ul>
                        {workshopHistory.taskNames?.map((a) => (
                            <li key={a}>{a}</li>
                        ))}
                    </ul>
                    <Column>
                        {workshopHistory.invoices?.map((i) => (
                            <Link
                                key={i.invoiceNumber}
                                onClick={() =>
                                    void openInvoice(
                                        i.path,
                                        i.invoiceNumber,
                                        workshopHistory.adapterId,
                                        enabledAnnataInvoice
                                    )
                                }
                            >
                                {lang.invoice} #{i.invoiceNumber}
                            </Link>
                        ))}
                    </Column>
                </StyledAccordionBody>
            </Accordion>
        </Card>
    );
};

export const WorkshopHistory = () => {
    const vin = useParams().vin;

    const { data, isLoading, isError } = useWorkshopHistory(vin);
    const showWorkshopHistory = useFlag('ShowWorkshopHistory');

    if (!showWorkshopHistory) {
        return null;
    }
    if (isLoading) {
        return <Skeleton height="150px" />;
    }
    if (isError) {
        return (
            <AlertBanner
                headingLevel={'h3'}
                density={'default'}
                heading={'Noe gikk galt'}
                message={'Vi klarte ikke å hente din verkstedhistorikk'}
                type={'error'}
            />
        );
    }

    const noHistory = `Du har ingen verkstedhistorikk knyttet til denne bilen.\nNår du har hatt bilen din på merkeverksted får du tilgang til verkstedhistorikken her. Vi viser arbeid som er ferdig fakturert og som ikke er garantisaker.\nKontakt ditt verksted hvis du har spørsmål om historikk fra tidligere eier eller lurer på noe annet. Telefonnummeret finner du under kontakt i menyen.`;

    const noHistoryParagraphs = noHistory.split('\n');

    if (data?.length === 0) {
        return (
            <div>
                {noHistoryParagraphs.map((x, i) => {
                    if (i < noHistoryParagraphs.length) {
                        return (
                            <>
                                <p>{x}</p>
                                <br />
                            </>
                        );
                    }
                })}
            </div>
        );
    }

    const hasHistory = `Når du har hatt bilen din på merkeverksted får du tilgang til verkstedhistorikken her. Vi viser arbeid som er ferdig fakturert og som ikke er garantisaker.\nKontakt ditt verksted hvis du har spørsmål om historikk fra tidligere eier eller lurer på noe annet. Telefonnummeret finner du under kontakt i menyen.`;

    const hasHistoryParagraphs = hasHistory.split('\n');

    const groupedWorkshopHistoryByYear = data?.reduce<
        Record<string, HistoricBooking[] | undefined>
    >((accumulator, curr) => {
        const year = moment(curr.date).format(YEAR_FORMAT);
        accumulator[year] = [...(accumulator[year] ?? []), curr];
        return accumulator;
    }, {});
    return (
        <>
            <div>
                {hasHistoryParagraphs.map((x, i) => {
                    if (i < hasHistoryParagraphs.length) {
                        return (
                            <>
                                <p>{x}</p>
                                <br />
                            </>
                        );
                    }
                })}
            </div>
            <WorshopHistoryList>
                {groupedWorkshopHistoryByYear &&
                    Object.keys(groupedWorkshopHistoryByYear)
                        .sort((a, b) => Number(b) - Number(a))
                        .map((year) => (
                            <div key={year}>
                                <Subtitle>{year}</Subtitle>
                                {groupedWorkshopHistoryByYear[year]?.map(
                                    (w) => {
                                        return (
                                            <WorkshopHistoryCard
                                                workshopHistory={w}
                                                key={w.id}
                                            />
                                        );
                                    }
                                )}
                            </div>
                        ))}
            </WorshopHistoryList>
        </>
    );
};
